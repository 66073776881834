import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import Highcharts from "highcharts";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighchartsReact from "highcharts-react-official";
import { Dispatch, SetStateAction, memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Chart } from "../../../types";
import { IBaseChart } from "../classes/i-base-chart";
import { dashboardApi } from "../dashboard-api";
import { BaseData, ChartType } from "../types";
import { DashboardChartDisplaySwitch, DashboardChartDisplaySwitchModel } from "./dashboard-chart-display-switch";
import { DashboardChartFilterFormModel } from "./dashboard-chart-filter-form";
import { DashboardTable } from "./dashboard-table";

NoDataToDisplay(Highcharts); // ReactだとこれがないとnoDataプロパティが効かない

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));
StyledPaper.defaultProps = {
  square: true,
};

type DashboardChartProps<TData extends BaseData, TChartClass extends IBaseChart<TData>> = {
  chartInstance: TChartClass;
  chart: Chart;
  type: ChartType;
  filter?: DashboardChartFilterFormModel;
  displaySwitch: DashboardChartDisplaySwitchModel;
  setDisplaySwitch: Dispatch<SetStateAction<DashboardChartDisplaySwitchModel>>;
  isAllLoaded: boolean;
  onLoaded: () => void;
};

const _DashboardChart = <TData extends BaseData, TChartClass extends IBaseChart<TData>>({
  chartInstance,
  chart,
  type,
  filter,
  displaySwitch,
  setDisplaySwitch,
  isAllLoaded,
  onLoaded,
}: DashboardChartProps<TData, TChartClass>) => {
  const { t } = useTranslation();
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const useSettingsQuery = dashboardApi.useSetting();
  const useChartsQuery = dashboardApi.useCharts<TData>(chart, filter);
  const useIndustryCompaniesCountQuery = dashboardApi.useIndustryCompaniesCount(
    chart,
    filter?.fromBusinessYearStartDate,
    filter?.toBusinessYearStartDate,
    filter?.companyType,
    filter?.companyIds,
    filter?.industryDivision
  );
  const useAllIndustryCompaniesCountQuery = dashboardApi.useAllIndustryCompaniesCount(
    chart,
    filter?.fromBusinessYearStartDate,
    filter?.toBusinessYearStartDate,
    filter?.companyType,
    filter?.companyIds
  );

  useEffect(() => {
    if (
      !useSettingsQuery.isLoading &&
      !useChartsQuery.isLoading &&
      !useChartsQuery.isFetching &&
      !useIndustryCompaniesCountQuery.isLoading &&
      !useIndustryCompaniesCountQuery.isFetching &&
      !useAllIndustryCompaniesCountQuery.isLoading &&
      !useAllIndustryCompaniesCountQuery.isFetching
    ) {
      onLoaded();
    }
  }, [
    useSettingsQuery.isLoading,
    useChartsQuery.isLoading,
    useChartsQuery.isFetching,
    useIndustryCompaniesCountQuery.isLoading,
    useIndustryCompaniesCountQuery.isFetching,
    useAllIndustryCompaniesCountQuery.isLoading,
    useAllIndustryCompaniesCountQuery.isFetching,
    onLoaded,
  ]);

  if (
    !isAllLoaded ||
    !useSettingsQuery.data ||
    !useChartsQuery.data ||
    !useIndustryCompaniesCountQuery.data ||
    !useAllIndustryCompaniesCountQuery.data
  )
    return null;

  const renderGraph = () => {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={chartInstance.getChartOptions(t, useChartsQuery.data, displaySwitch, false)}
        ref={chartComponentRef}
      />
    );
  };

  const renderTable = () => {
    return (
      <StyledPaper>
        <DashboardTable
          columns={
            Array.isArray(useChartsQuery.data)
              ? useChartsQuery.data[0].datasets.map(({ index }) => index)
              : useChartsQuery.data.datasets.map(({ index }) => index)
          }
          parentRows={chartInstance.getAggregateTypeRows(t, useChartsQuery.data, displaySwitch)}
          unitCaption={chartInstance.unitCaption}
          industryData={useIndustryCompaniesCountQuery.data}
          allIndustryData={useAllIndustryCompaniesCountQuery.data}
          notes={chartInstance.notes}
        />
      </StyledPaper>
    );
  };

  return (
    <>
      {/* TODO: 本来はシングルビューでも charts/index.tsx の方に DashboardChartDisplaySwitch を配置したいが、
          useChartsQueryからdisplayValuesを取得してきているため、やむなくここにおいている
          将来的には専用のAPIを用意してcharts/index.tsx側で完結させたい */}
      <Box mb={2}>
        <DashboardChartDisplaySwitch
          model={displaySwitch}
          updateModel={setDisplaySwitch}
          displayValues={chartInstance.getDisplayValues && chartInstance.getDisplayValues(t, useChartsQuery.data)}
          industryCompaniesCounts={useIndustryCompaniesCountQuery.data}
          allIndustryCompaniesCounts={useAllIndustryCompaniesCountQuery.data}
          inBoard={false}
          disabled={useChartsQuery.isLoading || useChartsQuery.isFetching || chartInstance.noData(useChartsQuery.data)}
        />
      </Box>
      {type === "graph" ? renderGraph() : renderTable()}
    </>
  );
};

export const DashboardChart = memo(_DashboardChart) as typeof _DashboardChart;
