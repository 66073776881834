import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { CHARACTER_LIMIT, RESERVED_SLUGS, SLUG_PATTERN } from "../../../config/const";
import { workspaceApi } from "../workspace-api";

export type WorkspaceFormModel = {
  name: string;
  slug: string;
  companyIds: string[];
};

type WorkspaceBaseFormProps = {
  title: string;
  buttonLabel: string;
  defaultValue: WorkspaceFormModel;
  open: boolean;
  loading: boolean;
  done: boolean;
  onSubmit: (value: WorkspaceFormModel) => void;
  onClose: () => void;
};

export const WorkspaceBaseForm = ({
  title,
  buttonLabel,
  defaultValue,
  open,
  loading,
  done,
  onSubmit,
  onClose,
}: WorkspaceBaseFormProps) => {
  const { t } = useTranslation();

  const companiesQuery = workspaceApi.useCompanies();

  const {
    handleSubmit,
    control,
    trigger,
    formState: { isValid },
  } = useForm<WorkspaceFormModel>({
    defaultValues: {
      name: defaultValue.name,
      slug: defaultValue.slug,
      companyIds: defaultValue.companyIds,
    },
    resolver: yupResolver(
      yup.object<yup.AnyObject, Record<keyof WorkspaceFormModel, yup.AnySchema>>({
        name: yup
          .string()
          .max(CHARACTER_LIMIT["80"], t("validation.character.limit", { number: CHARACTER_LIMIT["80"] }) as string)
          .required(t("validation.required") as string)
          .defined(),
        slug: yup
          .string()
          .max(CHARACTER_LIMIT["80"], t("validation.character.limit", { number: CHARACTER_LIMIT["80"] }) as string)
          .matches(SLUG_PATTERN, t("validation.slug") as string)
          .notOneOf(RESERVED_SLUGS, t("validation.slug.reserved") as string)
          .required(t("validation.required") as string)
          .defined(),
        companyIds: yup
          .array()
          .of(yup.string().defined())
          .min(1, t("validation.required") as string)
          .defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (done) onClose();
  }, [done, onClose]);

  if (companiesQuery.isLoading) return <LoadingModal open={companiesQuery.isLoading} />;
  if (!companiesQuery.data) return null;

  const renderNameField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("name")}</Typography>
      </Box>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            placeholder={t("placeholder.name") as string}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );

  const renderSlugField = () => (
    <>
      <Box mb={1} display="flex" alignItems="center">
        <Typography mr={0.5}>{t("slug")}</Typography>
        {/* i18nの言語ファイルで定義した改行をTooltip上で反映させるため */}
        <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>{t("slug.caption")}</span>}>
          <HelpOutlineIcon fontSize="small" sx={{ color: (theme) => theme.palette.grey[500] }} />
        </Tooltip>
      </Box>
      <Controller
        name="slug"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            placeholder={t("placeholder.slug") as string}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );

  const renderCompanyIdsField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("company")}</Typography>
      </Box>
      <Controller
        name="companyIds"
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            multiple
            options={companiesQuery.data}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={companiesQuery.data.filter(({ id }) => field.value.includes(id))}
            onChange={(_, values) => {
              field.onChange(values.map(({ id }) => id));
              trigger("companyIds");
            }}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props} selected={selected}>
                <Checkbox checked={selected} />
                <ListItemText primary={option.name} />
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                {...params}
                placeholder={field.value.length === 0 ? (t("placeholder.select") as string) : ""}
              />
            )}
          />
        )}
      />
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: "primary.main", color: "common.white" }}>{title}</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs={12} sm={8}>
                  {renderNameField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12} sm={8}>
                  {renderSlugField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderCompanyIdsField()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button disabled={!isValid || loading} variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={loading} />
    </>
  );
};
