import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DatabaseIcon from "./icon/database-icon";
import { MyTooltip } from "./my-tooltip";

type ButtonConfig = {
  label: string;
  unauthorized?: boolean;
  disabled?: boolean;
  onClick: () => void;
  variant?: "text" | "outlined" | "contained";
  bgcolor?: string;
};

type NotFoundProps = {
  title: string;
  caption: string;
  buttons: ButtonConfig[];
};

export const NotFound = ({ title, caption, buttons }: NotFoundProps) => {
  return (
    <Box my={4}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <DatabaseIcon />
        </Grid>
        <Grid item>
          <Box textAlign="center">
            <Typography>{title}</Typography>
            <Typography variant="caption">{caption}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justifyContent="center">
            {buttons.map((button, index) => (
              <Grid item key={index}>
                <MyTooltip unauthorized={button.unauthorized}>
                  <Button
                    variant={button.variant || "contained"}
                    disabled={button.disabled}
                    onClick={button.onClick}
                    sx={{ bgcolor: button.bgcolor || "" }}
                  >
                    {button.label}
                  </Button>
                </MyTooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
