import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { companyApi } from "../company-api";
import { CompanyBaseForm, CompanyFormModel } from "./company-base-form";

type CompanyNewFormProps = {
  open: boolean;
  onClose: () => void;
};

export const CompanyNewForm = ({ open, onClose }: CompanyNewFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const settingQuery = companyApi.useSetting();
  const createCompanyMutation = companyApi.useCreateCompany(t, enqueueSnackbar);

  if (settingQuery.isLoading) return <LoadingModal open={settingQuery.isLoading} />;
  if (!settingQuery.data) return null;

  const onSubmit = ({
    name,
    industryDivision,
    industryMajorGroup,
    industryGroup,
    industryDetail,
  }: CompanyFormModel) => {
    if (!industryMajorGroup) {
      return;
    }
    createCompanyMutation.mutate({
      name,
      industryDivision,
      industryMajorGroup,
      industryGroup: industryGroup ? industryGroup : null,
      industryDetail: industryDetail ? industryDetail : null,
    });
  };

  return (
    <CompanyBaseForm
      title={t("company.new")}
      buttonLabel={t("new")}
      defaultValue={{
        name: "",
        industryDivision: settingQuery.data.industryDivision,
        industryMajorGroup: "",
        industryGroup: "",
        industryDetail: "",
      }}
      open={open}
      loading={createCompanyMutation.isLoading}
      done={createCompanyMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
