import ButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";

export const StyledButtonGroup = styled((props: ButtonGroupProps) => <ButtonGroup {...props} />)(({ theme }) => ({
  "& .MuiButton-root": {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[400],
    "&:disabled": {
      color: theme.palette.grey[300],
      borderColor: theme.palette.grey[400],
    },
    "&:hover": {
      color: theme.palette.grey[700],
      borderColor: theme.palette.grey[400],
    },
  },
}));
