import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, MANAGEMENT_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, ManagementTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesLineOption, ManagementType } from "../../../types";
import { BaseLineChart } from "../base-line-chart";

export type BaseManagementTypeLineData = {
  managementType: ManagementType;
} & BaseData;

export type BaseManagementTypeLineChartSeriesCode = `${AggregateType}_${ManagementType}`;

export class BaseManagementTypeLineChart extends BaseLineChart<BaseManagementTypeLineData> {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseManagementTypeLineData> | ChartQueryResult<BaseManagementTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDef(t)),
    };
  }

  protected _getSeriesCode(datum: BaseManagementTypeLineData): BaseManagementTypeLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.managementType}`;
  }

  protected _createSeriesDef(t: TFunction): Map<BaseManagementTypeLineChartSeriesCode, ChartSeriesLineOption> {
    const results = new Map<BaseManagementTypeLineChartSeriesCode, ChartSeriesLineOption>();
    AGGREGATE_TYPES.forEach((aggregateType) => {
      MANAGEMENT_TYPES.forEach((managementType, i) => {
        results.set(`${aggregateType}_${managementType}`, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            ManagementTypeTextDef.get(managementType) as string
          )}`,
          color: super.getColor(aggregateType, i),
          dashStyle: super.getDashStyle(aggregateType),
        });
      });
    });
    return results;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseManagementTypeLineData> | ChartQueryResult<BaseManagementTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: MANAGEMENT_TYPES.map((managementType) => {
        return {
          header: t(ManagementTypeTextDef.get(managementType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.managementType === managementType
          ),
        };
      }),
    }));
  }
}
