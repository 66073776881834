import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { WorkspaceQueryResult, workspaceApi } from "../workspace-api";
import { WorkspaceBaseForm, WorkspaceFormModel } from "./workspace-base-form";

type WorkspaceEditFormProps = {
  target: WorkspaceQueryResult;
  open: boolean;
  onClose: () => void;
};

export const WorkspaceEditForm = ({ target, open, onClose }: WorkspaceEditFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { id, name, slug, companyIds } = target;

  const updateWorkspaceMutation = workspaceApi.useUpdateWorkspace(t, enqueueSnackbar, id);

  const onSubmit = ({ name, slug, companyIds }: WorkspaceFormModel) => {
    updateWorkspaceMutation.mutate({ id, name, slug, companyIds });
  };

  return (
    <WorkspaceBaseForm
      title={t("workspace.edit")}
      buttonLabel={t("save")}
      defaultValue={{ name, slug, companyIds }}
      open={open}
      loading={updateWorkspaceMutation.isLoading}
      done={updateWorkspaceMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
