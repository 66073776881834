import dayjs from "dayjs";

const DISPLAY_FORMAT = "YYYY/MM/DD HH:mm:ss";

const formatDisplayFromMutate = (datetimeStr: string): string => {
  return dayjs(datetimeStr).format(DISPLAY_FORMAT);
};

export const datetimeUtil = {
  formatDisplayFromMutate,
};
