import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";
import { CloseSnackbarButton } from "../../components/close-snackbar-button";
import { axiosInstance } from "../../lib/axios";
import { queryClient } from "../../lib/react-query";
import { BusinessYearVo } from "../../types/vo";
import { handleClientError } from "../../utils/handle-client-error";
import { snackbarUtil } from "../../utils/snackbar";

export type BusinessYearQueryResult = BusinessYearVo;

export type BusinessYearCreateRequest = {
  startDate: string;
  endDate: string;
  name: string;
};

export type BusinessYearUpdateRequest = {
  startDate: string; // apiでキーとして使う
  name: string;
};

// axios

const getBusinessYears = async (): Promise<BusinessYearQueryResult[]> => {
  return axiosInstance.get(`/business-years`);
};

const createBusinessYear = async (data: BusinessYearCreateRequest) => {
  return axiosInstance.post(`/business-years`, data);
};

const updateBusinessYear = async (data: BusinessYearUpdateRequest) => {
  return axiosInstance.put(`/business-years`, data);
};

const deleteBusinessYear = async (startDate: string): Promise<boolean> => {
  return axiosInstance.delete(`/business-years/${startDate}`);
};

// react-query

const useBusinessYears = () => {
  return useQuery({ queryKey: ["business-years"], queryFn: getBusinessYears });
};

const useCreateBusinessYear = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: createBusinessYear,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["business-years"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateBusinessYear = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: updateBusinessYear,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["business-years"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useDeleteBusinessYear = (t: TFunction, enqueSnackbar: EnqueueSnackbar, startDate: string) => {
  return useMutation({
    mutationFn: () => deleteBusinessYear(startDate),
    onSuccess: (data: boolean) => {
      if (data) {
        queryClient.invalidateQueries({ queryKey: ["business-years"] });
        enqueSnackbar(t("snackbar.deleted"), { variant: "success" });
      } else {
        const message = t("snackbar.business-year.not-deletable");
        enqueSnackbar(message, {
          autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_LONG,
          action: CloseSnackbarButton,
          style: { width: snackbarUtil.getCssWidthInPixel(message) },
        });
      }
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

export const businessYearApi = {
  useBusinessYears,
  useCreateBusinessYear,
  useUpdateBusinessYear,
  useDeleteBusinessYear,
};
