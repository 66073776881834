import { AxiosError, HttpStatusCode } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";

export const handleClientError = (e: AxiosError<string>, t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  if (e.response?.status === HttpStatusCode.BadRequest) {
    enqueSnackbar(t(e.response.data), { variant: "default" });
  } else {
    enqueSnackbar(t("snackbar.error.occurred"), { variant: "error" });
  }
};
