import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { PLACEHOLDER_OPACITY } from "../../../config/style";
import { TenantRoleTextDef } from "../../../config/text-def";
import { TenantRole } from "../../../types";

export type MemberFormModel = {
  email: string;
  role: TenantRole;
};

type MemberBaseFormProps = {
  edit?: boolean;
  title: string;
  buttonLabel: string;
  defaultValue: MemberFormModel;
  open: boolean;
  loading: boolean;
  done: boolean;
  onSubmit: (value: MemberFormModel) => void;
  onClose: () => void;
};

export const MemberBaseForm = ({
  edit = false,
  title,
  buttonLabel,
  defaultValue,
  open,
  loading,
  done,
  onSubmit,
  onClose,
}: MemberBaseFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<MemberFormModel>({
    defaultValues: {
      email: defaultValue.email,
      role: defaultValue.role,
    },
    resolver: yupResolver(
      yup.object<MemberFormModel>({
        email: yup
          .string()
          .email(t("validation.email") as string)
          .required(t("validation.required") as string)
          .defined(),
        role: yup
          .string()
          .required(t("validation.required") as string)
          .defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (done) onClose();
  }, [done, onClose]);

  const renderEmailField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("email")}</Typography>
      </Box>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={edit}
            placeholder={t("placeholder.email") as string}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );

  const renderRoleField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("role")}</Typography>
      </Box>
      <Controller
        name="role"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={Boolean(fieldState.error)} fullWidth>
            <Select
              {...field}
              renderValue={(selected) => {
                if (!selected) {
                  return <Typography sx={{ opacity: PLACEHOLDER_OPACITY }}>{t("placeholder.select")}</Typography>;
                }
                return t(TenantRoleTextDef.get(selected) as string);
              }}
            >
              {Array.from(TenantRoleTextDef).map(([key, i18nKey]) => (
                <MenuItem key={key} value={key}>
                  {t(i18nKey)}
                </MenuItem>
              ))}
            </Select>
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle sx={{ bgcolor: "primary.main", color: "common.white" }}>{title}</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs={12}>
                  {renderEmailField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12} sm={6}>
                  {renderRoleField()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button disabled={!isValid || loading} variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={loading} />
    </>
  );
};
