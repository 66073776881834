import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import {
  AGGREGATE_TYPES,
  DISABLED_EMPLOYMENT_RATE_TYPES,
  PHYSICAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES,
  INTELLECTUAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES,
  MENTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES,
  OTHER_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES,
} from "../../../config/const";
import {
  AggregateTypeTextDef,
  DisabledEmploymentHeadcountAndRateTypeTextDef,
  DisabledEmploymentTypeTextDef,
} from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  DisabledEmploymentHeadcountAndRateType,
  DisabledEmploymentHeadcountType,
  DisabledEmploymentRateType,
} from "../../../types";
import { BaseStackedColumnLineChart } from "../base-stacked-column-line-chart";

export type BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData = {
  disabledEmploymentHeadcountAndRateType: DisabledEmploymentHeadcountAndRateType;
} & BaseData;

type DisabledEmploymentHeadcountChartSeriesCode = `${AggregateType}_${DisabledEmploymentHeadcountType}`;
type DisabledEmploymentRateChartSeriesCode = `${AggregateType}_${DisabledEmploymentRateType}`;

type BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineChartSeriesCode =
  | DisabledEmploymentHeadcountChartSeriesCode
  | DisabledEmploymentRateChartSeriesCode;

export class BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineChart extends BaseStackedColumnLineChart<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData>
      | ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      const filteredColumnQueryResult = this._getEachDisabilityTypeTotal(columnQueryResult);
      const filteredQueryResult = [filteredColumnQueryResult, lineQueryResult];
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
        series: super.getSeries(filteredQueryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  private _getEachDisabilityTypeTotal(
    columnQueryResult: ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData>
  ): ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData> {
    const datasets = columnQueryResult.datasets.map((dataset) => {
      const data = dataset.data.filter(
        (datum) =>
          datum.disabledEmploymentHeadcountAndRateType == "physical_disabled_employee_headcount" ||
          datum.disabledEmploymentHeadcountAndRateType == "intellectual_disabled_employee_headcount" ||
          datum.disabledEmploymentHeadcountAndRateType == "mental_disabled_employee_headcount"
      );
      return {
        ...dataset,
        data,
      };
    });
    return {
      ...columnQueryResult,
      datasets,
    };
  }

  private _getSeriesCode(
    datum: BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData
  ): BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.disabledEmploymentHeadcountAndRateType}`;
  }

  private _createSeriesDefs(t: TFunction): [
    Map<DisabledEmploymentHeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<DisabledEmploymentRateChartSeriesCode, ChartSeriesLineOption>,
    BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<DisabledEmploymentHeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<DisabledEmploymentRateChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      // line
      DISABLED_EMPLOYMENT_RATE_TYPES.forEach((disabledEmploymentRateType, i) => {
        const code: DisabledEmploymentRateChartSeriesCode = `${aggregateType}_${disabledEmploymentRateType}`;
        orderedSeriesCodes.push(code);
        lineMap.set(code, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            DisabledEmploymentHeadcountAndRateTypeTextDef.get(disabledEmploymentRateType) as string
          )}`,
          color: super.getLineColor(aggregateType, i),
          dashStyle: super.getDashStyle(aggregateType),
        });
      });

      // column
      const displayDisableEmployeeHeadcountTypes: DisabledEmploymentHeadcountType[] = [
        "physical_disabled_employee_headcount",
        "intellectual_disabled_employee_headcount",
        "mental_disabled_employee_headcount",
      ];
      displayDisableEmployeeHeadcountTypes.toReversed().forEach((disableEmployeeHeadcountType, i, array) => {
        const code: DisabledEmploymentHeadcountChartSeriesCode = `${aggregateType}_${disableEmployeeHeadcountType}`;
        orderedSeriesCodes.push(code);
        columnMap.set(code, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            DisabledEmploymentHeadcountAndRateTypeTextDef.get(disableEmployeeHeadcountType) as string
          )}`,
          color: super.getColumnColor(aggregateType, array.length, i),
        });
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData>
      | ChartQueryResult<BaseDisabledEmploymentHeadcountAndRateTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          // 身体障害者数の表示のための処理
          ...PHYSICAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES.map((physicalDisabledEmploymentHeadcountType) => {
            return {
              header: `[${t(DisabledEmploymentTypeTextDef.get("physical") as string)}] ${t(
                DisabledEmploymentHeadcountAndRateTypeTextDef.get(physicalDisabledEmploymentHeadcountType) as string
              )}`,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.disabledEmploymentHeadcountAndRateType === physicalDisabledEmploymentHeadcountType
              ),
            };
          }),
          // 知的障害者数の表示のための処理
          ...INTELLECTUAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES.map((intellectualDisabledEmploymentHeadcountType) => {
            return {
              header: `[${t(DisabledEmploymentTypeTextDef.get("intellectual") as string)}] ${t(
                DisabledEmploymentHeadcountAndRateTypeTextDef.get(intellectualDisabledEmploymentHeadcountType) as string
              )}`,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.disabledEmploymentHeadcountAndRateType === intellectualDisabledEmploymentHeadcountType
              ),
            };
          }),
          // 知的障害者数の表示のための処理
          ...MENTAL_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES.map((mentalDisabledEmploymentHeadcountType) => {
            return {
              header: `[${t(DisabledEmploymentTypeTextDef.get("mental") as string)}] ${t(
                DisabledEmploymentHeadcountAndRateTypeTextDef.get(mentalDisabledEmploymentHeadcountType) as string
              )}`,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.disabledEmploymentHeadcountAndRateType === mentalDisabledEmploymentHeadcountType
              ),
            };
          }),
          // 障害者数合計の表示のための処理
          {
            header: `${t(DisabledEmploymentHeadcountAndRateTypeTextDef.get("disabled_employee_headcount") as string)}`,
            unit: columnQueryResult.unit,
            values: super.getValuesByCondition(
              columnQueryResult,
              (datum) =>
                datum.aggregateType === aggregateType &&
                datum.disabledEmploymentHeadcountAndRateType === "disabled_employee_headcount"
            ),
          },
          // 率の表示のための処理
          ...DISABLED_EMPLOYMENT_RATE_TYPES.map((disabledEmploymentRateType) => {
            return {
              header: t(
                DisabledEmploymentHeadcountAndRateTypeTextDef.get(disabledEmploymentRateType) as string
              ) as string,
              unit: lineQueryResult.unit,
              values: super.getValuesByCondition(
                lineQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.disabledEmploymentHeadcountAndRateType === disabledEmploymentRateType
              ),
            };
          }),
          // その他の障害者数の表示のための処理
          ...OTHER_DISABLED_EMPLOYMENT_HEADCOUNT_TYPES.map((otherDisabledEmploymentHeadcountType) => {
            return {
              header: t(
                DisabledEmploymentHeadcountAndRateTypeTextDef.get(otherDisabledEmploymentHeadcountType) as string
              ) as string,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.disabledEmploymentHeadcountAndRateType === otherDisabledEmploymentHeadcountType
              ),
            };
          }),
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
