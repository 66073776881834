import { YAxisOptions } from "highcharts";
import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../../components/dashboard-chart-display-switch";
import { ChartQueryResult } from "../../../../dashboard-api";
import { BaseManagementTypeLineChart, BaseManagementTypeLineData } from "../base-management-type-line-chart";

export class SalaryIncreaseRateByManagementTypeChart extends BaseManagementTypeLineChart {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseManagementTypeLineData> | ChartQueryResult<BaseManagementTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    const chartOptions: Highcharts.Options = super.getChartOptions(t, queryResult, displaySwitch, inBoard);
    // `_`は代入した値を使わないということを示す。
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { min: _, ...yAxis } = chartOptions.yAxis as YAxisOptions;

    return {
      ...{
        ...chartOptions,
        // 昇給率では値としてマイナスをとりうるので、yAxis.minをchartOptionsから除外するために
        // minを除外したyAxisで元のyAxis(minを含む)をoverrideする。
        yAxis,
      },
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDef(t)),
    };
  }
}
