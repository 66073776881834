import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LoginIcon from "@mui/icons-material/Login";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MoreMenu } from "../../../components/more-menu";
import { MyTooltip } from "../../../components/my-tooltip";
import { StyledAccordion } from "../../../components/styled/styled-accordion";
import { StyledAccordionSummary } from "../../../components/styled/styled-accordion-summary";
import { AuthStore } from "../../../stores/auth-store";
import { WorkspaceQueryResult } from "../workspace-api";
import { WorkspaceDeleteDialog } from "./workspace-delete-dialog";
import { WorkspaceDetail } from "./workspace-detail";
import { WorkspaceEditForm } from "./workspace-edit-form";

type WorkspaceSummaryProps = {
  workspace: WorkspaceQueryResult;
};

export const WorkspaceSummary = ({ workspace }: WorkspaceSummaryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isTenantOwner } = AuthStore.useContainer();
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [editFormOpen, setEditFormOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const onClickEdit = () => {
    setEditFormOpen(true);
  };

  const onClickDelete = () => {
    setDeleteDialogOpen(true);
  };

  const onClickNav = () => {
    navigate(`/${workspace.slug}`);
  };

  return (
    <React.Fragment key={workspace.id}>
      <StyledAccordion>
        <StyledAccordionSummary onClick={() => setDetailOpen((prev) => !prev)}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h6">{workspace.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    {t("workspace.companies.registered", { number: workspace.companyIds.length })}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <MoreMenu>
                <MenuItem onClick={onClickNav}>
                  <LoginIcon fontSize="small" sx={{ mr: 1 }} />
                  {t("workspace.navigation", { workspaceName: workspace.name })}
                </MenuItem>
                <Divider />
                <MyTooltip unauthorized={!isTenantOwner}>
                  <MenuItem disabled={!isTenantOwner} onClick={onClickEdit}>
                    <EditIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("edit")}
                  </MenuItem>
                </MyTooltip>
                <MyTooltip unauthorized={!isTenantOwner}>
                  <MenuItem
                    disabled={!isTenantOwner}
                    sx={{ color: (theme) => theme.palette.error.main }}
                    onClick={onClickDelete}
                  >
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                    {t("delete")}
                  </MenuItem>
                </MyTooltip>
              </MoreMenu>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
        {detailOpen && <WorkspaceDetail workspaceId={workspace.id} />}
      </StyledAccordion>
      {/** TODO: 本来はFormの中でWorkspaceを取得した方がいいかも（今は一覧表示した時のデータがそのまま表示されてる） */}
      {editFormOpen && (
        <WorkspaceEditForm target={workspace} open={editFormOpen} onClose={() => setEditFormOpen(false)} />
      )}
      {deleteDialogOpen && (
        <WorkspaceDeleteDialog target={workspace} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
    </React.Fragment>
  );
};
