import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/loading/loading";
import { axiosInstance } from "../lib/axios";
import { AuthStore } from "../stores/auth-store";
import { WorkspaceRole } from "../types";

export type CurrentWorkspaceQueryResult = {
  id: string;
  name: string;
  role: WorkspaceRole | null;
};

const getCurrentWorkspace = (wslug: string): Promise<CurrentWorkspaceQueryResult> => {
  return axiosInstance.get(`/workspaces/current`, { params: { wslug } });
};

const useCurrentWorkspace = (wslug?: string) => {
  return useQuery({
    queryKey: ["workspaces/current", wslug],
    queryFn: () => getCurrentWorkspace(wslug as string),
    enabled: !!wslug, // wslugが存在しない場合はクエリを実行しない
  });
};

type WorkspaceRouteGuardProps = {
  children: React.JSX.Element;
};

export const WorkspaceRouteGuard = ({ children }: WorkspaceRouteGuardProps) => {
  const { wslug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authStore = AuthStore.useContainer();

  const currentWorkspaceQuery = useCurrentWorkspace(wslug);

  useEffect(() => {
    if (currentWorkspaceQuery.isFetching) return;
    if (!currentWorkspaceQuery.data?.role) {
      enqueueSnackbar(t("snackbar.unauthorized-or-not-found"));
      navigate("/my-workspaces");
      return;
    }
    authStore.setCurrentWorkspace(currentWorkspaceQuery.data);
  }, [currentWorkspaceQuery, authStore, navigate, enqueueSnackbar, t]);

  if (currentWorkspaceQuery.isFetching || !authStore.currentWorkspace) {
    // 各ワークスペースのページでauthStore.currentWorkspaceを利用できるまでローディングを表示する
    return <Loading />;
  }

  return children;
};
