import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Theme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Link, LinkProps } from "react-router-dom";
import { DISABLED_OPACITY } from "../../../config/style";
import { Chart } from "../../../types";
import { SINGLE_YEAR_CHARTS } from "../config/const";

const StyledLink = styled((props: LinkProps) => <Link {...props} />)(() => ({
  color: "inherit",
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
}));

type DashboardChartDetailLinkProps = {
  noData: boolean;
  chart: Chart;
};

export const DashboardChartDetailLink = ({ noData, chart }: DashboardChartDetailLinkProps) => {
  const { t } = useTranslation();

  // チャート一覧でフィルタが実装されるまでは、年齢分布に限りチャート詳細に遷移できるようにしておく
  // TODO: チャート一覧でのフィルタ実装後、削除する
  return (
    <StyledLink
      onClick={(e) => e.stopPropagation()}
      to={`/charts/${chart}`}
      target="_blank"
      rel="noopener noreferrer"
      sx={!SINGLE_YEAR_CHARTS.includes(chart) && noData ? { pointerEvents: "none" } : undefined}
    >
      <OpenInNewIcon
        sx={(theme: Theme) => ({
          color: theme.palette.grey[600],
          opacity: !SINGLE_YEAR_CHARTS.includes(chart) && noData ? DISABLED_OPACITY : undefined,
        })}
      />
      <Typography ml={1} sx={!SINGLE_YEAR_CHARTS.includes(chart) && noData ? { opacity: DISABLED_OPACITY } : undefined}>
        {t("link.detail")}
      </Typography>
    </StyledLink>
  );
};
