import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useCallback } from "react";
import { Controller, useForm, Control, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { CHARACTER_LIMIT } from "../../../config/const";
import { IndustryTree } from "../../../config/industry";
import { PLACEHOLDER_OPACITY } from "../../../config/style";
import {
  IndustryDivisionTextDef,
  IndustryMajorGroupTextDef,
  IndustryGroupTextDef,
  IndustryDetailTextDef,
} from "../../../config/text-def";
import { IndustryDivision, IndustryMajorGroup, IndustryGroup, IndustryDetail } from "../../../types";

export type CompanyFormModel = {
  name: string;
  industryDivision: IndustryDivision;
  industryMajorGroup: IndustryMajorGroup | "";
  industryGroup: IndustryGroup | "";
  industryDetail: IndustryDetail | "";
};

type CompanyBaseFormProps = {
  title: string;
  buttonLabel: string;
  defaultValue: CompanyFormModel;
  open: boolean;
  loading: boolean;
  done: boolean;
  onSubmit: (value: CompanyFormModel) => void;
  onClose: () => void;
};

type RenderIndustryFieldProps<T> = {
  control: Control<CompanyFormModel, unknown>;
  title: string;
  placeholder: string;
  name: keyof CompanyFormModel;
  textDef: Map<T, string>;
  filterByIndustryCodeFunc?: (textDef: Map<T, string>) => [T, string][];
  isNotRequired: boolean;
  onChange?: (
    e: SelectChangeEvent<string>,
    field: ControllerRenderProps<CompanyFormModel, keyof CompanyFormModel>
  ) => void;
};

export const CompanyBaseForm = ({
  title,
  buttonLabel,
  defaultValue,
  open,
  loading,
  done,
  onSubmit,
  onClose,
}: CompanyBaseFormProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    setValue,
  } = useForm<CompanyFormModel>({
    defaultValues: {
      name: defaultValue.name,
      industryDivision: defaultValue.industryDivision,
      industryMajorGroup: defaultValue.industryMajorGroup,
      industryGroup: defaultValue.industryGroup,
      industryDetail: defaultValue.industryDetail,
    },
    resolver: yupResolver(
      yup.object<yup.AnyObject, Record<keyof CompanyFormModel, yup.AnySchema>>({
        name: yup
          .string()
          .max(CHARACTER_LIMIT["80"], t("validation.character.limit", { number: CHARACTER_LIMIT["80"] }) as string)
          .required(t("validation.required") as string)
          .defined(),
        industryDivision: yup
          .string()
          .required(t("validation.required") as string)
          .defined(),
        industryMajorGroup: yup
          .string()
          .required(t("validation.required") as string)
          .defined(),
        industryGroup: yup.string().defined(),
        industryDetail: yup.string().defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (done) onClose();
  }, [done, onClose]);

  const renderNameField = () => (
    <>
      <Box mb={1}>
        <Typography>{t("name")}</Typography>
      </Box>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            placeholder={t("placeholder.company") as string}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </>
  );

  const renderIndustryField = function <T extends string>({
    control,
    title,
    placeholder,
    name,
    textDef,
    filterByIndustryCodeFunc = (textDef: Map<T, string>) => Array.from(textDef),
    isNotRequired,
    onChange,
  }: RenderIndustryFieldProps<T>) {
    const filteredMenuItems = filterByIndustryCodeFunc(textDef);
    return (
      <>
        <Box mb={1}>
          <Typography component="span">{title}</Typography>
          {isNotRequired && (
            <Typography component="span" variant="caption">
              {` ${t("not-required.caption")}`}
            </Typography>
          )}
        </Box>
        <Controller
          name={name}
          control={control}
          defaultValue={""}
          render={({ field, fieldState }) => (
            <FormControl error={Boolean(fieldState.error)} fullWidth>
              <Select
                {...field}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <Typography sx={{ opacity: PLACEHOLDER_OPACITY }}>{placeholder}</Typography>;
                  }
                  return t(textDef.get(selected as T) as string);
                }}
                onChange={(e) => {
                  field.onChange(e);
                  if (!onChange) {
                    return;
                  }
                  onChange(e, field);
                }}
                disabled={filteredMenuItems.length === 0}
              >
                {filteredMenuItems.map(([key, i18nKey]) => (
                  <MenuItem key={key} value={key}>
                    {t(i18nKey)}
                  </MenuItem>
                ))}
              </Select>
              {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </>
    );
  };

  const industryDivision = watch("industryDivision");

  const renderIndustryDivisionField = () => {
    const handleChange = (
      e: SelectChangeEvent<string>,
      field: ControllerRenderProps<CompanyFormModel, keyof CompanyFormModel>
    ) => {
      if (e.target.value === field.value) {
        return;
      }
      setValue("industryMajorGroup", "");
      setValue("industryGroup", "");
      setValue("industryDetail", "");
    };
    return renderIndustryField({
      control,
      title: t("industry-division"),
      placeholder: t("placeholder.industry-division"),
      name: "industryDivision",
      textDef: IndustryDivisionTextDef,
      isNotRequired: false,
      onChange: handleChange,
    });
  };

  const filterByIndustryMajorGroupFunc = useCallback(
    (textDef: Map<IndustryMajorGroup, string>) => {
      const codes = IndustryTree.getDivision(industryDivision)
        ?.getMajorGroups()
        .map((majorGroup) => majorGroup.code);
      if (!codes) {
        return [];
      }
      return Array.from(textDef).filter(([code]) => codes.includes(code));
    },
    [industryDivision]
  );

  const industryMajorGroup = watch("industryMajorGroup");

  const renderIndustryMajorGroupField = () => {
    const handleChange = (
      e: SelectChangeEvent<string>,
      field: ControllerRenderProps<CompanyFormModel, keyof CompanyFormModel>
    ) => {
      if (e.target.value === field.value) {
        return;
      }
      setValue("industryGroup", "");
      setValue("industryDetail", "");
    };
    return renderIndustryField({
      control,
      title: t("industry-major-group"),
      placeholder: t("placeholder.industry-major-group"),
      name: "industryMajorGroup",
      textDef: IndustryMajorGroupTextDef,
      filterByIndustryCodeFunc: filterByIndustryMajorGroupFunc,
      isNotRequired: false,
      onChange: handleChange,
    });
  };

  const filterByIndustryGroupFunc = useCallback(
    (textDef: Map<IndustryGroup, string>) => {
      if (industryMajorGroup == "") {
        return [];
      }
      const codes = IndustryTree.getDivision(industryDivision)
        ?.getMajorGroup(industryMajorGroup)
        ?.getGroups()
        .map((group) => group.code);
      if (!codes) {
        return [];
      }
      return Array.from(textDef).filter(([code]) => codes.includes(code));
    },
    [industryDivision, industryMajorGroup]
  );

  const industryGroup = watch("industryGroup");

  const renderIndustryGroupField = () => {
    const handleChange = (
      e: SelectChangeEvent<string>,
      field: ControllerRenderProps<CompanyFormModel, keyof CompanyFormModel>
    ) => {
      if (e.target.value === field.value) {
        return;
      }
      setValue("industryDetail", "");
    };
    return renderIndustryField({
      control,
      title: t("industry-group"),
      placeholder: t("placeholder.industry-group"),
      name: "industryGroup",
      textDef: IndustryGroupTextDef,
      filterByIndustryCodeFunc: filterByIndustryGroupFunc,
      isNotRequired: true,
      onChange: handleChange,
    });
  };

  const filterByIndustryDetailFunc = useCallback(
    (textDef: Map<IndustryDetail, string>) => {
      if (industryMajorGroup == "" || industryGroup == "") {
        return [];
      }
      const codes = IndustryTree.getDivision(industryDivision)
        ?.getMajorGroup(industryMajorGroup)
        ?.getGroup(industryGroup)
        ?.getDetails()
        .map((detail) => detail.code);
      if (!codes) {
        return [];
      }
      return Array.from(textDef).filter(([code]) => codes.includes(code));
    },
    [industryDivision, industryMajorGroup, industryGroup]
  );

  const renderIndustryDetailField = () => {
    return renderIndustryField({
      control,
      title: t("industry-detail"),
      placeholder: t("placeholder.industry-detail"),
      name: "industryDetail",
      textDef: IndustryDetailTextDef,
      filterByIndustryCodeFunc: filterByIndustryDetailFunc,
      isNotRequired: true,
    });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: "primary.main", color: "common.white" }}>{title}</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid container item>
                <Grid item xs={12}>
                  {renderNameField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderIndustryDivisionField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderIndustryMajorGroupField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderIndustryGroupField()}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  {renderIndustryDetailField()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button disabled={!isValid || loading} variant="contained" onClick={handleSubmit(onSubmit)} autoFocus>
            {buttonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={loading} />
    </>
  );
};
