import { NationalityType } from "../types/nationality-type";

// マスタ情報提供サービスの国籍データ（https://support.works-hi.co.jp/mailLink.do?fNo=48474）の国籍コード参照
// ja.jsonの国名: データ参照元の国籍名称（国籍略称）
// en.jsonの国名: データ参照元の国籍名称（欧文略称）
export const NationalityTextDef = new Map<NationalityType, string>([
  ["AFG", "nationality.AFG"],
  ["AGO", "nationality.AGO"],
  ["ALB", "nationality.ALB"],
  ["AND", "nationality.AND"],
  ["ARE", "nationality.ARE"],
  ["ARG", "nationality.ARG"],
  ["ARM", "nationality.ARM"],
  ["ATG", "nationality.ATG"],
  ["AUS", "nationality.AUS"],
  ["AUT", "nationality.AUT"],
  ["AZE", "nationality.AZE"],
  ["BDI", "nationality.BDI"],
  ["BEL", "nationality.BEL"],
  ["BEN", "nationality.BEN"],
  ["BFA", "nationality.BFA"],
  ["BGD", "nationality.BGD"],
  ["BGR", "nationality.BGR"],
  ["BHR", "nationality.BHR"],
  ["BHS", "nationality.BHS"],
  ["BIH", "nationality.BIH"],
  ["BLR", "nationality.BLR"],
  ["BLZ", "nationality.BLZ"],
  ["BOL", "nationality.BOL"],
  ["BRA", "nationality.BRA"],
  ["BRB", "nationality.BRB"],
  ["BRN", "nationality.BRN"],
  ["BTN", "nationality.BTN"],
  ["BWA", "nationality.BWA"],
  ["CAF", "nationality.CAF"],
  ["CAN", "nationality.CAN"],
  ["CHE", "nationality.CHE"],
  ["CHL", "nationality.CHL"],
  ["CHN", "nationality.CHN"],
  ["CIV", "nationality.CIV"],
  ["CMR", "nationality.CMR"],
  ["COD", "nationality.COD"],
  ["COG", "nationality.COG"],
  ["COK", "nationality.COK"],
  ["COL", "nationality.COL"],
  ["COM", "nationality.COM"],
  ["CPV", "nationality.CPV"],
  ["CRI", "nationality.CRI"],
  ["CUB", "nationality.CUB"],
  ["CYP", "nationality.CYP"],
  ["CZE", "nationality.CZE"],
  ["DEU", "nationality.DEU"],
  ["DJI", "nationality.DJI"],
  ["DMA", "nationality.DMA"],
  ["DNK", "nationality.DNK"],
  ["DOM", "nationality.DOM"],
  ["DZA", "nationality.DZA"],
  ["ECU", "nationality.ECU"],
  ["EGY", "nationality.EGY"],
  ["ERI", "nationality.ERI"],
  ["ESP", "nationality.ESP"],
  ["EST", "nationality.EST"],
  ["ETH", "nationality.ETH"],
  ["FIN", "nationality.FIN"],
  ["FJI", "nationality.FJI"],
  ["FRA", "nationality.FRA"],
  ["FSM", "nationality.FSM"],
  ["GAB", "nationality.GAB"],
  ["GBR", "nationality.GBR"],
  ["GEO", "nationality.GEO"],
  ["GHA", "nationality.GHA"],
  ["GIN", "nationality.GIN"],
  ["GMB", "nationality.GMB"],
  ["GNB", "nationality.GNB"],
  ["GNQ", "nationality.GNQ"],
  ["GRC", "nationality.GRC"],
  ["GRD", "nationality.GRD"],
  ["GTM", "nationality.GTM"],
  ["GUY", "nationality.GUY"],
  ["HKG", "nationality.HKG"],
  ["HND", "nationality.HND"],
  ["HRV", "nationality.HRV"],
  ["HTI", "nationality.HTI"],
  ["HUN", "nationality.HUN"],
  ["IDN", "nationality.IDN"],
  ["IND", "nationality.IND"],
  ["IRL", "nationality.IRL"],
  ["IRN", "nationality.IRN"],
  ["IRQ", "nationality.IRQ"],
  ["ISL", "nationality.ISL"],
  ["ISR", "nationality.ISR"],
  ["ITA", "nationality.ITA"],
  ["JAM", "nationality.JAM"],
  ["JOR", "nationality.JOR"],
  ["JPN", "nationality.JPN"],
  ["KAZ", "nationality.KAZ"],
  ["KEN", "nationality.KEN"],
  ["KGZ", "nationality.KGZ"],
  ["KHM", "nationality.KHM"],
  ["KIR", "nationality.KIR"],
  ["KNA", "nationality.KNA"],
  ["KOR", "nationality.KOR"],
  ["KSV", "nationality.KSV"],
  ["KWT", "nationality.KWT"],
  ["LAO", "nationality.LAO"],
  ["LBN", "nationality.LBN"],
  ["LBR", "nationality.LBR"],
  ["LBY", "nationality.LBY"],
  ["LCA", "nationality.LCA"],
  ["LIE", "nationality.LIE"],
  ["LKA", "nationality.LKA"],
  ["LSO", "nationality.LSO"],
  ["LTU", "nationality.LTU"],
  ["LUX", "nationality.LUX"],
  ["LVA", "nationality.LVA"],
  ["MAC", "nationality.MAC"],
  ["MAR", "nationality.MAR"],
  ["MCO", "nationality.MCO"],
  ["MDA", "nationality.MDA"],
  ["MDG", "nationality.MDG"],
  ["MDV", "nationality.MDV"],
  ["MEX", "nationality.MEX"],
  ["MHL", "nationality.MHL"],
  ["MKD", "nationality.MKD"],
  ["MLI", "nationality.MLI"],
  ["MLT", "nationality.MLT"],
  ["MMR", "nationality.MMR"],
  ["MNE", "nationality.MNE"],
  ["MNG", "nationality.MNG"],
  ["MOZ", "nationality.MOZ"],
  ["MRT", "nationality.MRT"],
  ["MUS", "nationality.MUS"],
  ["MWI", "nationality.MWI"],
  ["MYS", "nationality.MYS"],
  ["NAM", "nationality.NAM"],
  ["NER", "nationality.NER"],
  ["NGA", "nationality.NGA"],
  ["NIC", "nationality.NIC"],
  ["NIU", "nationality.NIU"],
  ["NLD", "nationality.NLD"],
  ["NOR", "nationality.NOR"],
  ["NPL", "nationality.NPL"],
  ["NRU", "nationality.NRU"],
  ["NZL", "nationality.NZL"],
  ["OMN", "nationality.OMN"],
  ["PAK", "nationality.PAK"],
  ["PAN", "nationality.PAN"],
  ["PER", "nationality.PER"],
  ["PHL", "nationality.PHL"],
  ["PLW", "nationality.PLW"],
  ["PNG", "nationality.PNG"],
  ["POL", "nationality.POL"],
  ["PRK", "nationality.PRK"],
  ["PRT", "nationality.PRT"],
  ["PRY", "nationality.PRY"],
  ["PSE", "nationality.PSE"],
  ["QAT", "nationality.QAT"],
  ["ROM", "nationality.ROM"],
  ["RUS", "nationality.RUS"],
  ["RWA", "nationality.RWA"],
  ["SAU", "nationality.SAU"],
  ["SDN", "nationality.SDN"],
  ["SEN", "nationality.SEN"],
  ["SGP", "nationality.SGP"],
  ["SLB", "nationality.SLB"],
  ["SLE", "nationality.SLE"],
  ["SLV", "nationality.SLV"],
  ["SML", "nationality.SML"],
  ["SMR", "nationality.SMR"],
  ["SRB", "nationality.SRB"],
  ["SSD", "nationality.SSD"],
  ["STP", "nationality.STP"],
  ["SUR", "nationality.SUR"],
  ["SVK", "nationality.SVK"],
  ["SVN", "nationality.SVN"],
  ["SWE", "nationality.SWE"],
  ["SWZ", "nationality.SWZ"],
  ["SYC", "nationality.SYC"],
  ["SYR", "nationality.SYR"],
  ["TCD", "nationality.TCD"],
  ["TGO", "nationality.TGO"],
  ["THA", "nationality.THA"],
  ["TJK", "nationality.TJK"],
  ["TKM", "nationality.TKM"],
  ["TMP", "nationality.TMP"],
  ["TON", "nationality.TON"],
  ["TTO", "nationality.TTO"],
  ["TUN", "nationality.TUN"],
  ["TUR", "nationality.TUR"],
  ["TUV", "nationality.TUV"],
  ["TWN", "nationality.TWN"],
  ["TZA", "nationality.TZA"],
  ["UGA", "nationality.UGA"],
  ["UKR", "nationality.UKR"],
  ["URY", "nationality.URY"],
  ["USA", "nationality.USA"],
  ["UZB", "nationality.UZB"],
  ["VAT", "nationality.VAT"],
  ["VCT", "nationality.VCT"],
  ["VEN", "nationality.VEN"],
  ["VNM", "nationality.VNM"],
  ["VUT", "nationality.VUT"],
  ["WSM", "nationality.WSM"],
  ["YEM", "nationality.YEM"],
  ["ZAF", "nationality.ZAF"],
  ["ZMB", "nationality.ZMB"],
  ["ZWE", "nationality.ZWE"],
  ["other", "nationality.other"],
]);
