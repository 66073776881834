import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ChartTextDef, FormulaTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";

type DashboardFormulaProps = {
  open: boolean;
  onClose: () => void;
  chart: Chart;
};

export const DashboardFormula = ({ open, onClose, chart }: DashboardFormulaProps) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ bgcolor: "primary.main", color: "common.white" }}>
        {t("formula", { chartName: t(ChartTextDef.get(chart) as string) })}
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "fit-content" }}>
          <Typography sx={{ whiteSpace: "pre" }}>{t(FormulaTextDef.get(chart) as string)}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="normal" variant="contained" onClick={onClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
