import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { useQuery } from "@tanstack/react-query";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../lib/axios";
import { UserVo } from "../../types/vo";
import { COMPANYLogo } from "../icon/company-logo";

export const HEADER_HEIGHT = 48;

export type CurrentUserQueryResult = UserVo;

const getCurrentUser = (): Promise<CurrentUserQueryResult> => {
  return axiosInstance.get(`/users/current`);
};

const useCurrentUser = () => {
  return useQuery({ queryKey: ["users/current"], queryFn: () => getCurrentUser() });
};

export const GlobalHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentUserQuery = useCurrentUser();

  const onClickUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const renderUser = (name: string) => (
    <>
      <Button color="inherit" onClick={onClickUser}>
        <AccountCircleIcon sx={{ mr: 1, fontSize: 35 }} />
        {name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={onCloseUserMenu}
      >
        <MenuItem
          onClick={async () => {
            await Auth.signOut();
            navigate("/auth/login");
          }}
        >
          <LogoutIcon fontSize="large" sx={{ mr: 1 }} />
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <AppBar position="static" sx={{ px: 2 }}>
      <Toolbar disableGutters variant="dense" sx={{ columnGap: 2 }}>
        <Box height={HEADER_HEIGHT} flexGrow={1}>
          <COMPANYLogo title={t("service") as string} />
        </Box>
        {currentUserQuery.data && renderUser(currentUserQuery.data.name)}
      </Toolbar>
    </AppBar>
  );
};
