import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

type LoadingProps = {
  my?: number;
  size?: number;
};

export const Loading = ({ my = 24, size = 40 }: LoadingProps) => {
  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Box my={my}>
          <CircularProgress size={size} />
        </Box>
      </Grid>
    </Grid>
  );
};
