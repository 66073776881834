import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Loading } from "../../../components/loading/loading";
import { StyledAccordionDetails } from "../../../components/styled/styled-accordion-detail";
import { workspaceApi } from "../workspace-api";

type WorkspaceDetailProps = {
  workspaceId: string;
};

export const WorkspaceDetail = ({ workspaceId }: WorkspaceDetailProps) => {
  const workspaceCompaniesQuery = workspaceApi.useWorkspaceCompanies(workspaceId);

  if (workspaceCompaniesQuery.isLoading) return <Loading my={2} size={24} />;
  if (!workspaceCompaniesQuery.data) return null;

  return (
    <StyledAccordionDetails>
      {workspaceCompaniesQuery.data.map(({ id, name }) => (
        <Box key={id} mb={1}>
          <Box mb={1}>
            <Typography>{name}</Typography>
          </Box>
          <Divider />
        </Box>
      ))}
    </StyledAccordionDetails>
  );
};
