import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { ChartTextDef } from "../../../config/text-def";
import { Chart } from "../../../types";
import { CHART_INDICATOR_INDUSTRY_AVERAGE_RELATIONS } from "../config/const";
import { ChartIndicatorTypeTextDef } from "../config/text-def";
import { ChartIndicatorType } from "../types";

export const IndustryAverageSource = () => {
  const { t } = useTranslation();

  return (
    <TenantLayout activeKey="industry-average-source">
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6">{t("industry-average-source")}</Typography>
        </Grid>

        <Grid container item>
          <Grid container spacing={1}>
            <Grid container item>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>{t("industry-average-source.indicator-name")}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{t("industry-average-source.source")}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(CHART_INDICATOR_INDUSTRY_AVERAGE_RELATIONS).map(([chart, value], chartIndex) => (
                      <>
                        {Object.entries(value).map(([indicator, value], indicatorIndex) => (
                          <TableRow
                            key={`${chartIndex}-${indicatorIndex}`}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell>
                              <Typography>{t(ChartTextDef.get(chart as Chart) as string)}</Typography>
                              {chart !== indicator && (
                                <Typography variant="caption">
                                  {t(
                                    ChartIndicatorTypeTextDef.get(
                                      indicator.substring(chart.length + 1) as ChartIndicatorType
                                    ) as string
                                  )}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell>
                              {Object.values(value).map((value, sourceIndex) => (
                                <Typography key={`${chartIndex}-${indicatorIndex}-${sourceIndex}`}>
                                  {typeof value === "string" ? value : (value as string[]).join("、")}
                                </Typography>
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TenantLayout>
  );
};
