import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { dateUtil } from "../../../utils/date";
import { BusinessYearQueryResult, businessYearApi } from "../business-year-api";
import { BusinessYearBaseForm, BusinessYearFormModel } from "./business-year-base-form";

type BusinessYearEditFormProps = {
  target: BusinessYearQueryResult;
  open: boolean;
  onClose: () => void;
};

export const BusinessYearEditForm = ({ target, open, onClose }: BusinessYearEditFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { startDate, endDate, name } = target;

  const updateBusinessYearMutation = businessYearApi.useUpdateBusinessYear(t, enqueueSnackbar);

  const onSubmit = ({ name }: BusinessYearFormModel) => {
    updateBusinessYearMutation.mutate({ startDate, name });
  };

  return (
    <BusinessYearBaseForm
      edit
      title={t("business-year.edit")}
      buttonLabel={t("save")}
      defaultValue={{
        startDate: dateUtil.formatFromMutate(startDate),
        endDate: dateUtil.formatFromMutate(endDate),
        name,
      }}
      open={open}
      loading={updateBusinessYearMutation.isLoading}
      done={updateBusinessYearMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
