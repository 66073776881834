import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { workspaceApi } from "../workspace-api";
import { WorkspaceBaseForm, WorkspaceFormModel } from "./workspace-base-form";

type WorkspaceNewFormProps = {
  open: boolean;
  onClose: () => void;
};

export const WorkspaceNewForm = ({ open, onClose }: WorkspaceNewFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const createWorkspaceMutation = workspaceApi.useCreateWorkspace(t, enqueueSnackbar);

  const onSubmit = ({ name, slug, companyIds }: WorkspaceFormModel) => {
    createWorkspaceMutation.mutate({ name, slug, companyIds });
  };

  return (
    <WorkspaceBaseForm
      title={t("workspace.new")}
      buttonLabel={t("new")}
      defaultValue={{ name: "", slug: "", companyIds: [] }}
      open={open}
      loading={createWorkspaceMutation.isLoading}
      done={createWorkspaceMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
