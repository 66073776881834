import { AmplifyUser } from "@aws-amplify/ui";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { I18n, Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { neonTheme } from "../../../../config/neon-theme";
import "@aws-amplify/ui-react/styles.css";
import { queryClient } from "../../../../lib/react-query";

export const CustomAuthenticator = () => {
  // <--- イベントから呼び出される関数のカスタマイズ --->
  // リファレンス:
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#override-function-calls
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const customServices = {
    // ログイン認証成功時の処理
    async handleSignIn(formData: { username: string; password: string }): Promise<AmplifyUser> {
      const { username, password } = formData;

      queryClient.clear();
      return Auth.signIn({
        username,
        password,
      }).then((user) => {
        navigate("/", { state: { from: pathname }, replace: true });
        return user; // 戻り値は必要ないが、オーバーライド元の関数が要求するため記載
      });
    },
  };

  // <--- テキストのカスタマイズ --->
  const { t, i18n } = useTranslation();

  // Amplifyの日本語辞書をベースにローカライズする
  I18n.putVocabularies(translations);
  I18n.setLanguage(i18n.language);

  // placeholderなどのフォームフィールドは用意された引数でカスタマイズする
  // ラベルのカスタマイズは制約が多いため、非表示に変更し、後述のコンポーネントカスタマイズで上書きする
  // 各フォームのパラメータはデフォルトで必須のものとそうでないものが混在している、全てのパラメータについて"isRequired"を明示すること
  const customFormFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: t("email"),
        isRequired: true,
      },
      password: {
        labelHidden: true,
        placeholder: t("password"),
        isRequired: true,
      },
    },
    resetPassword: {
      username: {
        labelHidden: true,
        placeholder: t("email"),
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: true,
        placeholder: t("auth.placeholder.code"),
        isRequired: true,
      },
      password: {
        labelHidden: true,
        placeholder: t("auth.placeholder.new-password"),
        isRequired: true,
      },
      confirm_password: {
        labelHidden: true,
        placeholder: t("auth.placeholder.confirm-password"),
        isRequired: true,
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: true,
        placeholder: t("auth.placeholder.new-password"),
        isRequired: true,
      },
      confirm_password: {
        labelHidden: true,
        placeholder: t("auth.placeholder.confirm-password"),
        isRequired: true,
      },
    },
  };

  // フォームフィールドで非表示にしたラベルを自作のコンポーネントで上書きする
  // リファレンス:
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#headers--footers
  const customComponents = {
    SignIn: {
      Header() {
        return <StyledAmplifySignInFormMessage>{t("auth.login.caption")}</StyledAmplifySignInFormMessage>;
      },
    },
    ResetPassword: {
      Header() {
        return (
          <StyledAmplifyResetPasswordFormMessage>
            {t("auth.password.reset.caption")}
          </StyledAmplifyResetPasswordFormMessage>
        );
      },
    },
    ForceNewPassword: {
      FormFields() {
        return (
          <>
            <StyledAmplifyForceNewPasswordFormMessage>
              {t("auth.password.change.caption")}
            </StyledAmplifyForceNewPasswordFormMessage>
            <Authenticator.ForceNewPassword.FormFields />
          </>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <StyledAmplifyConfirmResetPasswordFormMessage>
            {t("auth.password.reset-confirm.caption")}
          </StyledAmplifyConfirmResetPasswordFormMessage>
        );
      },
    },
  };

  // フォームフィールド外のテキストは辞書を直接編集する
  // リファレンス:
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#labels--text
  // より多くのキーが掲載されている投稿:
  // https://qiita.com/ssugimoto/items/d0bc7540493499b6b154

  I18n.putVocabulariesForLanguage(i18n.language, {
    // ログインページ
    "Sign in": t("login"),
    "Forgot your password?": t("auth.password.reset"),
    "Signing in": t("logging-in"),
    // 一時パスワード変更ページ
    "Change Password": t("auth.password.change.submit"),
    // パスワードリセットページ
    "Send code": t("auth.password.reset.submit"),
    "Back to Sign In": t("auth.login.back"),
    // パスワード再設定ページ
    Submit: t("auth.password.reset-confirm.submit"),
    // フロントエンドのエラーメッセージ
    "Your passwords must match": t("auth.password.not-match"),
    "Confirmation code cannot be empty": t("auth.code.empty"),
    // バックエンドのエラーメッセージ
    "User does not exist.": t("cognito.auth.user.not-found"),
    "User is not confirmed.": t("cognito.auth.user.not-confirmed"),
    "User already exists": t("cognito.auth.user.duplicated"),
    "Incorrect username or password.": t("cognito.auth.login.invalid"),
    "Username cannot be empty": t("cognito.auth.login.no-user-input"),
    "Invalid password format": t("cognito.auth.login.invalid-password-format"),
    "Attempt limit exceeded, please try after some time.": t("cognito.auth.login.max-attempt"),
    "Password attempts exceeded": t("cognito.auth.login.max-login-attempt"),
    "Temporary password has expired and must be reset by an administrator.": t(
      "cognito.auth.login.expired-tmp-password"
    ),
    "Invalid verification code provided, please try again.": t("cognito.auth.password.invalid-code"),
    "Password does not conform to policy: Password must satisfy regular expression pattern: ^\\S.*\\S$": t(
      "cognito.auth.password.too-short-or-included-space"
    ),
    "Password does not conform to policy: Password not long enough": t("cognito.auth.password.not-long-enough"),
    "Password does not conform to policy: Password must have uppercase characters": t(
      "cognito.auth.password.lack-of-uppercase"
    ),
    "Password does not conform to policy: Password must have lowercase characters": t(
      "cognito.auth.password.lack-of-lowercase"
    ),
    "Password does not conform to policy: Password must have numeric characters": t(
      "cognito.auth.password.lack-of-numeric"
    ),
    "Password does not conform to policy: Password must have symbol characters": t(
      "cognito.auth.password.lack-of-symbol"
    ),
    "Password cannot be empty": t("cognito.auth.password.empty"),
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      t("cognito.auth.password.not-long-enough"),
    "Invalid session for the user, session is expired.": t("cognito.auth.session.expired"),
    "Network error": t("cognito.auth.network.error"),
  });

  // <--- デザインのカスタマイズ --->

  const amplifyTransparentColor = "var(--amplify-colors-transparent)";
  const baseFontSize = 16;
  const baseFontWeight = 400;
  const buttonFontSize = 14;
  const buttonHeight = 42;
  const buttonColor = "#004F94";
  const linkFontSize = 12;
  const inputFormHeight = 53;
  const inputFormBorderRadius = 4;
  const errorMessageTextColor = "#BE134C";
  const errorMessageBackgroundColor = "#FCE8EF";

  const StyledAmplifyFormBaseLabel = styled.div({
    fontSize: baseFontSize,
    fontWeight: baseFontWeight,
    color: neonTheme.palette.common.white,
    textAlign: "center",
    whiteSpace: "nowrap",
  });

  const StyledAmplifySignInFormMessage = styled(StyledAmplifyFormBaseLabel)(() => ({
    marginTop: 40,
    marginBottom: 20,
  }));

  const StyledAmplifyForceNewPasswordFormMessage = styled(StyledAmplifyFormBaseLabel)(() => ({
    marginTop: 40,
    marginBottom: 10,
  }));

  const StyledAmplifyResetPasswordFormMessage = styled(StyledAmplifyFormBaseLabel)(() => ({
    marginTop: 40,
    marginBottom: 10,
    marginLeft: -50, // TODO: 多言語対応をリリースしたときに文字長に応じて中央寄せを行う
  }));

  const StyledAmplifyConfirmResetPasswordFormMessage = styled(StyledAmplifyFormBaseLabel)(() => ({
    marginTop: 40,
    marginBottom: 10,
    marginLeft: -50, // TODO: 多言語対応をリリースしたときに文字長に応じて中央寄せを行う
  }));

  // Amplify以外のコンポーネントのデザイン方法を統一するために、emotionを利用したカスタマイズを行う
  //
  // リファレンス:
  // https://ui.docs.amplify.aws/react/guides/css-in-js
  // 変数の一覧: (＊画面上のどの要素に対応するかが分かりづらいため、ブラウザの開発者コンソールで調べる方法を推奨する)
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#additional-css-styling

  const StyledAuthenticator = styled(Authenticator)(() => ({
    "[data-amplify-theme]": {
      fontFamily: neonTheme.typography.fontFamily?.toString() ?? "", //Keyが存在しないことはあり得ないが型推量のため
    },
    "[data-amplify-form]": {
      width: 320,
      margin: "auto",
      padding: 0,
    },
    "[data-amplify-router]": {
      height: 410,
      backgroundColor: amplifyTransparentColor,
      boxShadow: "none",
      borderColor: amplifyTransparentColor,
    },
    "[data-amplify-footer]": {
      backgroundColor: amplifyTransparentColor,
    },
    // 'ForceNewPassword'などHeaderのoverrideができないコンポーネントが存在する。
    // カスタマイズ方法を統一するため、一律でHeaderを非表示にする。
    ".amplify-heading": {
      display: "none",
    },
    ".amplify-input": {
      backgroundColor: neonTheme.palette.common.white,
      height: inputFormHeight,
      marginBottom: 10,
      borderRadius: inputFormBorderRadius,
      borderColor: amplifyTransparentColor,
    },
    ".amplify-button--link": {
      color: neonTheme.palette.common.white,
    },
    ".amplify-label": {
      color: neonTheme.palette.common.white,
      textAlign: "center",
    },

    // パスワード入力フォーム中のパスワード表示ボタンを除いた入力部分のみ
    ".amplify-input.amplify-field-group__control": {
      borderRadius: inputFormBorderRadius,
    },
    // パスワード表示ボタンの背景要素
    ".amplify-field-group__outer-end": {
      backgroundColor: amplifyTransparentColor,
      height: inputFormHeight,
    },
    // パスワード表示ボタンのボタン要素
    ".amplify-button.amplify-field-group__control.amplify-field__show-password": {
      backgroundColor: neonTheme.palette.common.white,
      borderColor: amplifyTransparentColor,
      borderRadius: inputFormBorderRadius,
      marginLeft: 8,
    },

    // ログインページ/パスワードリセット リンク
    ".amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small": {
      fontSize: linkFontSize,
      fontWeight: baseFontWeight,
      padding: 0,
      marginTop: 20,
    },
    // ログイン/コード送信 ボタン
    ".amplify-button--primary": {
      backgroundColor: buttonColor,
      borderColor: amplifyTransparentColor,
      fontSize: buttonFontSize,
      fontWeight: baseFontWeight,
      height: buttonHeight,
    },
    // フロントエンドのエラーメッセージ
    ".amplify-text--error": {
      color: errorMessageTextColor,
      backgroundColor: errorMessageBackgroundColor,
      fontSize: 14,
      fontWeight: baseFontWeight,
      padding: 8,
      borderRadius: 4,
      textAlign: "center",
    },
    // バックエンドのエラーメッセージ
    ".amplify-alert": {
      color: errorMessageTextColor,
      backgroundColor: errorMessageBackgroundColor,
      fontSize: 14,
      fontWeight: baseFontWeight,
      padding: 8,
      borderRadius: 4,
      textAlign: "center",
    },
    // バックエンドのエラーメッセージの ×ボタン、!アイコンを消す
    ".amplify-alert__dismiss": {
      display: "none",
    },
    ".amplify-alert__icon": {
      display: "none",
    },
  }));

  return (
    <>
      <StyledAuthenticator
        hideSignUp={true}
        formFields={customFormFields}
        loginMechanisms={["email"]}
        components={customComponents}
        services={customServices}
      />
    </>
  );
};
