import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import {
  AGGREGATE_TYPES,
  ANNUAL_EMPLOYED_COMPOSITION_HEADCOUNT_TYPES,
  ANNUAL_EMPLOYED_COMPOSITION_RATIO_TYPES,
} from "../../../config/const";
import {
  AggregateTypeTextDef,
  EmploymentHeadcountTypeTextDef,
  EmploymentRatioTypeTextDef,
} from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  AnnualEmployedCompositionHeadcountType,
  AnnualEmployedCompositionRatioType,
} from "../../../types";
import { BaseStackedColumnLineChart } from "../base-stacked-column-line-chart";

type EmploymentCompositionType = AnnualEmployedCompositionHeadcountType | AnnualEmployedCompositionRatioType;

export type BaseEmploymentCompositionTypeStackedColumnLineData = {
  annualEmployedCompositionType: EmploymentCompositionType;
} & BaseData;

export type BaseEmploymentCompositionTypeStackedColumnLineChartSeriesCode =
  `${AggregateType}_${EmploymentCompositionType}`;

type EmploymentTypeCompositionHeadcountChartSeriesCode = `${AggregateType}_${AnnualEmployedCompositionHeadcountType}`;
type EmploymentTypeCompositionRatioChartSeriesCode = `${AggregateType}_${AnnualEmployedCompositionRatioType}`;

export class BaseEmploymentCompositionTypeStackedColumnLineChart extends BaseStackedColumnLineChart<BaseEmploymentCompositionTypeStackedColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseEmploymentCompositionTypeStackedColumnLineData>
      | ChartQueryResult<BaseEmploymentCompositionTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(
    datum: BaseEmploymentCompositionTypeStackedColumnLineData
  ): BaseEmploymentCompositionTypeStackedColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.annualEmployedCompositionType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<EmploymentTypeCompositionHeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<EmploymentTypeCompositionRatioChartSeriesCode, ChartSeriesLineOption>,
    BaseEmploymentCompositionTypeStackedColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<EmploymentTypeCompositionHeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<EmploymentTypeCompositionRatioChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseEmploymentCompositionTypeStackedColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      ANNUAL_EMPLOYED_COMPOSITION_RATIO_TYPES.filter((type) => type !== "other_ratio")
        .toReversed()
        .forEach((annualEmployedCompositionRatioType, i) => {
          const code: EmploymentTypeCompositionRatioChartSeriesCode = `${aggregateType}_${annualEmployedCompositionRatioType}`;
          orderedSeriesCodes.push(code);
          lineMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              EmploymentRatioTypeTextDef.get(annualEmployedCompositionRatioType) as string
            )}`,
            color: super.getLineColor(aggregateType, i),
            dashStyle: super.getDashStyle(aggregateType),
          });
        });
      ANNUAL_EMPLOYED_COMPOSITION_HEADCOUNT_TYPES.filter((type) => type !== "all_headcount")
        .toReversed()
        .forEach((AnnualEmployedCompositionHeadcountType, i, array) => {
          const code: EmploymentTypeCompositionHeadcountChartSeriesCode = `${aggregateType}_${AnnualEmployedCompositionHeadcountType}`;
          orderedSeriesCodes.push(code);
          columnMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              EmploymentHeadcountTypeTextDef.get(AnnualEmployedCompositionHeadcountType) as string
            )}`,
            color: super.getColumnColor(aggregateType, array.length, i),
          });
        });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseEmploymentCompositionTypeStackedColumnLineData>
      | ChartQueryResult<BaseEmploymentCompositionTypeStackedColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          ...ANNUAL_EMPLOYED_COMPOSITION_HEADCOUNT_TYPES.map((annualEmployedCompositionHeadcountType) => {
            return {
              header: t(EmploymentHeadcountTypeTextDef.get(annualEmployedCompositionHeadcountType) as string) as string,
              unit: columnQueryResult.unit,
              values: super.getValuesByCondition(
                columnQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.annualEmployedCompositionType === annualEmployedCompositionHeadcountType
              ),
            };
          }),
          ...ANNUAL_EMPLOYED_COMPOSITION_RATIO_TYPES.map((annualEmployedCompositionRatioType) => {
            return {
              header: t(EmploymentRatioTypeTextDef.get(annualEmployedCompositionRatioType) as string) as string,
              unit: lineQueryResult.unit,
              values: super.getValuesByCondition(
                lineQueryResult,
                (datum) =>
                  datum.aggregateType === aggregateType &&
                  datum.annualEmployedCompositionType === annualEmployedCompositionRatioType
              ),
            };
          }),
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
