import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { HEADER_HEIGHT } from "./layout/global-header";

type StyledPaperProps = {
  width: number;
};

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "width", // 'width' プロパティが DOM に渡らないようにする
})<StyledPaperProps>(({ width }) => ({
  width: width,
  minWidth: width,
  height: `calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px)`,
  border: "none",
  borderRadius: 0,
  display: "flex",
  flexDirection: "column",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxShadow: "none",
}));

type SideDialogProps = {
  open: boolean;
  onClose?: () => void;
  title: string;
  width?: number;
  children: React.ReactNode;
};

export const SideDialog = ({ open, onClose, title, width = 300, children }: SideDialogProps) => {
  return open ? (
    <StyledPaper width={width}>
      {/* タイトル */}
      <Grid container px={2} py={1} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item mr={-1}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      {/* コンテンツ */}
      {children}
    </StyledPaper>
  ) : (
    <></>
  );
};
