const DatabaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 512 512"
    style={{ transform: "scaleY(-1)" }}
  >
    <path
      fill="#bcbcbc"
      d="M444.272 161.648c-23.047-37.247-101.109-64.471-188.273-64.471-87.108 0-165.239 27.224-188.259 64.471-4.888 7.892-3.741 3.659-3.741-0.246 0-3.933 0-54.668 0-54.668 0-52.947 86.432-106.734 192-106.734s192 53.787 192 106.734c0 0 0 50.736 0 54.668 0 3.905 1.106 8.137-3.728 0.246zM444.546 301.322c-22.637-32.931-101.027-57.071-188.546-57.071s-165.922 24.139-188.559 57.071c-4.697 6.799-3.441 3.113-3.441 0.025 0-3.086 0-64.334 0-64.334 0-48.142 86.432-87.135 192-87.135s192 38.994 192 87.135c0 0 0 61.249 0 64.334s1.216 6.773-3.454-0.025zM256 480c-105.567 0-192-32.67-192-72.374v-34.379c0-42.107 86.432-76.24 192-76.24s192 34.133 192 76.24v34.352c0 39.731-86.432 72.401-192 72.401z"
    />
  </svg>
);

export default DatabaseIcon;
