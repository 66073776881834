import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorFallback } from "../components/error-fallback";
import { GlobalLayout } from "../components/layout/global-layout";
import { Loading } from "../components/loading/loading";
import { AuthRoutes } from "../features/auth/routes";
import { BusinessYear } from "../features/business-years/routes";
import { Company } from "../features/companies/routes";
import { Dashboard } from "../features/dashboard/routes";
import { DashboardBoardIndex } from "../features/dashboard/routes/boards/[id]";
import { DashboardChartIndex } from "../features/dashboard/routes/charts/[id]";
import { DashboardStore } from "../features/dashboard/stores/dashboard-store";
import { IndustryAverageSource } from "../features/industry-average-sources/routes";
import { Member } from "../features/members/routes";
import { Setting } from "../features/settings/routes";
import { Workspace } from "../features/workspaces/routes";
import { isAuthenticated } from "../lib/amplify";
import { AuthStore } from "../stores/auth-store";
import { TenantStore } from "../stores/tenant-store";
import { WorkspaceStore } from "../stores/workspace-store";
import { lazyImport } from "../utils/lazy-import";
import { TenantRouteGuard } from "./tenant-route-guard";
import { WorkspaceRouteGuard } from "./workspace-route-guard";

const { MyWorkspace } = lazyImport(() => import("../features/my-workspaces/routes"), "MyWorkspace");
const { WorkspaceRoutes } = lazyImport(() => import("../features/[wslug]/routes"), "WorkspaceRoutes");

const App = () => {
  return (
    <GlobalLayout>
      <React.Suspense fallback={<Loading />}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </React.Suspense>
    </GlobalLayout>
  );
};

const privateRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <TenantRouteGuard>
            <TenantStore.Provider>
              <Outlet />
            </TenantStore.Provider>
          </TenantRouteGuard>
        ),
        children: [
          // Outletに配置される
          // テナント
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "/charts/:id",
            element: (
              <DashboardStore.Provider>
                <DashboardChartIndex />
              </DashboardStore.Provider>
            ),
          },
          {
            path: "/boards/:id",
            element: (
              <DashboardStore.Provider>
                <DashboardBoardIndex />
              </DashboardStore.Provider>
            ),
          },
          {
            path: "/workspaces",
            element: <Workspace />,
          },
          {
            path: "/members",
            element: <Member />,
          },
          {
            path: "/companies",
            element: <Company />,
          },
          {
            path: "/business-years",
            element: <BusinessYear />,
          },
          {
            path: "/settings",
            element: <Setting />,
          },
          {
            path: "/industry-average-sources",
            element: <IndustryAverageSource />,
          },
          // マイワークスペース
          { path: "/my-workspaces", element: <MyWorkspace /> },
        ],
      },
      {
        path: "/:wslug/*",
        element: (
          <WorkspaceRouteGuard>
            <WorkspaceStore.Provider>
              <WorkspaceRoutes />
            </WorkspaceStore.Provider>
          </WorkspaceRouteGuard>
        ),
      },
    ],
  },
];

const publicRoutes = [
  { path: "/auth/*", element: <AuthRoutes /> },
  { path: "/*", element: <Navigate to="/auth/login" /> },
];

export const AppRoutes = () => {
  const { user, authStatus } = useAuthenticator((context) => [context.authStatus]);
  const authStore = AuthStore.useContainer();

  useEffect(() => {
    // useAuthenticatorの"user"は反映にラグがあり、ログアウト直後にもデータが残存しているケースがあるため、"authStatus"を判定し、authStoreに格納する値を判別する。
    if (isAuthenticated(authStatus)) {
      authStore.setUserId(user?.attributes?.["custom:user_id"]);
    } else {
      authStore.setUserId(undefined);
    }
  }, [user, authStatus, authStore]);

  // 2023/7/11時点でお試しData APIのusePromptを利用するために、useRoutesではなく、createBrowserRouterを利用する。
  const router = createBrowserRouter(
    isAuthenticated(authStatus)
      ? privateRoutes
      : authStatus === "configuring" // 初回描画時、authStatusが"configuring"状態となり認証の判定が行えない。そのため、"configuring"の間はパスを保持しつつ待機する。
      ? [{ path: "/*", element: <Loading /> }]
      : publicRoutes
  );
  return <RouterProvider router={router} />;
};
