import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { GridPaginationModel, DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RunningIcon } from "../../../components/icon/running-icon";
import { DATA_GRID_PAGE_SIZE, NONE } from "../../../config/const";
import { DATA_GRID_FOOTER_HEIGHT, DATA_GRID_NO_ROWS_HEIGHT } from "../../../config/style";
import { ExportJobStatusTextDef } from "../../../config/text-def";
import { JobStatus, LastEvaluatedKey } from "../../../types";
import { ExportJobVo } from "../../../types/vo";
import { datetimeUtil } from "../../../utils/datetime";
import { dashboardApi } from "../dashboard-api";

export const DashboardExportJob = () => {
  const { t } = useTranslation();
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState<(LastEvaluatedKey | null)[]>([null]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DATA_GRID_PAGE_SIZE,
  });
  const [displayItems, setDisplayItems] = useState<ExportJobVo[]>([]);

  const exportJobsQuery = dashboardApi.useExportJobs(lastEvaluatedKeys[paginationModel.page], paginationModel.pageSize);

  const exportJobsCountQuery = dashboardApi.useExportJobsCount();

  const updatePaginationModel = (newPaginationModel: GridPaginationModel) => {
    if (!exportJobsQuery.data) return;
    setPaginationModel(newPaginationModel);
    setLastEvaluatedKeys((prev) => {
      const newLastEvaluatedKeys = [...prev];
      newLastEvaluatedKeys[paginationModel.page + 1] = exportJobsQuery.data.lastEvaluatedKey;
      return newLastEvaluatedKeys;
    });
    setDisplayItems([...exportJobsQuery.data.items]);
  };

  // ページ遷移のLoading時に前のページのデータを表示する
  const getRows = () => {
    if (exportJobsQuery.isLoading) {
      return displayItems;
    }
    return exportJobsQuery.data ? exportJobsQuery.data.items : [];
  };

  const renderJobStatusIcon = (jobStatus: JobStatus) => {
    switch (jobStatus) {
      case "submitted":
        return <WatchLaterIcon sx={{ mr: 1, color: (theme) => theme.palette.grey[500] }} />;
      case "running":
        return <RunningIcon sx={{ mr: 1 }} />;
      case "succeeded":
        return <CheckCircleIcon sx={{ mr: 1, color: (theme) => theme.palette.success.main }} />;
      case "failed":
        return <ErrorIcon sx={{ mr: 1, color: (theme) => theme.palette.error.main }} />;
    }
  };

  const renderFileNameCell = ({ id, fileName, status }: ExportJobVo) => {
    const exportJobsDownloadUrlQuery = dashboardApi.useExportJobsDownloadUrl(id, fileName);
    const onClickLink = async () => {
      exportJobsDownloadUrlQuery.refetch().then((response) => {
        const url = response.data;
        if (url) {
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
        }
      });
    };

    if (status !== "succeeded") {
      return NONE;
    }

    return (
      <Typography
        component="a"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClickLink();
        }}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {fileName}
      </Typography>
    );
  };

  const columns: GridColDef<ExportJobVo>[] = [
    {
      field: "status",
      headerName: t("status") as string,
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => {
        return (
          <Box display="flex" alignItems="center" height="100%">
            {renderJobStatusIcon(row.status)}
            {row.status === "failed" && row.errorMessage?.ja ? (
              <Tooltip title={row.errorMessage.ja}>
                <Typography
                  sx={{
                    textDecoration: "underline dashed",
                    textDecorationColor: (theme) => theme.palette.grey[600],
                  }}
                >
                  {t(ExportJobStatusTextDef.get(row.status) as string)}
                </Typography>
              </Tooltip>
            ) : (
              t(ExportJobStatusTextDef.get(row.status) as string)
            )}
          </Box>
        );
      },
    },
    {
      field: "businessYearName",
      headerName: t("business-year") as string,
      flex: 1,
      minWidth: 140,
      renderCell: ({ row }) => `${row.fromBusinessYearName}〜${row.toBusinessYearName}`,
    },
    {
      field: "fileName",
      headerName: t("file") as string,
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => {
        return renderFileNameCell(row);
      },
    },
    {
      field: "userName",
      headerName: t("user") as string,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "startTime",
      headerName: t("start-time") as string,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        if (row.startTime) {
          return datetimeUtil.formatDisplayFromMutate(row.startTime);
        }
        return NONE;
      },
    },
    {
      field: "endTime",
      headerName: t("end-time") as string,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        if (row.endTime) {
          return datetimeUtil.formatDisplayFromMutate(row.endTime);
        }
        return NONE;
      },
    },
  ];

  return (
    <DataGrid
      sx={{
        bgcolor: (theme) => theme.palette.common.white,
        "& .MuiDataGrid-columnHeader": {
          bgcolor: (theme) => theme.palette.common.white,
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-footerContainer": {
          height: DATA_GRID_FOOTER_HEIGHT,
          minHeight: DATA_GRID_FOOTER_HEIGHT,
        },
        "& .MuiDataGrid-row:nth-of-type(1) .MuiDataGrid-cell": {
          borderTop: "none",
        },
        minHeight: DATA_GRID_NO_ROWS_HEIGHT,
      }}
      getRowId={(row) => row.id}
      rows={getRows()}
      columns={columns}
      rowSelection={false}
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnSelector
      disableColumnSorting
      density="compact"
      autoHeight
      rowCount={exportJobsCountQuery.data ?? 0}
      paginationModel={paginationModel}
      pageSizeOptions={[DATA_GRID_PAGE_SIZE]}
      paginationMode="server"
      onPaginationModelChange={updatePaginationModel}
      loading={exportJobsQuery.isLoading || exportJobsCountQuery.isLoading}
      slots={{
        noRowsOverlay: () => (
          <Box height={DATA_GRID_NO_ROWS_HEIGHT} display="flex" justifyContent="center" alignItems="center">
            <Typography color={(theme) => theme.palette.grey[500]}>{t("no-data")}</Typography>
          </Box>
        ),
      }}
    />
  );
};
