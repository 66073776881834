import { useMutation, useQuery } from "@tanstack/react-query";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";
import { axiosInstance } from "../../lib/axios";
import { queryClient } from "../../lib/react-query";
import { IndustryDivision } from "../../types";
import { TenantVo } from "../../types/vo";

export type SettingQueryResult = TenantVo;

export type SettingUpdateRequest = {
  name: string;
  industryDivision: IndustryDivision;
};

// axios

const getSetting = async (): Promise<SettingQueryResult> => {
  return axiosInstance.get("/setting");
};

const updateSetting = async (data: SettingUpdateRequest) => {
  // ここでreturnしないと、isLoading等がうまく動かない
  return axiosInstance.post("/setting", data);
};

// react-query

const useSetting = () => {
  return useQuery({ queryKey: ["setting"], queryFn: getSetting });
};

const useUpdateSetting = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: updateSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["setting"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
  });
};

export const settingApi = {
  useSetting,
  useUpdateSetting,
};
