import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { filterUtil } from "../../../utils/filter";
import { gridUtil } from "../../../utils/grid";
import { numberUtil } from "../../../utils/number";
import { ChartUnitTextDef, ParentRowTextDef } from "../config/text-def";
import { IndustryCompaniesCountQueryResult } from "../dashboard-api";
import { AggregateType, AggregateTypeRow, CalcBasisItem, CalcBasisItemRow } from "../types";
import { DashboardCompaniesCountTooltip } from "./dashboard-companies-count-tooltip";

const MIN_COLUMN_WIDTH_LIMIT = 240;
const MIN_COLUMN_WIDTH_MARGIN = 62;

const ALTERNATE_ROW_COLOR = "#f8f9fa";
const BORDER_COLOR = "#e0e0e0";
const BORDER_STYLE = `1px solid ${BORDER_COLOR}`;

type DashboardTableProps = {
  columns: string[];
  parentRows: AggregateTypeRow[] | CalcBasisItemRow[];
  unitCaption?: string;
  notes?: string[];
  industryData?: IndustryCompaniesCountQueryResult[];
  allIndustryData?: IndustryCompaniesCountQueryResult[];
};

export const DashboardTable = ({
  columns,
  parentRows,
  unitCaption,
  notes,
  industryData,
  allIndustryData,
}: DashboardTableProps) => {
  const { t } = useTranslation();
  const tableRef = useRef<HTMLTableElement>(null);
  const [firstColumnWidth, setFirstColumnWidth] = useState<number>();

  // rowHeaderを持つ表で横スクロールが必要な場合に、rowHeaderを固定するために表の1列目の幅を再計算するため
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const firstOffsetWidth = (tableRef.current?.querySelector("th:first-child") as HTMLTableElement)?.offsetWidth;
      setFirstColumnWidth(firstOffsetWidth);
    });

    if (tableRef.current) {
      observer.observe(tableRef.current);
    }
  }, []);

  const getStickyCellStyle = (left = 0): React.CSSProperties => {
    return {
      position: "sticky",
      left: left,
      zIndex: 1,
    };
  };

  const renderHead = (
    columns: string[],
    hasRowHeader: boolean,
    columnHeaderMinWidth: number,
    rowHeaderMinWidth: number
  ) => (
    <TableHead sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
      <TableRow>
        <TableCell
          sx={{ minWidth: columnHeaderMinWidth, bgcolor: (theme) => theme.palette.grey[100], ...getStickyCellStyle() }}
        ></TableCell>
        {hasRowHeader && (
          <TableCell
            sx={{
              minWidth: rowHeaderMinWidth,
              bgcolor: (theme) => theme.palette.grey[100],
              ...getStickyCellStyle(firstColumnWidth),
            }}
          ></TableCell>
        )}
        {columns.map((column) => (
          <TableCell key={column} align="right" sx={{ minWidth: 120 }}>
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const getRowBgcolor = (index: number, theme: Theme) => {
    const defaultColor = theme.palette.common.white;
    return index % 2 === 1 ? ALTERNATE_ROW_COLOR : defaultColor;
  };

  const renderBody = (
    parentRows: AggregateTypeRow[] | CalcBasisItemRow[],
    industryData?: IndustryCompaniesCountQueryResult[],
    allIndustryData?: IndustryCompaniesCountQueryResult[]
  ) => (
    <TableBody>
      {parentRows.map((parentRow, parentRowIndex) => {
        const isIndicatorTable = "aggregateType" in parentRow;
        const parentHeader: AggregateType | CalcBasisItem = isIndicatorTable
          ? parentRow.aggregateType
          : parentRow.calcBasisItem;
        return parentRow.rows.map(({ header, unit, values }, rowIndex) => (
          <TableRow
            hover
            key={`${parentRowIndex}-${rowIndex}`}
            sx={{
              bgcolor: (theme) => getRowBgcolor(parentRowIndex, theme),
            }}
          >
            {rowIndex === 0 && (
              <TableCell
                rowSpan={parentRow.rows.length}
                sx={{
                  borderRight: header ? "none" : BORDER_STYLE,
                  bgcolor: (theme) => getRowBgcolor(parentRowIndex, theme),
                  ...getStickyCellStyle(),
                }}
              >
                <Box display="flex" alignItems="center">
                  <Box mr={0.5}>{t(ParentRowTextDef.get(parentHeader) as string)}</Box>

                  {parentHeader === "industry_average" && (
                    <DashboardCompaniesCountTooltip
                      i18nKey="dashboard.industry-companies-count"
                      data={industryData ?? []}
                    />
                  )}
                  {parentHeader === "all_industry_average" && (
                    <DashboardCompaniesCountTooltip
                      i18nKey="dashboard.all-industry-companies-count"
                      data={allIndustryData ?? []}
                    />
                  )}
                </Box>
              </TableCell>
            )}
            {header && (
              // 行のヘッダーがある場合は、セルを表示
              // parentRowsの最後の行でない場合は、sxで罫線を表示しないように設定
              <TableCell
                sx={
                  rowIndex === parentRow.rows.length - 1
                    ? {
                        borderRight: BORDER_STYLE,
                        bgcolor: (theme) => getRowBgcolor(parentRowIndex, theme),
                        ...getStickyCellStyle(firstColumnWidth),
                      }
                    : {
                        borderBottom: "none",
                        borderRight: BORDER_STYLE,
                        bgcolor: (theme) => getRowBgcolor(parentRowIndex, theme),
                        ...getStickyCellStyle(firstColumnWidth),
                      }
                }
              >
                {header}
              </TableCell>
            )}
            {values.map((value, valueIndex) => (
              <TableCell
                key={`${parentRowIndex}-${rowIndex}-${valueIndex}`}
                align="right"
                sx={rowIndex === parentRow.rows.length - 1 ? undefined : { borderBottom: "none" }}
              >
                {value === null
                  ? "-"
                  : unit === "percent"
                  ? `${numberUtil.formatWithCommas(value)}${t(ChartUnitTextDef.get(unit) as string)}`
                  : numberUtil.formatWithCommas(value)}
              </TableCell>
            ))}
          </TableRow>
        ));
      })}
    </TableBody>
  );

  const renderTable = (
    columns: string[],
    parentRows: AggregateTypeRow[] | CalcBasisItemRow[],
    unitCaption?: string,
    notes?: string[],
    industryData?: IndustryCompaniesCountQueryResult[],
    allIndustryData?: IndustryCompaniesCountQueryResult[]
  ) => {
    const isIndicatorTable = "aggregateType" in parentRows[0];
    const parentHeaders = isIndicatorTable
      ? Array.from(new Set((parentRows as AggregateTypeRow[]).map(({ aggregateType }) => aggregateType)).values())
      : Array.from(new Set((parentRows as CalcBasisItemRow[]).map(({ calcBasisItem }) => calcBasisItem)).values());
    const columnHeaderMinWidth = gridUtil.getMinColumnWidth(
      parentHeaders.map((parentHeader) => t(ParentRowTextDef.get(parentHeader) as string)),
      MIN_COLUMN_WIDTH_LIMIT,
      MIN_COLUMN_WIDTH_MARGIN
    );

    const hasRowHeader = Boolean(parentRows[0]?.rows[0]?.header);
    const rowHeaders = hasRowHeader
      ? Array.from(
          new Set(
            parentRows
              .flatMap(({ rows }) => rows.map(({ header }) => header))
              .filter(filterUtil.nonNullableFilter)
              .values()
          )
        )
      : [];
    const rowHeaderMinWidth = hasRowHeader
      ? gridUtil.getMinColumnWidth(rowHeaders, MIN_COLUMN_WIDTH_LIMIT, MIN_COLUMN_WIDTH_MARGIN)
      : 0;

    return (
      <>
        {notes && (
          <Box mb={1}>
            {notes.map((note, index) => (
              <Typography key={index}>{note}</Typography>
            ))}
          </Box>
        )}
        {unitCaption && (
          <Box mb={1} display="flex" justifyContent="flex-end">
            <Typography>{unitCaption}</Typography>
          </Box>
        )}
        <Paper square>
          <TableContainer>
            <Table ref={tableRef}>
              {renderHead(columns, hasRowHeader, columnHeaderMinWidth, rowHeaderMinWidth)}
              {renderBody(parentRows, industryData, allIndustryData)}
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  };

  return renderTable(columns, parentRows, unitCaption, notes, industryData, allIndustryData);
};
