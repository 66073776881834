import dayjs from "dayjs";
import { Language } from "../types";

const DISPLAY_FORMAT = "YYYY/MM/DD";
const MUTATE_FORMAT = "YYYY-MM-DD";

const formatDisplayFromMutate = (dateStr: string): string => {
  return dayjs(dateStr).format(DISPLAY_FORMAT);
};

const formatMutate = (date: Date): string => {
  return dayjs(date).format(MUTATE_FORMAT);
};

const formatFromMutate = (dateStr: string): Date => {
  return dayjs(dateStr).toDate();
};

const formatToUserLanguage = (dateStr: string, language: Language): string => {
  return new Intl.DateTimeFormat(language, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(dateStr));
};

export const dateUtil = {
  DISPLAY_FORMAT,
  formatDisplayFromMutate,
  formatMutate,
  formatFromMutate,
  formatToUserLanguage,
};
