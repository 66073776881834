import { useState } from "react";
import { createContainer } from "unstated-next";

export const useTenantStore = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return {
    isSidebarOpen,
    setIsSidebarOpen,
  };
};

export const TenantStore = createContainer(useTenantStore);
