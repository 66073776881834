export const SIDEBAR_WIDTH = 224;
export const SIDEBAR_CLOSED_WIDTH = 53; // 文字がギリギリ隠れる幅
export const SIDEBAR_ICON_MIN_WIDTH = 36;
export const PLACEHOLDER_OPACITY = 0.38;
export const DISABLED_OPACITY = 0.38;
export const COMPANY_BACKGROUND_COLOR = "linear-gradient(45deg,#005BAC,#5EC2C6)";
export const DATA_GRID_NO_ROWS_HEIGHT = 72;
export const DATA_GRID_HEADER_HEIGHT = 39;
export const DATA_GRID_FOOTER_HEIGHT = 39;
export const DATA_GRID_ROW_HEIGHT = 36;
export const DATA_GRID_MORE_MENU_WIDTH = 56;
