import { IndustryDetail, IndustryDivision, IndustryMajorGroup, IndustryGroup } from "../types";

export class IndustryTree {
  static readonly rawIndustryTree = [
    //農業，林業
    {
      code: "A",
      children: [
        //農業
        {
          code: "01",
          children: [],
        },
        //林業
        {
          code: "02",
          children: [
            //管理，補助的経済活動を行う事業所（02林業）
            {
              code: "020",
              children: [
                // 主として管理事務を行う本社等
                "0200",
                // その他の管理，補助的経済活動を行う事業所
                "0209",
              ],
            },
            //育林業
            {
              code: "021",
              children: [
                // 育林業
                "0211",
              ],
            },
            //素材生産業
            {
              code: "022",
              children: [
                // 素材生産業
                "0221",
              ],
            },
            //特用林産物生産業（きのこ類の栽培を除く）
            {
              code: "023",
              children: [
                // 製薪炭業
                "0231",
                // その他の特用林産物生産業（きのこ類の栽培を除く）
                "0239",
              ],
            },
            //林業サービス業
            {
              code: "024",
              children: [
                // 育林サービス業
                "0241",
                // 素材生産サービス業
                "0242",
                // 山林種苗生産サービス業
                "0243",
                // その他の林業サービス業
                "0249",
              ],
            },
            //その他の林業
            {
              code: "029",
              children: [
                // その他の林業
                "0299",
              ],
            },
          ],
        },
      ],
    },
    //漁業
    {
      code: "B",
      children: [
        //漁業（水産養殖業を除く）
        {
          code: "03",
          children: [],
        },
        //水産養殖業
        {
          code: "04",
          children: [],
        },
      ],
    },
    //鉱業，採石業，砂利採取業
    {
      code: "C",
      children: [
        //鉱業，採石業，砂利採取業
        {
          code: "05",
          children: [
            //金属鉱業
            {
              code: "051",
              children: [
                // 金・銀鉱業
                "0511",
                // 鉛・亜鉛鉱業
                "0512",
                // 鉄鉱業
                "0513",
                // その他の金属鉱業
                "0519",
              ],
            },
            //石炭・亜炭鉱業
            {
              code: "052",
              children: [
                // 石炭鉱業（石炭選別業を含む）
                "0521",
                // 亜炭鉱業
                "0522",
              ],
            },
            //採石業，砂・砂利・玉石採取業
            {
              code: "054",
              children: [
                // 花こう岩・同類似岩石採石業
                "0541",
                // 石英粗面岩・同類似岩石採石業
                "0542",
                // 安山岩・同類似岩石採石業
                "0543",
                // 大理石採石業
                "0544",
                // ぎょう灰岩採石業
                "0545",
                // 砂岩採石業
                "0546",
                // 粘板岩採石業
                "0547",
                // 砂・砂利・玉石採取業
                "0548",
                // その他の採石業，砂・砂利・玉石採取業
                "0549",
              ],
            },
            //窯業原料用鉱物鉱業（耐火物・陶磁器・ガラス・セメント原料用に限る）
            {
              code: "055",
              children: [
                // 耐火粘土鉱業
                "0551",
                // ろう石鉱業
                "0552",
                // ドロマイト鉱業
                "0553",
                // 長石鉱業
                "0554",
                // けい石鉱業
                "0555",
                // 天然けい砂鉱業
                "0556",
                // 石灰石鉱業
                "0557",
                // その他の窯業原料用鉱物鉱業
                "0559",
              ],
            },
            //その他の鉱業
            {
              code: "059",
              children: [
                // 酸性白土鉱業
                "0591",
                // ベントナイト鉱業
                "0592",
                // けいそう土鉱業
                "0593",
                // 滑石鉱業
                "0594",
                // 他に分類されない鉱業
                "0599",
              ],
            },
          ],
        },
      ],
    },
    //建設業
    {
      code: "D",
      children: [
        //総合工事業
        {
          code: "06",
          children: [
            //管理，補助的経済活動を行う事業所（06総合工事業）
            {
              code: "060",
              children: [
                // 主として管理事務を行う本社等
                "0600",
                // その他の管理，補助的経済活動を行う事業所
                "0609",
              ],
            },
            //一般土木建築工事業
            {
              code: "061",
              children: [
                // 一般土木建築工事業
                "0611",
              ],
            },
            //土木工事業（舗装工事業を除く）
            {
              code: "062",
              children: [
                // 土木工事業(別掲を除く)
                "0621",
                // 造園工事業
                "0622",
                // しゅんせつ工事業
                "0623",
              ],
            },
            //舗装工事業
            {
              code: "063",
              children: [
                // 舗装工事業
                "0631",
              ],
            },
            //建築工事業(木造建築工事業を除く)
            {
              code: "064",
              children: [
                // 建築工事業(木造建築工事業を除く)
                "0641",
              ],
            },
            //木造建築工事業
            {
              code: "065",
              children: [
                // 木造建築工事業
                "0651",
              ],
            },
            //建築リフォーム工事業
            {
              code: "066",
              children: [
                // 建築リフォーム工事業
                "0661",
              ],
            },
          ],
        },
        //職別工事業(設備工事業を除く)
        {
          code: "07",
          children: [
            //管理，補助的経済活動を行う事業所（07職別工事業）
            {
              code: "070",
              children: [
                // 主として管理事務を行う本社等
                "0700",
                // その他の管理，補助的経済活動を行う事業所
                "0709",
              ],
            },
            //大工工事業
            {
              code: "071",
              children: [
                // 大工工事業(型枠大工工事業を除く)
                "0711",
                // 型枠大工工事業
                "0712",
              ],
            },
            //とび・土工・コンクリート工事業
            {
              code: "072",
              children: [
                // とび工事業
                "0721",
                // 土工・コンクリート工事業
                "0722",
                // 特殊コンクリート工事業
                "0723",
              ],
            },
            //鉄骨・鉄筋工事業
            {
              code: "073",
              children: [
                // 鉄骨工事業
                "0731",
                // 鉄筋工事業
                "0732",
              ],
            },
            //石工・れんが・タイル・ブロック工事業
            {
              code: "074",
              children: [
                // 石工工事業
                "0741",
                // れんが工事業
                "0742",
                // タイル工事業
                "0743",
                // コンクリートブロック工事業
                "0744",
              ],
            },
            //左官工事業
            {
              code: "075",
              children: [
                // 左官工事業
                "0751",
              ],
            },
            //板金・金物工事業
            {
              code: "076",
              children: [
                // 金属製屋根工事業
                "0761",
                // 板金工事業
                "0762",
                // 建築金物工事業
                "0763",
              ],
            },
            //塗装工事業
            {
              code: "077",
              children: [
                // 塗装工事業（道路標示・区画線工事業を除く）
                "0771",
                // 道路標示・区画線工事業
                "0772",
              ],
            },
            //床・内装工事業
            {
              code: "078",
              children: [
                // 床工事業
                "0781",
                // 内装工事業
                "0782",
              ],
            },
            //その他の職別工事業
            {
              code: "079",
              children: [
                // ガラス工事業
                "0791",
                // 金属製建具工事業
                "0792",
                // 木製建具工事業
                "0793",
                // 屋根工事業（金属製屋根工事業を除く）
                "0794",
                // 防水工事業
                "0795",
                // 解体・はつり工事業
                "0796",
                // 他に分類されない職別工事業
                "0799",
              ],
            },
          ],
        },
        //設備工事業
        {
          code: "08",
          children: [
            //管理，補助的経済活動を行う事業所（08設備工事業）
            {
              code: "080",
              children: [
                // 主として管理事務を行う本社等
                "0800",
                // その他の管理，補助的経済活動を行う事業所
                "0809",
              ],
            },
            //電気工事業
            {
              code: "081",
              children: [
                // 一般電気工事業
                "0811",
                // 電気配線工事業
                "0812",
              ],
            },
            //電気通信・信号装置工事業
            {
              code: "082",
              children: [
                // 電気通信工事業（有線テレビジョン放送設備設置工事業を除く）
                "0821",
                // 有線テレビジョン放送設備設置工事業
                "0822",
                // 信号装置工事業
                "0823",
              ],
            },
            //管工事業（さく井工事業を除く）
            {
              code: "083",
              children: [
                // 一般管工事業
                "0831",
                // 冷暖房設備工事業
                "0832",
                // 給排水・衛生設備工事業
                "0833",
                // その他の管工事業
                "0839",
              ],
            },
            //機械器具設置工事業
            {
              code: "084",
              children: [
                // 機械器具設置工事業（昇降設備工事業を除く）
                "0841",
                // 昇降設備工事業
                "0842",
              ],
            },
            //その他の設備工事業
            {
              code: "089",
              children: [
                // 築炉工事業
                "0891",
                // 熱絶縁工事業
                "0892",
                // 道路標識設置工事業
                "0893",
                // さく井工事業
                "0894",
              ],
            },
          ],
        },
      ],
    },
    //製造業
    {
      code: "E",
      children: [
        //食料品製造業
        {
          code: "09",
          children: [],
        },
        //飲料・たばこ・飼料製造業
        {
          code: "10",
          children: [],
        },
        //繊維工業
        {
          code: "11",
          children: [],
        },
        //木材・木製品製造業（家具を除く）
        {
          code: "12",
          children: [],
        },
        //家具・装備品製造業
        {
          code: "13",
          children: [],
        },
        //パルプ・紙・紙加工品製造業
        {
          code: "14",
          children: [],
        },
        //印刷・同関連業
        {
          code: "15",
          children: [],
        },
        //化学工業
        {
          code: "16",
          children: [],
        },
        //石油製品・石炭製品製造業
        {
          code: "17",
          children: [],
        },
        //プラスチック製品製造業（別掲を除く）
        {
          code: "18",
          children: [],
        },
        //ゴム製品製造業
        {
          code: "19",
          children: [],
        },
        //なめし革・同製品・毛皮製造業
        {
          code: "20",
          children: [],
        },
        //窯業・土石製品製造業
        {
          code: "21",
          children: [],
        },
        //鉄鋼業
        {
          code: "22",
          children: [
            //管理，補助的経済活動を行う事業所（22鉄鋼業）
            {
              code: "220",
              children: [
                // 主として管理事務を行う本社等
                "2200",
                // その他の管理，補助的経済活動を行う事業所
                "2209",
              ],
            },
            //製鉄業
            {
              code: "221",
              children: [
                // 高炉による製鉄業
                "2211",
                // 高炉によらない製鉄業
                "2212",
                // フェロアロイ製造業
                "2213",
              ],
            },
            //製鋼・製鋼圧延業
            {
              code: "222",
              children: [
                // 製鋼・製鋼圧延業
                "2221",
              ],
            },
            //製鋼を行わない鋼材製造業（表面処理鋼材を除く）
            {
              code: "223",
              children: [
                // 熱間圧延業（鋼管，伸鉄を除く）
                "2231",
                // 冷間圧延業（鋼管，伸鉄を除く）
                "2232",
                // 冷間ロール成型形鋼製造業
                "2233",
                // 鋼管製造業
                "2234",
                // 伸鉄業
                "2235",
                // 磨棒鋼製造業
                "2236",
                // 引抜鋼管製造業
                "2237",
                // 伸線業
                "2238",
                // その他の製鋼を行わない鋼材製造業（表面処理鋼材を除く)
                "2239",
              ],
            },
            //表面処理鋼材製造業
            {
              code: "224",
              children: [
                // 亜鉛鉄板製造業
                "2241",
                // その他の表面処理鋼材製造業
                "2249",
              ],
            },
            //鉄素形材製造業
            {
              code: "225",
              children: [
                // 銑鉄鋳物製造業（鋳鉄管，可鍛鋳鉄を除く）
                "2251",
                // 可鍛鋳鉄製造業
                "2252",
                // 鋳鋼製造業
                "2253",
                // 鍛工品製造業
                "2254",
                // 鍛鋼製造業
                "2255",
              ],
            },
            //その他の鉄鋼業
            {
              code: "229",
              children: [
                // 鉄鋼シャースリット業
                "2291",
                // 鉄スクラップ加工処理業
                "2292",
                // 鋳鉄管製造業
                "2293",
                // 他に分類されない鉄鋼業
                "2299",
              ],
            },
          ],
        },
        //非鉄金属製造業
        {
          code: "23",
          children: [
            //管理，補助的経済活動を行う事業所（23非鉄金属製造業）
            {
              code: "230",
              children: [
                // 主として管理事務を行う本社等
                "2300",
                // その他の管理，補助的経済活動を行う事業所
                "2309",
              ],
            },
            //非鉄金属第1次製錬・精製業
            {
              code: "231",
              children: [
                // 銅第1次製錬・精製業
                "2311",
                // 亜鉛第1次製錬・精製業
                "2312",
                // その他の非鉄金属第1次製錬・精製業
                "2319",
              ],
            },
            //非鉄金属第2次製錬・精製業（非鉄金属合金製造業を含む）
            {
              code: "232",
              children: [
                // 鉛第2次製錬・精製業（鉛合金製造業を含む)
                "2321",
                // アルミニウム第2次製錬・精製業（アルミニウム合金製造業を含む）
                "2322",
                // その他の非鉄金属第2次製錬・精製業（非鉄金属合金製造業を含む）
                "2329",
              ],
            },
            //非鉄金属・同合金圧延業（抽伸，押出しを含む）
            {
              code: "233",
              children: [
                // 伸銅品製造業
                "2331",
                // アルミニウム・同合金圧延業（抽伸，押出しを含む）
                "2332",
                // その他の非鉄金属・同合金圧延業（抽伸，押出しを含む）
                "2339",
              ],
            },
            //電線・ケーブル製造業
            {
              code: "234",
              children: [
                // 電線・ケーブル製造業（光ファイバケーブルを除く）
                "2341",
                // 光ファイバケーブル製造業（通信複合ケーブルを含む）
                "2342",
              ],
            },
            //非鉄金属素形材製造業
            {
              code: "235",
              children: [
                // 銅・同合金鋳物製造業（ダイカストを除く）
                "2351",
                // 非鉄金属鋳物製造業（銅・同合金鋳物及びダイカストを除く）
                "2352",
                // アルミニウム・同合金ダイカスト製造業
                "2353",
                // 非鉄金属ダイカスト製造業（アルミニウム・同合金ダイカストを除く）
                "2354",
                // 非鉄金属鍛造品製造業
                "2355",
              ],
            },
            //その他の非鉄金属製造業
            {
              code: "239",
              children: [
                // 核燃料製造業
                "2391",
                // 他に分類されない非鉄金属製造業
                "2399",
              ],
            },
          ],
        },
        //金属製品製造業
        {
          code: "24",
          children: [],
        },
        //はん用機械器具製造業
        {
          code: "25",
          children: [],
        },
        //生産用機械器具製造業
        {
          code: "26",
          children: [],
        },
        //業務用機械器具製造業
        {
          code: "27",
          children: [],
        },
        //電子部品・デバイス・電子回路製造業
        {
          code: "28",
          children: [],
        },
        //電気機械器具製造業
        {
          code: "29",
          children: [],
        },
        //情報通信機械器具製造業
        {
          code: "30",
          children: [],
        },
        //輸送用機械器具製造業
        {
          code: "31",
          children: [
            //船舶製造・修理業，舶用機関製造業
            {
              code: "313",
              children: [
                // 船舶製造・修理業
                "3131",
                // 船体ブロック製造業
                "3132",
                // 舟艇製造・修理業
                "3133",
                // 舶用機関製造業
                "3134",
              ],
            },
          ],
        },
        //その他の製造業
        {
          code: "32",
          children: [],
        },
      ],
    },
    //電気・ガス・熱供給・水道業
    {
      code: "F",
      children: [
        //電気業
        {
          code: "33",
          children: [],
        },
        //ガス業
        {
          code: "34",
          children: [],
        },
        //熱供給業
        {
          code: "35",
          children: [],
        },
        //水道業
        {
          code: "36",
          children: [],
        },
      ],
    },
    //情報通信業
    {
      code: "G",
      children: [
        //通信業
        {
          code: "37",
          children: [],
        },
        //放送業
        {
          code: "38",
          children: [],
        },
        //情報サービス業
        {
          code: "39",
          children: [],
        },
        //インターネット附随サービス業
        {
          code: "40",
          children: [],
        },
        //映像・音声・文字情報制作業
        {
          code: "41",
          children: [],
        },
      ],
    },
    //運輸業，郵便業
    {
      code: "H",
      children: [
        //鉄道業
        {
          code: "42",
          children: [
            //管理，補助的経済活動を行う事業所（42鉄道業）
            {
              code: "420",
              children: [
                // 主として管理事務を行う本社等
                "4200",
                // その他の管理，補助的経済活動を行う事業所
                "4209",
              ],
            },
            //鉄道業
            {
              code: "421",
              children: [
                // 普通鉄道業
                "4211",
                // 軌道業
                "4212",
                // 地下鉄道業
                "4213",
                // モノレール鉄道業（地下鉄道業を除く）
                "4214",
                // 案内軌条式鉄道業（地下鉄道業を除く）
                "4215",
                // 鋼索鉄道業
                "4216",
                // 索道業
                "4217",
                // その他の鉄道業
                "4219",
              ],
            },
          ],
        },
        //道路旅客運送業
        {
          code: "43",
          children: [
            //管理，補助的経済活動を行う事業所（43道路旅客運送業）
            {
              code: "430",
              children: [
                // 主として管理事務を行う本社等
                "4300",
                // その他の管理，補助的経済活動を行う事業所
                "4309",
              ],
            },
            //一般乗合旅客自動車運送業
            {
              code: "431",
              children: [
                // 一般乗合旅客自動車運送業
                "4311",
              ],
            },
            //一般乗用旅客自動車運送業
            {
              code: "432",
              children: [
                // 一般乗用旅客自動車運送業
                "4321",
              ],
            },
            //一般貸切旅客自動車運送業
            {
              code: "433",
              children: [
                // 一般貸切旅客自動車運送業
                "4331",
              ],
            },
            //その他の道路旅客運送業
            {
              code: "439",
              children: [
                // 特定旅客自動車運送業
                "4391",
                // 他に分類されない道路旅客運送業
                "4399",
              ],
            },
          ],
        },
        //道路貨物運送業
        {
          code: "44",
          children: [
            //管理，補助的経済活動を行う事業所（44道路貨物運送業）
            {
              code: "440",
              children: [
                // 主として管理事務を行う本社等
                "4400",
                // その他の管理，補助的経済活動を行う事業所
                "4409",
              ],
            },
            //一般貨物自動車運送業
            {
              code: "441",
              children: [
                // 一般貨物自動車運送業（特別積合せ貨物運送業を除く）
                "4411",
                // 特別積合せ貨物運送業
                "4412",
              ],
            },
            //特定貨物自動車運送業
            {
              code: "442",
              children: [
                // 特定貨物自動車運送業
                "4421",
              ],
            },
            //貨物軽自動車運送業
            {
              code: "443",
              children: [
                // 貨物軽自動車運送業
                "4431",
              ],
            },
            //集配利用運送業
            {
              code: "444",
              children: [
                // 集配利用運送業
                "4441",
              ],
            },
            //その他の道路貨物運送業
            {
              code: "449",
              children: [
                // その他の道路貨物運送業
                "4499",
              ],
            },
          ],
        },
        //水運業
        {
          code: "45",
          children: [
            //管理，補助的経済活動を行う事業所（45水運業）
            {
              code: "450",
              children: [
                // 主として管理事務を行う本社等
                "4500",
                // その他の管理，補助的経済活動を行う事業所
                "4509",
              ],
            },
            //外航海運業
            {
              code: "451",
              children: [
                // 外航旅客海運業
                "4511",
                // 外航貨物海運業
                "4512",
              ],
            },
            //沿海海運業
            {
              code: "452",
              children: [
                // 沿海旅客海運業
                "4521",
                // 沿海貨物海運業
                "4522",
              ],
            },
            //内陸水運業
            {
              code: "453",
              children: [
                // 港湾旅客海運業
                "4531",
                // 河川水運業
                "4532",
                // 湖沼水運業
                "4533",
              ],
            },
            //船舶貸渡業
            {
              code: "454",
              children: [
                // 船舶貸渡業（内航船舶貸渡業を除く）
                "4541",
                // 内航船舶貸渡業
                "4542",
              ],
            },
          ],
        },
        //航空運輸業
        {
          code: "46",
          children: [
            //管理，補助的経済活動を行う事業所（46航空運輸業）
            {
              code: "460",
              children: [
                // 主として管理事務を行う本社等
                "4600",
                // その他の管理，補助的経済活動を行う事業所
                "4609",
              ],
            },
            //航空運送業
            {
              code: "461",
              children: [
                // 航空運送業
                "4611",
              ],
            },
            //航空機使用業（航空運送業を除く）
            {
              code: "462",
              children: [
                // 航空機使用業（航空運送業を除く）
                "4621",
              ],
            },
          ],
        },
        //倉庫業
        {
          code: "47",
          children: [
            //管理，補助的経済活動を行う事業所（47倉庫業）
            {
              code: "470",
              children: [
                // 主として管理事務を行う本社等
                "4700",
                // その他の管理，補助的経済活動を行う事業所
                "4709",
              ],
            },
            //倉庫業（冷蔵倉庫業を除く）
            {
              code: "471",
              children: [
                // 倉庫業（冷蔵倉庫業を除く）
                "4711",
              ],
            },
            //冷蔵倉庫業
            {
              code: "472",
              children: [
                // 冷蔵倉庫業
                "4721",
              ],
            },
          ],
        },
        //運輸に附帯するサービス業
        {
          code: "48",
          children: [
            //港湾運送業
            {
              code: "481",
              children: [
                // 港湾運送業
                "4811",
              ],
            },
            //貨物運送取扱業（集配利用運送業を除く）
            {
              code: "482",
              children: [
                // 利用運送業（集配利用運送業を除く）
                "4821",
                // 運送取次業
                "4822",
              ],
            },
          ],
        },
        //郵便業（信書便事業を含む）
        {
          code: "49",
          children: [
            //管理，補助的経済活動を行う事業所（49郵便業）
            {
              code: "490",
              children: [
                // 管理，補助的経済活動を行う事業所
                "4901",
              ],
            },
            //郵便業（信書便事業を含む）
            {
              code: "491",
              children: [
                // 郵便業（信書便事業を含む）
                "4911",
              ],
            },
          ],
        },
      ],
    },
    //卸売業，小売業
    {
      code: "I",
      children: [
        //各種商品卸売業
        {
          code: "50",
          children: [],
        },
        //繊維・衣服等卸売業
        {
          code: "51",
          children: [],
        },
        //飲食料品卸売業
        {
          code: "52",
          children: [],
        },
        //建築材料，鉱物・金属材料等卸売業
        {
          code: "53",
          children: [],
        },
        //機械器具卸売業
        {
          code: "54",
          children: [],
        },
        //その他の卸売業
        {
          code: "55",
          children: [],
        },
        //各種商品小売業
        {
          code: "56",
          children: [],
        },
        //織物・衣服・身の回り品小売業
        {
          code: "57",
          children: [],
        },
        //飲食料品小売業
        {
          code: "58",
          children: [],
        },
        //機械器具小売業
        {
          code: "59",
          children: [],
        },
        //その他の小売業
        {
          code: "60",
          children: [],
        },
        //無店舗小売業
        {
          code: "61",
          children: [],
        },
      ],
    },
    //金融業，保険業
    {
      code: "J",
      children: [
        //銀行業
        {
          code: "62",
          children: [],
        },
        //協同組織金融業
        {
          code: "63",
          children: [],
        },
        //貸金業，クレジットカード業等非預金信用機関
        {
          code: "64",
          children: [],
        },
        //金融商品取引業，商品先物取引業
        {
          code: "65",
          children: [],
        },
        //補助的金融業等
        {
          code: "66",
          children: [],
        },
        //保険業（保険媒介代理業，保険サービス業を含む）
        {
          code: "67",
          children: [],
        },
      ],
    },
    //不動産業，物品賃貸業
    {
      code: "K",
      children: [
        //不動産取引業
        {
          code: "68",
          children: [],
        },
        //不動産賃貸業・管理業
        {
          code: "69",
          children: [],
        },
        //物品賃貸業
        {
          code: "70",
          children: [],
        },
      ],
    },
    //学術研究，専門・技術サービス業
    {
      code: "L",
      children: [
        //学術・開発研究機関
        {
          code: "71",
          children: [],
        },
        //専門サービス業（他に分類されないもの）
        {
          code: "72",
          children: [],
        },
        //広告業
        {
          code: "73",
          children: [],
        },
        //技術サービス業（他に分類されないもの）
        {
          code: "74",
          children: [],
        },
      ],
    },
    //宿泊業，飲食サービス業
    {
      code: "M",
      children: [
        //宿泊業
        {
          code: "75",
          children: [],
        },
        //飲食店
        {
          code: "76",
          children: [],
        },
        //持ち帰り・配達飲食サービス業
        {
          code: "77",
          children: [],
        },
      ],
    },
    //生活関連サービス業，娯楽業
    {
      code: "N",
      children: [
        //洗濯・理容・美容・浴場業
        {
          code: "78",
          children: [],
        },
        //その他の生活関連サービス業
        {
          code: "79",
          children: [],
        },
        //娯楽業
        {
          code: "80",
          children: [],
        },
      ],
    },
    //教育，学習支援業
    {
      code: "O",
      children: [
        //学校教育
        {
          code: "81",
          children: [
            //幼稚園
            {
              code: "811",
              children: [
                // 幼稚園
                "8111",
              ],
            },
            //小学校
            {
              code: "812",
              children: [
                // 小学校
                "8121",
              ],
            },
            //特別支援学校
            {
              code: "815",
              children: [
                // 特別支援学校
                "8151",
              ],
            },
            //高等教育機関
            {
              code: "816",
              children: [
                // 大学
                "8161",
                // 短期大学
                "8162",
                // 高等専門学校
                "8163",
              ],
            },
            //幼保連携型認定こども園
            {
              code: "819",
              children: [
                // 幼保連携型認定こども園
                "8191",
              ],
            },
          ],
        },
        //その他の教育，学習支援業
        {
          code: "82",
          children: [],
        },
      ],
    },
    //医療，福祉
    {
      code: "P",
      children: [
        //医療業
        {
          code: "83",
          children: [
            //管理，補助的経済活動を行う事業所（83医療業）
            {
              code: "830",
              children: [
                // 主として管理事務を行う本社等
                "8300",
                // その他の管理，補助的経済活動を行う事業所
                "8309",
              ],
            },
            //病院
            {
              code: "831",
              children: [
                // 一般病院
                "8311",
                // 精神科病院
                "8312",
              ],
            },
            //一般診療所
            {
              code: "832",
              children: [
                // 有床診療所
                "8321",
                // 無床診療所
                "8322",
              ],
            },
            //歯科診療所
            {
              code: "833",
              children: [
                // 歯科診療所
                "8331",
              ],
            },
            //助産・看護業
            {
              code: "834",
              children: [
                // 助産所
                "8341",
                // 看護業
                "8342",
              ],
            },
            //施術業
            {
              code: "835",
              children: [
                // あん摩マッサージ指圧師・はり師・きゅう師・柔道整復師の施術所
                "8351",
                // 療術業
                "8352",
              ],
            },
            //医療に附帯するサービス業
            {
              code: "836",
              children: [
                // 歯科技工所
                "8361",
                // その他の医療に附帯するサービス業
                "8369",
              ],
            },
          ],
        },
        //保健衛生
        {
          code: "84",
          children: [],
        },
        //社会保険・社会福祉・介護事業
        {
          code: "85",
          children: [
            //児童福祉事業
            {
              code: "853",
              children: [
                // 保育所
                "8531",
                // その他の児童福祉事業
                "8539",
              ],
            },
            //老人福祉・介護事業
            {
              code: "854",
              children: [
                // 介護老人保健施設
                "8542",
                // 介護医療院
                "8543",
              ],
            },
          ],
        },
      ],
    },
    //複合サービス事業
    {
      code: "Q",
      children: [
        //郵便局
        {
          code: "86",
          children: [],
        },
        //協同組合（他に分類されないもの）
        {
          code: "87",
          children: [],
        },
      ],
    },
    //サービス業（他に分類されないもの）
    {
      code: "R",
      children: [
        //廃棄物処理業
        {
          code: "88",
          children: [],
        },
        //自動車整備業
        {
          code: "89",
          children: [],
        },
        //機械等修理業（別掲を除く）
        {
          code: "90",
          children: [],
        },
        //職業紹介・労働者派遣業
        {
          code: "91",
          children: [],
        },
        //その他の事業サービス業
        {
          code: "92",
          children: [
            //警備業
            {
              code: "923",
              children: [
                // 警備業
                "9231",
              ],
            },
          ],
        },
        //政治・経済・文化団体
        {
          code: "93",
          children: [],
        },
        //宗教
        {
          code: "94",
          children: [],
        },
        //その他のサービス業
        {
          code: "95",
          children: [],
        },
        //外国公務
        {
          code: "96",
          children: [],
        },
      ],
    },
    //公務（他に分類されるものを除く）
    {
      code: "S",
      children: [
        //国家公務
        {
          code: "97",
          children: [],
        },
        //地方公務
        {
          code: "98",
          children: [],
        },
      ],
    },
    //分類不能の産業
    {
      code: "T",
      children: [
        //分類不能の産業
        {
          code: "99",
          children: [],
        },
      ],
    },
  ] as const;

  static getDivision(code: IndustryDivision): _IndustryDivision | null {
    const rawIndustryDivision = this.rawIndustryTree.find((division) => division["code"] == code);
    if (!rawIndustryDivision) {
      return null;
    }
    return new _IndustryDivision(rawIndustryDivision);
  }
}
export type RawIndustryTree = typeof IndustryTree.rawIndustryTree;
export type RawIndustryDivision = RawIndustryTree[number];
export type RawIndustryMajorGroup = RawIndustryDivision["children"][number];
export type RawIndustryGroup = RawIndustryMajorGroup["children"][number];
export type RawIndustryDetail = RawIndustryGroup["children"][number];

class _IndustryDivision {
  constructor(private readonly rawIndustryDivision: RawIndustryDivision) {}
  getMajorGroups(): _IndustryMajorGroup[] {
    return this.rawIndustryDivision["children"].map((majorGroup) => new _IndustryMajorGroup(majorGroup));
  }

  getMajorGroup(code: IndustryMajorGroup): _IndustryMajorGroup | null {
    const rawIndustryMajorGroup = this.rawIndustryDivision["children"].find((majorGroup) => majorGroup["code"] == code);
    if (!rawIndustryMajorGroup) {
      return null;
    }
    return new _IndustryMajorGroup(rawIndustryMajorGroup);
  }

  get code(): IndustryDivision {
    return this.rawIndustryDivision["code"];
  }
}

class _IndustryMajorGroup {
  constructor(private readonly rawIndustryMajorGroup: RawIndustryMajorGroup) {}
  getGroups(): _IndustryGroup[] {
    return this.rawIndustryMajorGroup["children"].map((group) => new _IndustryGroup(group));
  }

  getGroup(code: IndustryGroup): _IndustryGroup | null {
    const rawIndustryGroup = this.rawIndustryMajorGroup["children"].find((group) => group["code"] == code);
    if (!rawIndustryGroup) {
      return null;
    }
    return new _IndustryGroup(rawIndustryGroup);
  }

  get code(): IndustryMajorGroup {
    return this.rawIndustryMajorGroup["code"];
  }
}

class _IndustryGroup {
  constructor(private readonly rawIndustryGroup: RawIndustryGroup) {}
  getDetails(): _IndustryDetail[] {
    return this.rawIndustryGroup["children"].map((detail) => new _IndustryDetail(detail));
  }

  getDetail(code: IndustryDetail): _IndustryDetail | null {
    const rawIndustryDetail = this.rawIndustryGroup["children"].find((detail) => detail == code);
    if (!rawIndustryDetail) {
      return null;
    }
    return new _IndustryDetail(rawIndustryDetail);
  }
  get code(): IndustryGroup {
    return this.rawIndustryGroup["code"];
  }
}

class _IndustryDetail {
  constructor(private readonly rawIndustryDetail: RawIndustryDetail) {}
  get code(): IndustryDetail {
    return this.rawIndustryDetail;
  }
}
