import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";
import { axiosInstance } from "../../lib/axios";
import { queryClient } from "../../lib/react-query";
import { TenantRole } from "../../types";
import { TenantMemberVo } from "../../types/vo";
import { handleClientError } from "../../utils/handle-client-error";

export type MemberQueryResult = TenantMemberVo;

export type MemberCreateRequest = {
  email: string;
  role: TenantRole;
};

export type MemberUpdateRequest = {
  userId: string;
  role: TenantRole;
};

// axios

const getMembers = async (): Promise<MemberQueryResult[]> => {
  return axiosInstance.get(`/members`);
};

const createMember = async (data: MemberCreateRequest) => {
  return axiosInstance.post("/members", data);
};

const updateMember = async (data: MemberUpdateRequest) => {
  return axiosInstance.put("/members", data);
};

const deleteMember = async (userId: string) => {
  return axiosInstance.delete("/members", { params: { userId } });
};

// react-query

const useMembers = () => {
  return useQuery({ queryKey: ["members"], queryFn: getMembers });
};

const useCreateMember = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: createMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateMember = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: updateMember,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useDeleteMember = (t: TFunction, enqueSnackbar: EnqueueSnackbar, userId: string) => {
  return useMutation({
    mutationFn: () => deleteMember(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      enqueSnackbar(t("snackbar.deleted"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

export const memberApi = {
  useMembers,
  useCreateMember,
  useUpdateMember,
  useDeleteMember,
};
