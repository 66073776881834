import styled from "@emotion/styled";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { COMPANYLogo } from "../../../../components/icon/company-logo";
import { neonTheme } from "../../../../config/neon-theme";
import { COMPANY_BACKGROUND_COLOR } from "../../../../config/style";
import { CustomAuthenticator } from "../components/custom-authenticator";

export const Login = () => {
  const { t } = useTranslation();
  const FullScreenContainer = styled.div({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: COMPANY_BACKGROUND_COLOR,
    zIndex: -1,
  });

  const ProductName = styled.div({
    textAlign: "center",
    fontSize: 32,
    fontWeight: 400,
    marginBottom: 0,
    color: neonTheme.palette.common.white,
  });
  return (
    <>
      <FullScreenContainer />
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Grid item>
          <Grid item>
            <COMPANYLogo />
          </Grid>
          <Grid item>
            <ProductName>{t("service")}</ProductName>
          </Grid>
          <Grid>
            <CustomAuthenticator />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
