import { Amplify } from "aws-amplify";
import { COGNITO_AWS_REGION, COGNITO_NATIVE_CLIENT_ID, COGNITO_USER_POOL_ID } from "../config";

export const setupAmplify = () => {
  Amplify.configure({
    Auth: {
      region: COGNITO_AWS_REGION,
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolWebClientId: COGNITO_NATIVE_CLIENT_ID,
    },
  });
};

export const isAuthenticated = (status: string) => {
  return status === "authenticated";
};
