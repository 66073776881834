import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";
import { CloseSnackbarButton } from "../../components/close-snackbar-button";
import { axiosInstance } from "../../lib/axios";
import { queryClient } from "../../lib/react-query";
import { CompanyVo, WorkspaceVo } from "../../types/vo";
import { handleClientError } from "../../utils/handle-client-error";
import { snackbarUtil } from "../../utils/snackbar";

export type WorkspaceQueryResult = WorkspaceVo;

export type CompanyQueryResult = CompanyVo;

export type WorkspaceCreateRequest = {
  name: string;
  slug: string;
  companyIds: string[];
};

export type WorkspaceUpdateRequest = {
  id: string;
  name: string;
  slug: string;
  companyIds: string[];
};

// axios

const getWorkspaces = async (): Promise<WorkspaceQueryResult[]> => {
  return axiosInstance.get(`/workspaces`);
};

const getCompanies = async (): Promise<CompanyQueryResult[]> => {
  return axiosInstance.get(`/companies`);
};

const getWorkspaceCompanies = async (id: string): Promise<CompanyQueryResult[]> => {
  return axiosInstance.get(`/workspaces/companies`, { params: { id } });
};

const createWorkspace = async (data: WorkspaceCreateRequest) => {
  return axiosInstance.post(`/workspaces`, data);
};

const updateWorkspace = async (data: WorkspaceUpdateRequest) => {
  return axiosInstance.put(`/workspaces`, data);
};

const deleteWorkspace = async (id: string): Promise<boolean> => {
  return axiosInstance.delete(`/workspaces/${id}`);
};

// react-query

const useWorkspaces = () => {
  return useQuery({ queryKey: ["workspaces"], queryFn: getWorkspaces });
};

const useCompanies = () => {
  // @workspaceを指定しているのは、本来companyApi側で使われるクエリなので、キャッシュが競合しないようにしている
  return useQuery({ queryKey: ["@workspace", "companies"], queryFn: getCompanies });
};

const useWorkspaceCompanies = (id: string) => {
  return useQuery({ queryKey: ["workspaces/companies", id], queryFn: () => getWorkspaceCompanies(id) });
};

const useCreateWorkspace = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: createWorkspace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateWorkspace = (t: TFunction, enqueSnackbar: EnqueueSnackbar, id: string) => {
  return useMutation({
    mutationFn: updateWorkspace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["workspaces/companies", id] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useDeleteWorkspace = (t: TFunction, enqueSnackbar: EnqueueSnackbar, startDate: string) => {
  return useMutation({
    mutationFn: () => deleteWorkspace(startDate),
    onSuccess: (data: boolean) => {
      if (data) {
        queryClient.invalidateQueries({ queryKey: ["workspaces"] });
        enqueSnackbar(t("snackbar.deleted"), { variant: "success" });
      } else {
        const message = t("snackbar.workspace.not-deletable");
        enqueSnackbar(message, {
          autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_LONG,
          action: CloseSnackbarButton,
          style: { width: snackbarUtil.getCssWidthInPixel(message) },
        });
      }
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

export const workspaceApi = {
  useWorkspaces,
  useCompanies,
  useWorkspaceCompanies,
  useCreateWorkspace,
  useUpdateWorkspace,
  useDeleteWorkspace,
};
