import { Authenticator } from "@aws-amplify/ui-react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/error-fallback";
import { Loading } from "../components/loading/loading";
import "../config/i18n";
import { neonTheme } from "../config/neon-theme";
import { setupAmplify } from "../lib/amplify";
import { queryClient } from "../lib/react-query";
import { AuthStore } from "../stores/auth-store";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  setupAmplify();
  return (
    <React.Suspense fallback={<Loading />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ThemeProvider theme={neonTheme}>
          <CssBaseline />
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Authenticator.Provider>
                <AuthStore.Provider>{children}</AuthStore.Provider>
              </Authenticator.Provider>
            </SnackbarProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
