import InfoIcon from "@mui/icons-material/Info";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { SideDialog } from "../../../components/side-dialog";
import { ChartTextDef } from "../../../config/text-def";
import { DashboardExportJob } from "../components/dashboard-export-job";
import { DashboardBoardQueryResult, dashboardApi } from "../dashboard-api";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey[100],
  },
  "td, th": {
    border: "none",
  },
}));

export const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [hoveredBoardId, setHoveredBoardId] = useState<string>();
  const [sideDialogBoard, setSideDialogBoard] = useState<DashboardBoardQueryResult>();
  const [sideDialogOpen, setSideDialogOpen] = useState(false);

  const onClickBoard = (id: string) => {
    navigate(`/boards/${id}`);
  };

  const onClickInfoIcon = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, board: DashboardBoardQueryResult) => {
    e.stopPropagation();
    setSideDialogBoard(board);
    setSideDialogOpen(true);
  };

  const businessYearsQuery = dashboardApi.useBusinessYears();
  const boardsQuery = dashboardApi.useBoards();

  if (businessYearsQuery.isLoading || boardsQuery.isLoading)
    return (
      <TenantLayout activeKey="dashboard">
        <Grid container>
          <Grid item xs={12}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!businessYearsQuery.data) return null;
  if (!boardsQuery.data) return null;

  const businessYearExists = Boolean(businessYearsQuery.data?.length);

  const renderBoard = (board: DashboardBoardQueryResult) => (
    <Paper>
      <ListItemButton
        onClick={() => onClickBoard(board.id)}
        onMouseEnter={() => setHoveredBoardId(board.id)}
        onMouseLeave={() => setHoveredBoardId(undefined)}
      >
        <ListItemText
          // TODO: 文字列がはみ出たら「...」表示にしているが、ホバー時に全文表示するようにする（現状はみ出るチャート名はない）
          primary={
            <Box display="flex" alignItems="center">
              <Box mr={1}>{board.name}</Box>
              {board.id === hoveredBoardId && (
                <InfoIcon
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                    "&:hover": {
                      color: (theme) => theme.palette.grey[700],
                    },
                  }}
                  onClick={(e) => onClickInfoIcon(e, board)}
                />
              )}
            </Box>
          }
          primaryTypographyProps={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        />
        {/* 将来以下のようにアイコンを追加
          <Box display="flex" mr={-2}>
            <IconButton>
              <StarBorderIcon />
            </IconButton>
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          </Box> */}
      </ListItemButton>
    </Paper>
  );

  const renderSideDialog = () => (
    <SideDialog open={sideDialogOpen} onClose={() => setSideDialogOpen(false)} title={t("dashboard.board.detail")}>
      <Grid container px={2} py={1} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">{t("name")}</Typography>
          <Typography>{sideDialogBoard?.name}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="caption">{t("indicator")}</Typography>
          <Box mt={0.5}>
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table size="small">
                <TableBody>
                  {sideDialogBoard?.charts.map((chart) => (
                    <StyledTableRow key={chart}>
                      <TableCell padding="none" sx={{ px: 1, py: 0.5 }}>
                        <Typography variant="caption">{t(ChartTextDef.get(chart) as string)}</Typography>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </SideDialog>
  );

  return (
    <TenantLayout activeKey="dashboard" sideDialog={renderSideDialog()}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h6">{t("dashboard")}</Typography>
        </Grid>
        {!businessYearExists ? (
          <Grid item xs={12}>
            <Alert severity="warning">
              <Trans i18nKey="alert.dashboard.warning" components={{ l: <Link to="/business-years" /> }} />
            </Alert>
          </Grid>
        ) : (
          <Grid container item>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid container item spacing={1}>
                    {boardsQuery.data.map((board) => (
                      <Grid
                        item
                        xs={12}
                        sm={sideDialogOpen ? 12 : 8}
                        md={sideDialogOpen ? 8 : 4}
                        lg={sideDialogOpen ? 4 : 3}
                        key={board.id}
                      >
                        {renderBoard(board)}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid container item>
                    <Typography variant="body1" color={(theme) => theme.palette.grey[600]}>
                      {t("dashboard.export.history")}
                    </Typography>
                  </Grid>
                  <Grid container item>
                    <DashboardExportJob />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </TenantLayout>
  );
};
