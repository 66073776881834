import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { MemberQueryResult, memberApi } from "../member-api";
import { MemberBaseForm, MemberFormModel } from "./member-base-form";

type MemberEditFormProps = {
  target: MemberQueryResult;
  open: boolean;
  onClose: () => void;
};

export const MemberEditForm = ({ target, open, onClose }: MemberEditFormProps) => {
  const { userId, email, role } = target;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const updateMemberMutation = memberApi.useUpdateMember(t, enqueueSnackbar);

  const onSubmit = ({ role }: MemberFormModel) => {
    updateMemberMutation.mutate({ userId, role });
  };

  return (
    <MemberBaseForm
      edit
      title={t("member.edit")}
      buttonLabel={t("save")}
      defaultValue={{ email, role }}
      open={open}
      loading={updateMemberMutation.isLoading}
      done={updateMemberMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
