export let API_BASE_URL: string;
export let COGNITO_AWS_REGION: string;
export let COGNITO_USER_POOL_ID: string;
export let COGNITO_NATIVE_CLIENT_ID: string;

import axios from "axios";

const axiosRawInstance = axios.create({
  baseURL: process.env.PUBLIC_URL,
});

type Config = {
  apiBaseUrl: string;
  awsRegion: string;
  userPoolId: string;
  userPoolWebClientId: string;
};

export const setupConfig = async () => {
  const response = await axiosRawInstance.get<Config>("/config.json");
  setConfig(response.data);
};

const setConfig = (config: Config) => {
  API_BASE_URL = config.apiBaseUrl;
  COGNITO_AWS_REGION = config.awsRegion;
  COGNITO_USER_POOL_ID = config.userPoolId;
  COGNITO_NATIVE_CLIENT_ID = config.userPoolWebClientId;
};
