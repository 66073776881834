import Box from "@mui/material/Box";
import React from "react";
import { DashboardChartHeader } from "./dashboard-chart-header";

type DashboardChartLayoutProps = {
  children: React.ReactNode;
};

export const DashboardChartLayout = ({ children }: DashboardChartLayoutProps) => {
  return (
    <Box>
      <DashboardChartHeader />
      {children}
    </Box>
  );
};
