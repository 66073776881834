import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, GENDER_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, GenderTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption, GenderType } from "../../../types";
import { BaseStackedColumnChart } from "../base-stacked-column-chart";

export type BaseGenderTypeStackedColumnData = {
  genderType: GenderType;
} & BaseData;

export type BaseGenderTypeStackedColumnChartSeriesCode = `${AggregateType}_${GenderType}`;

export class BaseGenderTypeStackedColumnChart extends BaseStackedColumnChart<BaseGenderTypeStackedColumnData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseGenderTypeStackedColumnData>
      | ChartQueryResult<BaseGenderTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
    };
  }

  protected _getSeriesCode(datum: BaseGenderTypeStackedColumnData): BaseGenderTypeStackedColumnChartSeriesCode {
    return `${datum.aggregateType}_${datum.genderType}`;
  }

  protected _createSeriesDefs(t: TFunction): Map<BaseGenderTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption> {
    const columnMap = new Map<BaseGenderTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption>();

    AGGREGATE_TYPES.forEach((aggregateType) => {
      GENDER_TYPES.filter((type) => type !== "all")
        .toReversed()
        .forEach((genderType, i, array) => {
          const code: BaseGenderTypeStackedColumnChartSeriesCode = `${aggregateType}_${genderType}`;
          columnMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              GenderTypeTextDef.get(genderType) as string
            )}`,
            color: super.getColor(aggregateType, array.length, i),
          });
        });
    });
    return columnMap;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseGenderTypeStackedColumnData>
      | ChartQueryResult<BaseGenderTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: GENDER_TYPES.map((genderType) => {
        return {
          header: t(GenderTypeTextDef.get(genderType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.genderType === genderType
          ),
        };
      }),
    }));
  }
}
