import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES, EMPLOYEE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, EmployeeTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, AggregateTypeRow, BaseData, ChartSeriesColumnOption, EmployeeType } from "../../../types";
import { BaseStackedColumnChart } from "../base-stacked-column-chart";

export type BaseEmployeeTypeStackedColumnData = {
  employeeType: EmployeeType;
} & BaseData;

export type BaseEmployeeTypeStackedColumnChartSeriesCode = `${AggregateType}_${EmployeeType}`;

export class BaseEmployeeTypeStackedColumnChart extends BaseStackedColumnChart<BaseEmployeeTypeStackedColumnData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseEmployeeTypeStackedColumnData>
      | ChartQueryResult<BaseEmployeeTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return {
      ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
      series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
    };
  }

  protected _getSeriesCode(datum: BaseEmployeeTypeStackedColumnData): BaseEmployeeTypeStackedColumnChartSeriesCode {
    return `${datum.aggregateType}_${datum.employeeType}`;
  }

  protected _createSeriesDefs(
    t: TFunction
  ): Map<BaseEmployeeTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption> {
    const columnMap = new Map<BaseEmployeeTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption>();

    AGGREGATE_TYPES.forEach((aggregateType) => {
      EMPLOYEE_TYPES.filter((type) => type !== "all")
        .toReversed()
        .forEach((employeeType, i, array) => {
          const code: BaseEmployeeTypeStackedColumnChartSeriesCode = `${aggregateType}_${employeeType}`;
          columnMap.set(code, {
            name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
              EmployeeTypeTextDef.get(employeeType) as string
            )}`,
            color: super.getColor(aggregateType, array.length, i),
          });
        });
    });
    return columnMap;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseEmployeeTypeStackedColumnData>
      | ChartQueryResult<BaseEmployeeTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
      aggregateType,
      rows: EMPLOYEE_TYPES.map((employeeType) => {
        return {
          header: t(EmployeeTypeTextDef.get(employeeType) as string) as string,
          unit: queryResult.unit,
          values: super.getValuesByCondition(
            queryResult,
            (datum) => datum.aggregateType === aggregateType && datum.employeeType === employeeType
          ),
        };
      }),
    }));
  }
}
