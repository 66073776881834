import { BusinessYearVo } from "../../../types/vo";

const getFromBusinessYearStartDate = (businessYears: BusinessYearVo[]) => {
  const max = 5;
  if (businessYears.length > max) {
    return businessYears[max - 1].startDate;
  }
  return businessYears[businessYears.length - 1].startDate;
};

export const filters = {
  getFromBusinessYearStartDate,
};
