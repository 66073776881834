import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../../components/dashboard-chart-display-switch";
import { ChartQueryResult } from "../../../../dashboard-api";
import { converters } from "../../../../functions/converters";
import { AggregateTypeRow } from "../../../../types";
import { BaseGradeTypeLineChart, BaseGradeTypeLineData } from "../base-grade-type-line-chart";

export class AverageAnnualBonusByGradeTypeChart extends BaseGradeTypeLineChart {
  getChartOptions(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGradeTypeLineData> | ChartQueryResult<BaseGradeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    const newQueryResult = converters.toThousandYen<BaseGradeTypeLineData>(queryResult);
    return super.getChartOptions(t, newQueryResult, displaySwitch, inBoard);
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult: ChartQueryResult<BaseGradeTypeLineData> | ChartQueryResult<BaseGradeTypeLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    const newQueryResult = converters.toThousandYen<BaseGradeTypeLineData>(queryResult);
    return super.getAggregateTypeRows(t, newQueryResult, displaySwitch);
  }
}
