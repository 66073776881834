import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "../../stores/auth-store";
import { IconTextButton } from "../icon-text-button";
import { HEADER_HEIGHT } from "./global-header";
import { TENANT_SIDEBAR_ITEMS } from "./tenant-sidebar";

export const GLOBAL_SIDEBAR_WIDTH = 64;

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: GLOBAL_SIDEBAR_WIDTH,
  minWidth: GLOBAL_SIDEBAR_WIDTH,
  height: `calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px)`,
  border: "none",
  borderRadius: 0,
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: "none",
}));

type GlobalSidebarKey = "tenant" | "my-workspace";

type Item = {
  key: GlobalSidebarKey;
  path: string;
  icon: React.ReactNode;
};

const items: Item[] = [
  {
    key: "tenant",
    path: "/",
    icon: <MapsHomeWorkIcon fontSize="large" />,
  },
  {
    key: "my-workspace",
    path: "/my-workspaces",
    icon: <WorkspacesIcon fontSize="large" />,
  },
];

export const GlobalSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const authStore = AuthStore.useContainer();
  const [activeKey, setActiveKey] = useState<GlobalSidebarKey>("tenant");

  useEffect(() => {
    const isTenantPath =
      TENANT_SIDEBAR_ITEMS.find((item) => item.path === location.pathname) ||
      ["boards", "charts"].includes(location.pathname.split("/")[1]); // /charts/:id => ["", charts, :id]
    isTenantPath ? setActiveKey("tenant") : setActiveKey("my-workspace");
  }, [location.pathname]);

  const onClickItem = (item: Item) => {
    setActiveKey(item.key);
    navigate(item.path);
  };

  const isItemDisabled = (item: Item) => {
    return item.key === "tenant" && authStore.isTenantGuest;
  };

  return (
    <StyledPaper>
      <Box p={1}>
        {items.map((item) => (
          <Box key={item.key} mb={1}>
            <IconTextButton
              icon={item.icon}
              text={t(item.key)}
              color={isItemDisabled(item) ? undefined : activeKey === item.key ? "primary" : undefined}
              disabled={isItemDisabled(item)}
              onClick={isItemDisabled(item) ? undefined : () => onClickItem(item)}
            />
          </Box>
        ))}
      </Box>
    </StyledPaper>
  );
};
