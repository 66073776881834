import { stringWidthUtil } from "./string-width";

const DEFAULT_MARGIN = 30;

const getMinColumnWidth = (columnValues: string[], limit: number, margin: number = DEFAULT_MARGIN): number => {
  const columnWidth = Math.max(...columnValues.map((value) => stringWidthUtil.getPixel(value))) + margin;
  return columnWidth < limit ? columnWidth : limit;
};

export const gridUtil = {
  getMinColumnWidth,
};
