import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { MoreMenu } from "../../../components/more-menu";
import { MyTooltip } from "../../../components/my-tooltip";
import { DATA_GRID_MORE_MENU_WIDTH } from "../../../config/style";
import { TenantRoleTextDef } from "../../../config/text-def";
import { AuthStore } from "../../../stores/auth-store";
import { MemberDeleteDialog } from "../components/member-delete-dialog";
import { MemberEditForm } from "../components/member-edit-form";
import { MemberNewForm } from "../components/member-new-form";
import { MemberQueryResult, memberApi } from "../member-api";

export const Member = () => {
  const { t } = useTranslation();

  const { userId, isTenantOwner } = AuthStore.useContainer();
  const [newFormOpen, setNewFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editTarget, setEditTarget] = useState<MemberQueryResult>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<MemberQueryResult>();

  const membersQuery = memberApi.useMembers();

  if (membersQuery.isLoading)
    return (
      <TenantLayout activeKey="member">
        <Grid container>
          <Grid item sm={12} md={10} lg={8}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!membersQuery.data) return null;

  const onClickNew = () => {
    setNewFormOpen(true);
  };

  const onClickEdit = (member: MemberQueryResult) => {
    setEditTarget(member);
    setEditFormOpen(true);
  };

  const onClickDelete = (member: MemberQueryResult) => {
    setDeleteTarget(member);
    setDeleteDialogOpen(true);
  };

  const columns: GridColDef<MemberQueryResult>[] = [
    { field: "name", headerName: t("name") as string, flex: 1 },
    { field: "email", headerName: t("email") as string, flex: 1.6 },
    {
      field: "role",
      headerName: t("role") as string,
      flex: 1,
      renderCell: ({ row }) => {
        return t(TenantRoleTextDef.get(row.role) as string);
      },
    },
    {
      field: "moreMenu",
      headerName: "",
      width: DATA_GRID_MORE_MENU_WIDTH,
      renderCell: ({ row }) => {
        return (
          <MoreMenu>
            <MenuItem onClick={() => onClickEdit(row)} disabled={!userId || row.userId === userId || !isTenantOwner}>
              <EditIcon fontSize="small" sx={{ mr: 1 }} />
              {t("edit")}
            </MenuItem>
            <MenuItem
              dense
              onClick={() => onClickDelete(row)}
              sx={{ color: (theme) => theme.palette.error.main }}
              disabled={!userId || row.userId === userId || !isTenantOwner}
            >
              <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
              {t("delete")}
            </MenuItem>
          </MoreMenu>
        );
      },
    },
  ];

  return (
    <TenantLayout activeKey="member">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography variant="h6">{t("member")}</Typography>
            <Typography variant="caption">{t("member.caption")}</Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item sm={12} md={10} lg={8}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MyTooltip unauthorized={!isTenantOwner}>
                  <Button variant="contained" onClick={onClickNew} disabled={!isTenantOwner}>
                    {t("member.new")}
                  </Button>
                </MyTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item sm={12} md={10} lg={8}>
            <DataGrid
              sx={{
                bgcolor: (theme) => theme.palette.common.white,
                "& .MuiDataGrid-columnHeader": {
                  bgcolor: (theme) => theme.palette.common.white,
                },
                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                  outline: "none",
                },
                "& .MuiDataGrid-row:nth-of-type(1) .MuiDataGrid-cell": {
                  borderTop: "none",
                },
              }}
              getRowId={(row) => row.userId}
              rows={membersQuery.data.filter((member) => member.role !== "guest")}
              columns={columns}
              rowSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnSelector
              disableColumnSorting
              hideFooter
              density="compact"
              autoHeight
            />
          </Grid>
        </Grid>
      </Grid>
      {newFormOpen && <MemberNewForm open={newFormOpen} onClose={() => setNewFormOpen(false)} />}
      {editFormOpen && editTarget && (
        <MemberEditForm target={editTarget} open={editFormOpen} onClose={() => setEditFormOpen(false)} />
      )}
      {deleteDialogOpen && deleteTarget && (
        <MemberDeleteDialog target={deleteTarget} open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)} />
      )}
    </TenantLayout>
  );
};
