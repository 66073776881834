import CancelIcon from "@mui/icons-material/Cancel";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Encoding from "encoding-japanese"; // eslint-disable-line
import { useSnackbar } from "notistack";
import Papa from "papaparse";
import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { JAPAN_STANDARD_INDUSTRIAL_CLASSIFICATION_URL } from "../../../config/const";
import { IndustryTree } from "../../../config/industry";
import {
  IndustryDivisionTextDef,
  IndustryMajorGroupTextDef,
  IndustryGroupTextDef,
  IndustryDetailTextDef,
} from "../../../config/text-def";
import { IndustryDetail, IndustryDivision, IndustryGroup, IndustryMajorGroup } from "../../../types";
import { companyApi } from "../company-api";

const ACCEPTED_FILE_FORMATS = [".csv"];
const PARTIAL_DISPLAY_MESSAGE_COUNT = 4;
const REQUIRE_COLUMNS = ["会社名", "業種コード"];
const MAX_IMPORT_COMPANIES = 1000;

const StyledTableCell = styled(TableCell)(() => ({
  borderRight: "1px solid rgba(224, 224, 224, 1)",
}));

type CompanyImportCsvFormProps = {
  open: boolean;
  onClose: () => void;
};
type CSVRow = { [key: string]: string };
type PreviewRow = {
  no: string;
  name: string;
  industryCode: string;
  industryDivision: IndustryDivision;
  industryMajorGroup: IndustryMajorGroup;
  industryGroup: IndustryGroup;
  industryDetail: IndustryDetail;
};
type AlertMessage = {
  message: string;
  no: number;
};

// TODO:多言語対応
export const CompanyImportCsvForm = ({ open, onClose }: CompanyImportCsvFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [previewRows, setPreviewRows] = useState<PreviewRow[]>([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [errorMessages, setErrorMessages] = useState<AlertMessage[]>([]);
  const [warningMessages, setWarningMessages] = useState<AlertMessage[]>([]);
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [showAllIWarnings, setShowAllWarnings] = useState(false);

  const companiesQuery = companyApi.useCompanies();
  const batchUpsertCompaniesMutation = companyApi.useBatchUpsertCompanies(t, enqueueSnackbar);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "text/csv": ACCEPTED_FILE_FORMATS },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        enqueueSnackbar(t("snackbar.file.format.incorrect"));
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (result) {
          if ((result as ArrayBuffer).byteLength === 0) {
            enqueueSnackbar(t("snackbar.file.empty"));
            return;
          }

          const uint8Array = new Uint8Array(result as ArrayBuffer);
          const detectedEncoding = Encoding.detect(uint8Array);
          if (!detectedEncoding) {
            enqueueSnackbar(t("snackbar.file.encoding.incorrect"));
            return;
          }

          const unicodeString = Encoding.convert(uint8Array, {
            to: "UNICODE",
            from: detectedEncoding,
            type: "string",
          });

          Papa.parse<CSVRow>(unicodeString, {
            complete: (result: Papa.ParseResult<CSVRow>) => {
              const rows = result.data;
              if (rows.length === 0) {
                enqueueSnackbar(t("snackbar.company.info.empty"));
                return;
              }

              if (rows.length > MAX_IMPORT_COMPANIES) {
                enqueueSnackbar(t("snackbar.company.info.full"));
                return;
              }

              const hasRequiredColumns = REQUIRE_COLUMNS.every((column) => column in rows[0]);
              if (!hasRequiredColumns) {
                enqueueSnackbar(t("snackbar.columns.missing"));
                return;
              }

              const { errorMessages, warningMessages } = validateRows(rows);
              setErrorMessages(errorMessages);
              setWarningMessages(warningMessages);

              setPreviewRows(createPreviewRows(rows));
              setFileLoaded(true);
            },
            header: true,
            skipEmptyLines: true,
          });
        }
      };

      reader.readAsArrayBuffer(acceptedFiles[0]);
    },
  });

  const isValidIndustryCode = (industryCode: string) => {
    // 2桁の業種コードの場合
    if (industryCode.length === 2) {
      return IndustryTree.rawIndustryTree.some(
        (division) =>
          division.code === industryCode || division.children.some((majorGroup) => majorGroup.code === industryCode)
      );
    }

    // 3桁の業種コードの場合
    if (industryCode.length === 3) {
      return IndustryTree.rawIndustryTree.some((division) =>
        division.children.some((majorGroup) => majorGroup.children.some((group) => group.code === industryCode))
      );
    }

    // 4桁の業種コードの場合
    if (industryCode.length === 4) {
      return IndustryTree.rawIndustryTree.some((division) =>
        division.children.some((majorGroup) =>
          majorGroup.children.some((group) => group.children.includes(industryCode as never))
        )
      );
    }

    // 対応しない業種コードの長さ
    return false;
  };

  const validateRows = (rows: CSVRow[]) => {
    const submittedCompanyNames = new Set<string>();
    const errorMessages: AlertMessage[] = [];
    const warningMessages: AlertMessage[] = [];

    const existingCompanyNames = new Set(companiesQuery.data?.map((company) => company.name));

    rows.forEach((row, index) => {
      const emptyFields = REQUIRE_COLUMNS.filter((column) => !row[column]);
      if (emptyFields.length > 0) {
        errorMessages.push({
          message: t("company.import.error.empty", { number: index + 1, fields: emptyFields }),
          no: index + 1,
        });
      }

      const industryCode = row["業種コード"];
      if (!isValidIndustryCode(industryCode) && industryCode !== "") {
        errorMessages.push({
          message: t("company.import.error.invalidIndustryCode", { number: index + 1, code: industryCode }),
          no: index + 1,
        });
      }

      const companyName = row["会社名"];
      if (submittedCompanyNames.has(companyName)) {
        errorMessages.push({
          message: t("company.import.error.duplicated", { number: index + 1 }),
          no: index + 1,
        });
      } else {
        submittedCompanyNames.add(companyName);
      }
      if (existingCompanyNames.has(companyName)) {
        warningMessages.push({
          message: t("company.import.warning.existing", { number: index + 1, name: companyName }),
          no: index + 1,
        });
      }
    });

    errorMessages.sort((a, b) => a.no - b.no);

    return { errorMessages, warningMessages };
  };

  const createPreviewRows = (rows: CSVRow[]): PreviewRow[] => {
    return rows.map((row, index) => {
      const code = getIndustryCode(row["業種コード"]);
      return {
        no: (index + 1).toString(),
        name: row["会社名"],
        industryCode: row["業種コード"],
        industryDivision: code.industryDivision as IndustryDivision,
        industryMajorGroup: code.industryMajorGroup as IndustryMajorGroup,
        industryGroup: code.industryGroup as IndustryGroup,
        industryDetail: code.industryDetail as IndustryDetail,
      };
    });
  };

  const getIndustryCode = (industryCode: string) => {
    let division, majorGroup, group, detail;

    if (industryCode.length === 2) {
      division = IndustryTree.rawIndustryTree.find(
        (division) =>
          division.code === industryCode || division.children.some((majorGroup) => majorGroup.code === industryCode)
      );

      majorGroup = division?.children.find((majorGroup) => majorGroup.code === industryCode);
    } else if (industryCode.length === 3) {
      division = IndustryTree.rawIndustryTree.find((division) =>
        division.children.some((majorGroup) => majorGroup.children.some((group) => group.code === industryCode))
      );

      majorGroup = division?.children.find((majorGroup) =>
        majorGroup.children.some((group) => group.code === industryCode)
      );

      group = majorGroup?.children.find((group) => group.code === industryCode);
    } else if (industryCode.length === 4) {
      division = IndustryTree.rawIndustryTree.find((division) =>
        division.children.some((majorGroup) =>
          majorGroup.children.some((group) => group.children.includes(industryCode as never))
        )
      );

      majorGroup = division?.children.find((majorGroup) =>
        majorGroup.children.some((group) => group.children.includes(industryCode as never))
      );

      group = majorGroup?.children.find((group) => group.children.includes(industryCode as never));

      detail = group?.children.find((code) => code === industryCode);
    }

    return {
      industryDivision: division ? division.code : "",
      industryMajorGroup: majorGroup ? majorGroup.code : "",
      industryGroup: group ? group.code : "",
      industryDetail: detail ? detail : "",
    };
  };

  const onSubmit = () => {
    if (!companiesQuery.data) return;

    const companiesToUpsert = previewRows.map((row) => {
      return {
        name: row.name,
        industryDivision: row.industryDivision,
        industryMajorGroup: row.industryMajorGroup,
        industryGroup: row.industryGroup ? row.industryGroup : null,
        industryDetail: row.industryDetail ? row.industryDetail : null,
      };
    });

    batchUpsertCompaniesMutation.mutate(companiesToUpsert);
  };

  useEffect(() => {
    if (batchUpsertCompaniesMutation.isSuccess) {
      onClose();
    }
  });

  const renderDropzoneItem = () => (
    <>
      <Grid item xs={12} textAlign="center">
        <Paper
          {...getRootProps()}
          variant="outlined"
          sx={{
            p: 2,
            borderStyle: "dashed",
            bgcolor: (theme) => (isDragActive ? theme.palette.info.light : theme.palette.grey[100]),
          }}
        >
          <input {...getInputProps()} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FileUploadIcon color="disabled" sx={{ fontSize: 35 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">{t("file.drop")}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                {t("or")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="input">
                <Button
                  variant="outlined"
                  sx={{
                    bgcolor: "common.white",
                  }}
                >
                  {t("file.select")}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Box mt={1}>
        <Typography variant="caption" color="textSecondary">
          {t("file.format.available", { format: ACCEPTED_FILE_FORMATS })}
        </Typography>
      </Box>
    </>
  );

  const renderExample = () => (
    <>
      <Box mb={1}>
        <Typography>{t("company.input.csv.sample")}</Typography>
      </Box>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, bgcolor: (theme) => theme.palette.grey[100] }}>
          <Box>
            <Typography fontSize={12}>会社名,業種コード</Typography>
            <Typography fontSize={12}>株式会社サンプルA,0211</Typography>
            <Typography fontSize={12}>株式会社サンプルB,0511</Typography>
            <Typography fontSize={12}>株式会社サンプルC,06</Typography>
          </Box>
        </Paper>
      </Grid>
    </>
  );

  const renderCsvUploadForm = () => (
    <>
      <Grid container item>
        <Grid item>
          <Typography>
            <Trans
              i18nKey="company.import.reference"
              components={{
                l: (
                  <Link
                    to={JAPAN_STANDARD_INDUSTRIAL_CLASSIFICATION_URL}
                    target="_blank"
                    style={{ display: "inline-flex", alignItems: "center", textDecoration: "none" }}
                  />
                ),
                icon: <OpenInNewIcon fontSize="small" />,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
      <Grid container item>
        {renderDropzoneItem()}
      </Grid>
      <Grid container item>
        {renderExample()}
      </Grid>
    </>
  );

  const renderPreview = () => (
    <>
      {previewRows.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {warningMessages.length > 0 && (
              <Grid item xs={12}>
                <Paper sx={{ width: "100%", p: 2, bgcolor: (theme) => theme.palette.warning.light }}>
                  <Grid container alignItems="center" mb={1}>
                    <ReportProblemIcon color="warning" />
                    <Typography sx={{ color: "warning.main" }} display="flex" alignItems="center" ml={1}>
                      {t("company.import.warning.title")}
                    </Typography>
                  </Grid>
                  {warningMessages
                    .slice(0, showAllIWarnings ? warningMessages.length : PARTIAL_DISPLAY_MESSAGE_COUNT)
                    .map((warning, index) => (
                      <Typography fontSize={12} key={index}>
                        {warning.message}
                      </Typography>
                    ))}
                  {warningMessages.length > PARTIAL_DISPLAY_MESSAGE_COUNT && (
                    <Grid item xs={12} textAlign="center" mt={1}>
                      <Paper
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "8px",
                          bgcolor: "common.white",
                          width: "100%",
                        }}
                        onClick={() => setShowAllWarnings(!showAllIWarnings)}
                      >
                        {showAllIWarnings ? (
                          <>
                            <ExpandLessIcon />
                            <Typography fontSize={12}>{t("show.partial")}</Typography>
                          </>
                        ) : (
                          <>
                            <ExpandMoreIcon />
                            <Typography fontSize={12}>{t("show.all", { count: warningMessages.length })}</Typography>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            )}
            {errorMessages.length > 0 && (
              <Grid item xs={12}>
                <Paper sx={{ width: "100%", p: 2, bgcolor: (theme) => theme.palette.error.light }}>
                  <Grid container alignItems="center" mb={1}>
                    <CancelIcon color="error" />
                    <Typography color="error" display="flex" alignItems="center" ml={1}>
                      {t("company.import.error.title")}
                    </Typography>
                  </Grid>
                  {errorMessages
                    .slice(0, showAllErrors ? errorMessages.length : PARTIAL_DISPLAY_MESSAGE_COUNT)
                    .map((error, index) => (
                      <Typography fontSize={12} key={index}>
                        {error.message}
                      </Typography>
                    ))}
                  {errorMessages.length > PARTIAL_DISPLAY_MESSAGE_COUNT && (
                    <Grid item xs={12} textAlign="center" mt={1}>
                      <Paper
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "8px",
                          bgcolor: "common.white",
                          width: "100%",
                        }}
                        onClick={() => setShowAllErrors(!showAllErrors)}
                      >
                        {showAllErrors ? (
                          <>
                            <ExpandLessIcon />
                            <Typography fontSize={12}>{t("show.partial")}</Typography>
                          </>
                        ) : (
                          <>
                            <ExpandMoreIcon />
                            <Typography fontSize={12}>{t("show.all", { count: errorMessages.length })}</Typography>
                          </>
                        )}
                      </Paper>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            )}
            <Grid container item>
              <Grid item>
                <Typography variant="h6">{t("company.import.preview")}</Typography>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TableContainer sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow sx={{ whiteSpace: "nowrap" }}>
                        <StyledTableCell>No.</StyledTableCell>
                        <StyledTableCell>{t("company-name")}</StyledTableCell>
                        <StyledTableCell>{t("industry-code")}</StyledTableCell>
                        <StyledTableCell>{t("industry-division")}</StyledTableCell>
                        <StyledTableCell>{t("industry-major-group")}</StyledTableCell>
                        <StyledTableCell>{t("industry-group")}</StyledTableCell>
                        <StyledTableCell>{t("industry-detail")}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {previewRows.map((row) => (
                        <TableRow
                          key={row.no}
                          sx={{
                            "&.errorRow": { bgcolor: (theme) => theme.palette.error.light },
                            "&.warningRow": { bgcolor: (theme) => theme.palette.warning.light },
                          }}
                          className={
                            errorMessages.some((error) => error.no === parseInt(row.no))
                              ? "errorRow"
                              : warningMessages.some((warning) => warning.no === parseInt(row.no))
                              ? "warningRow"
                              : ""
                          }
                        >
                          <StyledTableCell>{row.no}</StyledTableCell>
                          <StyledTableCell>{row.name}</StyledTableCell>
                          <StyledTableCell>{row.industryCode}</StyledTableCell>
                          <StyledTableCell>
                            {t(IndustryDivisionTextDef.get(row.industryDivision as IndustryDivision) || "")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {t(IndustryMajorGroupTextDef.get(row.industryMajorGroup as IndustryMajorGroup) || "")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {t(IndustryGroupTextDef.get(row.industryGroup as IndustryGroup) || "")}
                          </StyledTableCell>
                          <StyledTableCell>
                            {t(IndustryDetailTextDef.get(row.industryDetail as IndustryDetail) || "")}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth={fileLoaded ? "lg" : "sm"}>
        <DialogTitle sx={{ bgcolor: "primary.main", color: "common.white" }}>{t("company.import.csv")}</DialogTitle>
        <DialogContent dividers sx={{ height: fileLoaded ? "700px" : "auto" }}>
          <Box>
            <Grid container spacing={2}>
              {fileLoaded ? renderPreview() : renderCsvUploadForm()}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          {errorMessages.length > 0 ? (
            <Button disabled={!fileLoaded} variant="contained" onClick={() => setFileLoaded(false)}>
              {t("company.re-upload")}
            </Button>
          ) : (
            <Button disabled={!fileLoaded} variant="contained" onClick={onSubmit}>
              {t("new")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <LoadingModal open={batchUpsertCompaniesMutation.isLoading} />
    </>
  );
};
