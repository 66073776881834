import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES } from "../../../config/const";
import { AggregateTypeTextDef, HeadcountAndRatioTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import {
  AggregateType,
  AggregateTypeRow,
  BaseData,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  HeadcountAndRatioType,
  HeadcountType,
  RatioType,
} from "../../../types";
import { BaseColumnLineChart } from "../base-column-line-chart";

export type BaseHeadcountAndRatioTypeColumnLineData = {
  headcountAndRatioType: HeadcountAndRatioType;
} & BaseData;

export type BaseHeadcountAndRatioTypeColumnLineChartSeriesCode = `${AggregateType}_${HeadcountAndRatioType}`;

type HeadcountChartSeriesCode = `${AggregateType}_${HeadcountType}`;
type RatioChartSeriesCode = `${AggregateType}_${RatioType}`;

export class BaseHeadcountAndRatioTypeColumnLineChart extends BaseColumnLineChart<BaseHeadcountAndRatioTypeColumnLineData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRatioTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRatioTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      return {
        ...super.getChartOptions(t, queryResult, displaySwitch, inBoard),
        series: super.getSeries(queryResult, displaySwitch, this._getSeriesCode, this._createSeriesDefs(t)),
      };
    }
    throw new Error("ChartQueryResult must be array.");
  }

  protected _getSeriesCode(
    datum: BaseHeadcountAndRatioTypeColumnLineData
  ): BaseHeadcountAndRatioTypeColumnLineChartSeriesCode {
    return `${datum.aggregateType}_${datum.headcountAndRatioType}`;
  }

  protected _createSeriesDefs(t: TFunction): [
    Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<RatioChartSeriesCode, ChartSeriesLineOption>,
    BaseHeadcountAndRatioTypeColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<RatioChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseHeadcountAndRatioTypeColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      const ratioChartSeriesCode: RatioChartSeriesCode = `${aggregateType}_ratio`;
      orderedSeriesCodes.push(ratioChartSeriesCode);
      lineMap.set(ratioChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          HeadcountAndRatioTypeTextDef.get("ratio") as string
        )}`,
        color: super.getLineColor(aggregateType, 0),
        dashStyle: super.getDashStyle(aggregateType),
      });

      const headcountChartSeriesCode: HeadcountChartSeriesCode = `${aggregateType}_headcount`;
      orderedSeriesCodes.push(headcountChartSeriesCode);
      columnMap.set(headcountChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          HeadcountAndRatioTypeTextDef.get("headcount") as string
        )}`,
        color: super.getColumnColor(aggregateType, 0),
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseHeadcountAndRatioTypeColumnLineData>
      | ChartQueryResult<BaseHeadcountAndRatioTypeColumnLineData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      const [columnQueryResult, lineQueryResult] = queryResult;
      return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => ({
        aggregateType,
        rows: [
          {
            header: t(HeadcountAndRatioTypeTextDef.get("headcount") as string) as string,
            unit: columnQueryResult.unit,
            values: super.getValuesByCondition(
              columnQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.headcountAndRatioType === "headcount"
            ),
          },
          {
            header: t(HeadcountAndRatioTypeTextDef.get("ratio") as string) as string,
            unit: lineQueryResult.unit,
            values: super.getValuesByCondition(
              lineQueryResult,
              (datum) => datum.aggregateType === aggregateType && datum.headcountAndRatioType === "ratio"
            ),
          },
        ],
      }));
    }
    throw new Error("ChartQueryResult must be array.");
  }
}
