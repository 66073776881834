import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../../../components/styled/styled-button";
import { StyledButtonGroup } from "../../../components/styled/styled-button-group";
import { ChartTypeTextDef } from "../config/text-def";
import { DashboardStore } from "../stores/dashboard-store";

type DashboardChartSwitchButtonProps = {
  size?: "small" | "medium" | "large";
  disabled?: boolean;
};

export const DashboardChartSwitchButton = ({ size = "medium", disabled = false }: DashboardChartSwitchButtonProps) => {
  const { t } = useTranslation();
  const { selectedChartType, setSelectedChartType } = DashboardStore.useContainer();

  return (
    <StyledButtonGroup variant="outlined" size={size} disabled={disabled}>
      {Array.from(ChartTypeTextDef).map(([key, i18nKey]) => (
        <Tooltip key={key} title={t(i18nKey)}>
          <StyledButton
            sx={{
              bgcolor: (theme) => (key === selectedChartType ? theme.palette.grey[200] : undefined),
              px: 1.5,
            }}
            size={size}
            onClick={() => setSelectedChartType(key)}
            disabled={disabled && false}
          >
            {key === "graph" ? <BarChartIcon /> : <CalendarViewMonthIcon />}
          </StyledButton>
        </Tooltip>
      ))}
    </StyledButtonGroup>
  );
};
