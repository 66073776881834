import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { TFunction } from "i18next";
import { EnqueueSnackbar } from "notistack";
import { CloseSnackbarButton } from "../../components/close-snackbar-button";
import { axiosInstance } from "../../lib/axios";
import { queryClient } from "../../lib/react-query";
import { IndustryDetail, IndustryDivision, IndustryGroup, IndustryMajorGroup } from "../../types";
import { CompanyVo, TenantVo, WorkspaceVo } from "../../types/vo";
import { handleClientError } from "../../utils/handle-client-error";
import { snackbarUtil } from "../../utils/snackbar";

export type CompanyQueryResult = CompanyVo;

export type SettingQueryResult = TenantVo;

export type CompanyDeleteResult = {
  isSuccess: boolean;
  linkedWorkspaces: WorkspaceVo[];
  isLinkedWithCompanyMappings: boolean;
};

export type CompanyCreateRequest = {
  name: string;
  industryDivision: IndustryDivision;
  industryMajorGroup: IndustryMajorGroup;
  industryGroup: IndustryGroup | null;
  industryDetail: IndustryDetail | null;
};

export type CompanyUpdateRequest = {
  id: string;
  name: string;
  industryDivision: IndustryDivision;
  industryMajorGroup: IndustryMajorGroup;
  industryGroup: IndustryGroup | null;
  industryDetail: IndustryDetail | null;
};

export type CompanyUpsertRequest = {
  name: string;
  industryDivision: IndustryDivision;
  industryMajorGroup: IndustryMajorGroup;
  industryGroup: IndustryGroup | null;
  industryDetail: IndustryDetail | null;
};
// axios

const getCompanies = async (): Promise<CompanyQueryResult[]> => {
  return axiosInstance.get(`/companies`);
};

const getSetting = async (): Promise<SettingQueryResult> => {
  return axiosInstance.get("/setting");
};

const createCompany = async (data: CompanyCreateRequest) => {
  return axiosInstance.post(`/companies`, data);
};

const updateCompany = async (data: CompanyUpdateRequest) => {
  return axiosInstance.put(`/companies`, data);
};

const batchUpsertCompanies = async (data: CompanyUpsertRequest[]) => {
  return axiosInstance.put(`/companies/batch`, data);
};

const deleteCompany = async (id: string): Promise<CompanyDeleteResult> => {
  return axiosInstance.delete(`/companies/${id}`);
};

// react-query

const useCompanies = () => {
  return useQuery({ queryKey: ["companies"], queryFn: getCompanies });
};

const useSetting = () => {
  return useQuery({ queryKey: ["@company", "setting"], queryFn: getSetting });
};

const useCreateCompany = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: createCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useUpdateCompany = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useBatchUpsertCompanies = (t: TFunction, enqueSnackbar: EnqueueSnackbar) => {
  return useMutation({
    mutationFn: batchUpsertCompanies,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      enqueSnackbar(t("snackbar.saved"), { variant: "success" });
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

const useDeleteCompany = (t: TFunction, enqueSnackbar: EnqueueSnackbar, id: string) => {
  return useMutation({
    mutationFn: () => deleteCompany(id),
    onSuccess: (data: CompanyDeleteResult) => {
      if (data.isSuccess) {
        queryClient.invalidateQueries({ queryKey: ["companies"] });
        enqueSnackbar(t("snackbar.deleted"), { variant: "success" });
      } else {
        if (data.linkedWorkspaces.length > 0) {
          const message = t("snackbar.company.linked-workspaces.not-deletable");
          const bulletedList = data.linkedWorkspaces.map((w) => `・${w.name}`).join("\n");
          enqueSnackbar(`${message}\n${bulletedList}`, {
            autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_LONG,
            action: CloseSnackbarButton,
            style: { whiteSpace: "pre-line", width: snackbarUtil.getCssWidthInPixel(message) },
          });
        } else {
          // data.isLinkedWithCompanyMappings == true と同義
          const message = t("snackbar.company.linked-company-mappings.not-deletable");
          enqueSnackbar(`${message}`, {
            autoHideDuration: snackbarUtil.AUTO_HIDE_DURATION_LONG,
            action: CloseSnackbarButton,
            style: { width: snackbarUtil.getCssWidthInPixel(message) },
          });
        }
      }
    },
    onError: (e: AxiosError<string>) => {
      handleClientError(e, t, enqueSnackbar);
    },
  });
};

export const companyApi = {
  useCompanies,
  useSetting,
  useCreateCompany,
  useUpdateCompany,
  useBatchUpsertCompanies,
  useDeleteCompany,
};
