// numDecimalPlaces: 四捨五入をして残す小数点以下の桁数
const roundDecimal = (value: number, numDecimalPlaces: number) => {
  const factor = Math.pow(10, numDecimalPlaces);
  return Math.round(value * factor) / factor;
};

const formatWithCommas = (value: number): string => value.toLocaleString("en-US"); // 3桁区切りのカンマを付けるだけなので、en-USを指定

export const numberUtil = {
  roundDecimal,
  formatWithCommas,
};
