import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { MyTooltip } from "../../../components/my-tooltip";
import { IndustryDivisionTextDef } from "../../../config/text-def";
import { AuthStore } from "../../../stores/auth-store";
import { IndustryDivision } from "../../../types";
import { settingApi } from "../setting-api";

export const Setting = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { isTenantOwner } = AuthStore.useContainer();

  const settingQuery = settingApi.useSetting();
  const updateSettingMutation = settingApi.useUpdateSetting(t, enqueueSnackbar);

  const [industryDivision, setIndustryDivision] = useState<IndustryDivision>();

  useEffect(() => {
    if (settingQuery.data) {
      setIndustryDivision(settingQuery.data.industryDivision);
    }
  }, [settingQuery.data]);

  if (settingQuery.isLoading)
    return (
      <TenantLayout activeKey="general-setting">
        <Grid container>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!settingQuery.data) return null;

  const onChangeIndustry = (e: SelectChangeEvent<IndustryDivision>): void => {
    setIndustryDivision(e.target.value as IndustryDivision);
  };

  const onClickSave = (): void => {
    if (industryDivision) updateSettingMutation.mutate({ ...settingQuery.data, industryDivision });
  };

  return (
    <TenantLayout activeKey="general-setting">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography variant="h6">{t("general-setting")}</Typography>
            <Typography variant="caption">{t("general-setting.caption")}</Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MyTooltip unauthorized={!isTenantOwner}>
                  <Button
                    variant="contained"
                    onClick={onClickSave}
                    disabled={updateSettingMutation.isLoading || !isTenantOwner}
                  >
                    {t("save")}
                  </Button>
                </MyTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12} sm={10} md={8} lg={6} sx={{ bgcolor: (theme) => theme.palette.common.white }} p={2}>
            <Grid container>
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Box mb={1} display="flex" alignItems="center">
                  <Typography mr={0.5}>{t("setting.default-industry")}</Typography>
                  {/* i18nの言語ファイルで定義した改行をTooltip上で反映させるため */}
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{t("setting.default-industry.caption")}</span>}
                  >
                    <HelpOutlineIcon fontSize="small" sx={{ color: (theme) => theme.palette.grey[500] }} />
                  </Tooltip>
                </Box>
                <Select
                  fullWidth
                  value={industryDivision || ""}
                  onChange={onChangeIndustry}
                  disabled={updateSettingMutation.isLoading}
                >
                  {Array.from(IndustryDivisionTextDef).map(([key, i18nKey]) => (
                    <MenuItem key={key} value={key}>
                      {t(i18nKey)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LoadingModal open={updateSettingMutation.isLoading} />
    </TenantLayout>
  );
};
