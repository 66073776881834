import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: "ja",
    fallbackLng: "ja",
    ns: ["default"],
    fallbackNS: "default",
    backend: {
      loadPath: "/i18n/{{ns}}/{{lng}}.json",
    },
    debug: false,
  });

export default i18n;
