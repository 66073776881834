import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { memberApi } from "../member-api";
import { MemberBaseForm, MemberFormModel } from "./member-base-form";

type MemberNewFormProps = {
  open: boolean;
  onClose: () => void;
};

export const MemberNewForm = ({ open, onClose }: MemberNewFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const createMemberMutation = memberApi.useCreateMember(t, enqueueSnackbar);

  const onSubmit = ({ email, role }: MemberFormModel) => {
    createMemberMutation.mutate({ email, role });
  };

  return (
    <MemberBaseForm
      title={t("member.new")}
      buttonLabel={t("add")}
      defaultValue={{ email: "", role: "owner" }}
      open={open}
      loading={createMemberMutation.isLoading}
      done={createMemberMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
