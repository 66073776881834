import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import Grid from "@mui/material/Grid/Grid";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Paper from "@mui/material/Paper/Paper";
import Select from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { HEADER_HEIGHT } from "../../../components/layout/global-header";
import { COMPANY_TYPES } from "../../../config/const";
import { PLACEHOLDER_OPACITY } from "../../../config/style";
import { CompanyTypeTextDef } from "../../../config/text-def";
import { Chart, CompanyType, IndustryDivision } from "../../../types";
import { BusinessYearVo } from "../../../types/vo";
import { FilterQueryResult, dashboardApi } from "../dashboard-api";
import { filters } from "../functions/filters";
import { CHART_HEADER_HEIGHT } from "./layout/dashboard-chart-header";

const CHART_FILTER_WIDTH = 260;
const CHART_FILTER_FOOTER_HEIGHT = 68;

const paperCommonStyles = (theme: Theme) => ({
  width: CHART_FILTER_WIDTH,
  minWidth: CHART_FILTER_WIDTH,
  border: "none",
  borderRadius: 0,
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  boxShadow: "none",
});

const StyledBodyPaper = styled(Paper)(({ theme }) => ({
  ...paperCommonStyles(theme),
  height: `calc(var(--vh, 100vh) - ${HEADER_HEIGHT + CHART_HEADER_HEIGHT + CHART_FILTER_FOOTER_HEIGHT}px)`,
}));

const StyledFooterPaper = styled(Paper)(({ theme }) => ({
  ...paperCommonStyles(theme),
  height: `${CHART_FILTER_FOOTER_HEIGHT}px`,
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}));

export type DashboardChartFilterFormModel = {
  fromBusinessYearStartDate: string;
  toBusinessYearStartDate: string;
  companyType: CompanyType;
  companyIds: string[];
  industryDivision: IndustryDivision;
};

type DashboardChartFilterFormProps = {
  chart: Chart;
  applyDisabled: boolean;
  isSingleYear: boolean;
  onSubmit: (filter: DashboardChartFilterFormModel) => void;
};

export const DashboardChartFilterForm = ({
  chart,
  applyDisabled,
  isSingleYear,
  onSubmit,
}: DashboardChartFilterFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const useFiltersQuery = dashboardApi.useFilters(chart);
  const useSettingsQuery = dashboardApi.useSetting();
  const queryParams = useLocation().search;
  const searchParams = new URLSearchParams(queryParams);

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    formState: { isValid },
  } = useForm<DashboardChartFilterFormModel>({
    defaultValues: {
      fromBusinessYearStartDate: "",
      toBusinessYearStartDate: "",
      companyType: "original",
      companyIds: [],
      industryDivision: "A",
    },
    resolver: yupResolver(
      yup.object<DashboardChartFilterFormModel>({
        // toBusinessYearsStartDateのバリデーションのための存在確認
        fromBusinessYearStartDate: yup
          .string()
          .required(t("validation.required") as string)
          .defined(),
        toBusinessYearStartDate: yup
          .string()
          .required(t("validation.required") as string)
          .test("from-on-or-before-to", t("validation.business-year.to") as string, function (value) {
            return (
              dayjs(value).isAfter(dayjs(this.parent.fromBusinessYearStartDate)) ||
              dayjs(value).isSame(dayjs(this.parent.fromBusinessYearStartDate))
            );
          })
          .required(t("validation.required") as string)
          .defined(),
      })
    ),
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const fromBusinessYearStartDate = watch("fromBusinessYearStartDate");
  const toBusinessYearStartDate = watch("toBusinessYearStartDate");

  // 終了事業年度のバリデーションエラー発生時に、ユーザーが開始事業年度を変更した場合にもエラーを解消するため
  useEffect(() => {
    if (fromBusinessYearStartDate) trigger("toBusinessYearStartDate");
  }, [fromBusinessYearStartDate, trigger]);

  // 単年度のチャートで、終了事業年度を変更した場合に、開始事業年度を変更する
  useEffect(() => {
    if (isSingleYear) {
      setValue("fromBusinessYearStartDate", toBusinessYearStartDate);
    }
  }, [isSingleYear, toBusinessYearStartDate, setValue]);

  useEffect(() => {
    if (useFiltersQuery.data) {
      // 事業年度が登録されていない場合、ルートパスの画面では「事業年度を登録してください」と出て画面から遷移はできないが、
      // 直接URLを入力して遷移した場合は事業年度が登録されていない時に限り、ルートパスにリダイレクトする
      if (useFiltersQuery.data.businessYears.length > 0) {
        // searchParams.sizeに対応していないバージョンのため
        // https://github.com/vercel/next.js/pull/53144
        if (Array.from(searchParams.keys()).length) {
          setValueFromSearchParams(useFiltersQuery.data);
        } else {
          setDefaultBusinessYearValue(useFiltersQuery.data.businessYears);
        }
        handleSubmit(onSubmit)();
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFiltersQuery.data]);

  useEffect(() => {
    if (useSettingsQuery.data) {
      setValue("industryDivision", useSettingsQuery.data.industryDivision);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSettingsQuery.data]);

  const setDefaultBusinessYearValue = (businessYears: BusinessYearVo[]) => {
    setValue(
      "fromBusinessYearStartDate",
      isSingleYear ? businessYears[0].startDate : filters.getFromBusinessYearStartDate(businessYears)
    );
    setValue("toBusinessYearStartDate", businessYears[0].startDate);
  };

  const setValueFromSearchParams = (useFiltersQueryData: FilterQueryResult) => {
    const fromParam = searchParams.get("fromBusinessYearStartDate");
    const toParam = searchParams.get("toBusinessYearStartDate");
    if (
      fromParam &&
      toParam &&
      useFiltersQueryData.businessYears.find((businessYear) => businessYear.startDate === fromParam) &&
      useFiltersQueryData.businessYears.find((businessYear) => businessYear.startDate === toParam)
    ) {
      setValue("fromBusinessYearStartDate", fromParam);
      setValue("toBusinessYearStartDate", toParam);
      const companyTypeParam = searchParams.get("companyType");
      const companyIdsStrParam = searchParams.get("companyIds");
      if (companyTypeParam && COMPANY_TYPES.includes(companyTypeParam as CompanyType)) {
        setValue("companyType", companyTypeParam as CompanyType);
      }
      if (companyIdsStrParam) {
        const companyIdsParam = companyIdsStrParam.split(",");
        setValue(
          "companyIds",
          companyIdsParam.filter((id) => useFiltersQueryData.companies.find((c) => c.id === id))
        );
      }
    } else {
      // パラメータの前のPathが同一だとuseFiltersQuery.dataのuseEffectが発火しないため、
      // 発火させようとすると処理が複雑になるため、リダイレクトではなくデフォルト値をセットしている。
      setDefaultBusinessYearValue(useFiltersQueryData.businessYears);
    }
  };

  // if (useFiltersQuery.isLoading) return <Loading />; は不要。
  // 上記のuseEffectでfilterが取得できたタイミングでhandleSubmitを流し、チャートを描画するため。
  // 逆にこれを記述してしまうと、フィルタのローディングとチャートのローディングの二重表示になってしまう
  if (!useFiltersQuery.data || !useSettingsQuery.data) return null;

  const renderFromBusinessYearFilter = () => (
    <>
      <Box mb={1}>
        <Typography>{t("business-year.from")}</Typography>
      </Box>
      <Controller
        name="fromBusinessYearStartDate"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={Boolean(fieldState.error)} fullWidth>
            <Select
              {...field}
              disabled={isSingleYear}
              displayEmpty
              renderValue={(selected) => {
                const selectedFromBusinessYear = useFiltersQuery.data.businessYears.filter(
                  ({ startDate }) => selected === startDate
                );
                return selectedFromBusinessYear[0]?.name;
              }}
            >
              {useFiltersQuery.data.businessYears.map(({ startDate, name }) => (
                <MenuItem key={startDate} value={startDate}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );

  const renderToBusinessYearFilter = () => (
    <>
      <Box mb={1}>
        <Box display="flex" alignItems="center">
          <Typography>{t("business-year.to")}</Typography>
          {isSingleYear && (
            <Box ml={0.5} display="flex" justifyContent="center">
              <Tooltip title={t("dashboard.business-year.to.tooltip")} placement="top">
                <HelpOutlineIcon fontSize="small" sx={{ color: (theme) => theme.palette.grey[500] }} />
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Controller
        name="toBusinessYearStartDate"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={Boolean(fieldState.error)} fullWidth>
            <Select
              {...field}
              displayEmpty
              renderValue={(selected) => {
                const selectedToBusinessYear = useFiltersQuery.data.businessYears.filter(
                  ({ startDate }) => selected === startDate
                );
                return selectedToBusinessYear[0]?.name;
              }}
            >
              {useFiltersQuery.data.businessYears.map(({ startDate, name }) => (
                <MenuItem key={startDate} value={startDate}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </>
  );

  const renderCompanyIdsFilter = () => (
    <>
      <Box mb={1}>
        <Typography>{t("dashboard.our-company")}</Typography>
      </Box>
      <Controller
        name="companyIds"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <Select
              {...field}
              multiple
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <Typography sx={{ opacity: PLACEHOLDER_OPACITY }}>{t("placeholder.select")}</Typography>;
                }
                const selectedCompanies = useFiltersQuery.data.companies.filter(({ id }) => selected.includes(id));
                return selectedCompanies.map((s) => s.name).join(", ");
              }}
            >
              {useFiltersQuery.data.companies.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox checked={field.value?.includes(id)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </>
  );

  const renderCompanyTypeFilter = () => (
    <>
      <Box mb={1}>
        <Typography>{t("company-type")}</Typography>
      </Box>
      <Controller
        name="companyType"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <Select
              {...field}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <Typography sx={{ opacity: PLACEHOLDER_OPACITY }}>{t("placeholder.select")}</Typography>;
                }
                return t(CompanyTypeTextDef.get(selected) as string);
              }}
            >
              {COMPANY_TYPES.map((value) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={t(CompanyTypeTextDef.get(value) as string)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </>
  );

  return (
    <Box display="flex" flexDirection="column">
      <StyledBodyPaper>
        <Box
          overflow="scroll"
          height={`calc(var(--vh, 100vh) - ${HEADER_HEIGHT + CHART_HEADER_HEIGHT + CHART_FILTER_FOOTER_HEIGHT}px)`}
        >
          <Box p={2}>
            <Grid container spacing={2}>
              <>
                <Grid item xs={12}>
                  {renderFromBusinessYearFilter()}
                </Grid>
                <Grid item xs={12}>
                  {renderToBusinessYearFilter()}
                </Grid>
              </>
              <Grid item xs={12}>
                {renderCompanyIdsFilter()}
              </Grid>
              <Grid item xs={12}>
                {renderCompanyTypeFilter()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </StyledBodyPaper>
      <StyledFooterPaper>
        <Grid container justifyContent="flex-end">
          <Box p={2}>
            <Button disabled={!isValid || applyDisabled} variant="contained" onClick={handleSubmit(onSubmit)}>
              {t("apply")}
            </Button>
          </Box>
        </Grid>
      </StyledFooterPaper>
    </Box>
  );
};
