import Box from "@mui/material/Box";
import IconButton, { IconButtonPropsColorOverrides } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { OverridableStringUnion } from "@mui/types";
import React from "react";

type IconTextButtonProps = {
  icon: React.ReactNode;
  text: string;
  color?: OverridableStringUnion<
    "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning",
    IconButtonPropsColorOverrides
  >;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const IconTextButton = ({ icon, text, color, disabled = false, onClick }: IconTextButtonProps) => {
  return (
    <Box textAlign="center" sx={{ cursor: disabled ? "not-allowed" : "pointer" }} onClick={onClick}>
      <IconButton color={color} disabled={disabled}>
        {icon}
      </IconButton>
      <Box mt={-1}>
        <Typography fontSize={10} component="div" color={disabled ? "grey.400" : color} whiteSpace="pre-line">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
