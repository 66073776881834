import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";

import { SnackbarKey, useSnackbar } from "notistack";

export const CloseSnackbarButton = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Button onClick={() => closeSnackbar(key)}>
      <CloseIcon sx={{ color: "common.white" }} />
    </Button>
  );
};
