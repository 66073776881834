import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LoadingModal } from "../../../components/loading/loading-modal";
import { CompanyQueryResult, companyApi } from "../company-api";

type CompanyDeleteDialogProps = {
  target: CompanyQueryResult;
  open: boolean;
  onClose: () => void;
};

export const CompanyDeleteDialog = ({ target, open, onClose }: CompanyDeleteDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const deleteCompanyMutation = companyApi.useDeleteCompany(t, enqueueSnackbar, target.id);

  const onSubmit = () => {
    deleteCompanyMutation.mutate();
  };

  useEffect(() => {
    if (deleteCompanyMutation.isSuccess) onClose();
  }, [deleteCompanyMutation.isSuccess, onClose]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>{t("company.delete")}</DialogTitle>
        <DialogContent dividers>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography>{t("company.delete.confirmation")}</Typography>
                </Box>
                <Typography>{target.name}</Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="normal" variant="contained" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Button
            disabled={deleteCompanyMutation.isLoading}
            variant="contained"
            onClick={onSubmit}
            autoFocus
            color="error"
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingModal open={deleteCompanyMutation.isLoading} />
    </>
  );
};
