import { Route, Routes } from "react-router-dom";
import { Login } from "../login/routes/index";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      {/* TODO: 存在しないパスがリクエストされた時のハンドリングを行う */}
    </Routes>
  );
};
