import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { alertClasses } from "@mui/material/Alert";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { alpha, createTheme } from "@mui/material/styles";
import { COMPANY_BACKGROUND_COLOR } from "./style";

export const neonTheme = createTheme({
  palette: {
    grey: {
      50: "#ffffff",
      100: "#f4f4f4",
      200: "#eaeaea",
      300: "#d3d3d3",
      400: "#bcbcbc",
      500: "#959595",
      600: "#747474",
      700: "#505050",
      800: "#323232",
      900: "#000000",
    },
    background: {
      default: "#f4f4f4",
    },
    text: {
      primary: "#323232",
    },
    primary: {
      main: "#007cc5",
      contrastText: "#ffffff",
    },
    info: {
      main: "#4285f4",
      light: "#ecf2fd",
      contrastText: "#ffffff",
    },
    success: {
      main: "#00b19d",
      light: "#eaf8f7",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#f4b142",
      light: "#fdf7ec",
      contrastText: "#ffffff",
    },
    error: {
      main: "#e91e63",
      light: "#fce8ef",
      contrastText: "#ffffff",
    },
    // 灰色を追加
    normal: {
      main: "#d3d3d3",
      light: "#eaeaea",
      contrastText: "#323232",
    },
    action: {
      disabled: "#bcbcbc",
      disabledBackground: "#f4f4f4",
    },
  },
  typography: {
    fontFamily: '"メイリオ", "Roboto", sans-serif',
    //デフォルトのフォントはTypographyのvariant="body1"を見ているのでここで指定
    body1: {
      fontSize: 14,
    },
    h6: {
      fontSize: 16,
    },
    h5: {
      fontSize: 20,
    },
    caption: {
      color: "#505050",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        standardInfo: ({ theme }) => {
          return {
            backgroundColor: theme.palette.info.light,
            color: "#0e63ec",
            [`& .${alertClasses.icon}`]: {
              color: "#0e63ec",
            },
          };
        },
        standardSuccess: ({ theme }) => {
          return {
            backgroundColor: theme.palette.success.light,
            color: "#007a6c",
            [`& .${alertClasses.icon}`]: {
              color: "#007a6c",
            },
          };
        },
        standardWarning: ({ theme }) => {
          return {
            backgroundColor: theme.palette.warning.light,
            color: "#d86c00",
            [`& .${alertClasses.icon}`]: {
              color: "#d86c00",
            },
          };
        },
        standardError: ({ theme }) => {
          return {
            backgroundColor: theme.palette.error.light,
            color: "#be134c",
            [`& .${alertClasses.icon}`]: {
              color: "#be134c",
            },
          };
        },
        filled: ({ theme }) => {
          return {
            color: theme.palette.common.white,
          };
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: COMPANY_BACKGROUND_COLOR,
          boxShadow: "none",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            backgroundColor: theme.palette.grey[500],
          };
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme, ownerState }) => {
          return {
            backgroundColor: theme.palette.grey[500],
            color: theme.palette.common.white,
            ...(ownerState?.color &&
              ownerState.color !== "default" && {
                backgroundColor: theme.palette[ownerState.color].main,
                color: theme.palette[ownerState.color].contrastText,
              }),
          };
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: <KeyboardArrowRightIcon fontSize="small" />,
      },
      styleOverrides: {
        root: {
          color: "#505050",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        // Buttonの色が灰色の時のホバー色を変更
        root: ({ theme, ownerState }) => {
          return {
            ...(ownerState.color === "normal" && {
              "&:hover": {
                backgroundColor: theme.palette.grey[200],
              },
            }),
          };
        },
        sizeSmall: {
          fontSize: "12px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            color: theme.palette.text.primary,
          };
        },
        asterisk: ({ theme }) => {
          return {
            color: theme.palette.error.main,
          };
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        input: () => {
          return {
            [`&.${outlinedInputClasses.disabled}`]: {
              cursor: "not-allowed",
            },
          };
        },
        root: ({ theme, ownerState }) => {
          return {
            ...(ownerState?.disabled && {
              borderColor: theme.palette.grey[100],
              backgroundColor: theme.palette.grey[100],
              borderRadius: "inherit",
              [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 0,
              },
            }),
            ...((ownerState?.readOnly || ownerState?.inputProps?.readOnly) && {
              borderColor: theme.palette.grey[100],
              backgroundColor: theme.palette.grey[100],
              borderRadius: "inherit",
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 0,
              },
              [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
                borderWidth: 0,
              },
            }),
          };
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          return {
            ...(ownerState.selected
              ? {
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                  },
                }
              : {
                  "&:hover": {
                    backgroundColor: theme.palette.grey[100],
                  },
                }),
          };
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: ({ theme }) => {
          return {
            borderBottom: 1,
            borderBottomColor: theme.palette.divider,
            borderBottomStyle: "solid",
          };
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiDialogTitle: {
      defaultProps: {
        variant: "h5",
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 24,
        },
        fontSizeMedium: {
          fontSize: 20,
        },
        fontSizeSmall: {
          fontSize: 16,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            borderRadius: "inherit", // ボタンの形状を親コンポーネントから継承する
            "&:hover": {
              backgroundColor: theme.palette.grey[200],
            },
          };
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
});
