import { numberUtil } from "../../../utils/number";
import { ChartQueryResult } from "../dashboard-api";
import { BaseData } from "../types";

const toThousandYen = <TData extends BaseData>(queryResult: ChartQueryResult<TData>): ChartQueryResult<TData> => {
  return {
    ...queryResult,
    unit: "thousand_yen", // 単位を千円に変換
    datasets: queryResult.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((datum) => ({
        ...datum,
        value: datum.value ? numberUtil.roundDecimal(datum.value / 1000, 1) : datum.value, // 値を1000で割り、小数点第一位までの値として丸める
      })),
    })),
  };
};

export const converters = {
  toThousandYen,
};
