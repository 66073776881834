import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TenantLayout } from "../../../components/layout/tenant-layout";
import { Loading } from "../../../components/loading/loading";
import { MoreMenu } from "../../../components/more-menu";
import { MyTooltip } from "../../../components/my-tooltip";
import { NotFound } from "../../../components/not-found";
import { AuthStore } from "../../../stores/auth-store";
import { dateUtil } from "../../../utils/date";
import { BusinessYearQueryResult, businessYearApi } from "../business-year-api";
import { BusinessYearDeleteDialog } from "../components/business-year-delete-dialog";
import { BusinessYearEditForm } from "../components/business-year-edit-form";
import { BusinessYearNewForm } from "../components/business-year-new-form";

export const BusinessYear = () => {
  const { t } = useTranslation();

  const [newFormOpen, setNewFormOpen] = useState<boolean>(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editTarget, setEditTarget] = useState<BusinessYearQueryResult>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState<BusinessYearQueryResult>();

  const { isTenantOwner } = AuthStore.useContainer();

  const businessYearsQuery = businessYearApi.useBusinessYears();

  if (businessYearsQuery.isLoading)
    return (
      <TenantLayout activeKey="business-year">
        <Grid container>
          <Grid item sm={12} md={10} lg={8}>
            <Loading />
          </Grid>
        </Grid>
      </TenantLayout>
    );
  if (!businessYearsQuery.data) return null;

  const onClickNew = () => {
    setNewFormOpen(true);
  };

  const onClickEdit = (businessYear: BusinessYearQueryResult) => {
    setEditTarget(businessYear);
    setEditFormOpen(true);
  };

  const onClickDelete = (businessYear: BusinessYearQueryResult) => {
    setDeleteTarget(businessYear);
    setDeleteDialogOpen(true);
  };

  const renderBusinessYearsContainer = () => (
    <>
      <Grid container item>
        <Grid item sm={12} md={10} lg={8}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MyTooltip unauthorized={!isTenantOwner}>
                <Button variant="contained" onClick={onClickNew} disabled={!isTenantOwner}>
                  {t("business-year.new")}
                </Button>
              </MyTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item sm={12} md={10} lg={8}>
          <TableContainer component={Paper}>
            <Table>
              {/* ヘッダーとボディをDataGridのデフォルトの高さに合わせる */}
              <TableHead>
                <TableRow sx={{ height: 39 }}>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell>{t("start-date")}</TableCell>
                  <TableCell>{t("end-date")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessYearsQuery.data.map((businessYear) => (
                  <TableRow key={businessYear.startDate} sx={{ height: 36 }}>
                    <TableCell>{businessYear.name}</TableCell>
                    <TableCell>{dateUtil.formatDisplayFromMutate(businessYear.startDate)}</TableCell>
                    <TableCell>{dateUtil.formatDisplayFromMutate(businessYear.endDate)}</TableCell>
                    <TableCell align="right" sx={{ py: 0 }}>
                      <MoreMenu>
                        <MyTooltip unauthorized={!isTenantOwner}>
                          <MenuItem disabled={!isTenantOwner} onClick={() => onClickEdit(businessYear)}>
                            <EditIcon fontSize="small" sx={{ mr: 1 }} />
                            {t("edit")}
                          </MenuItem>
                        </MyTooltip>
                        <MyTooltip unauthorized={!isTenantOwner}>
                          <MenuItem
                            disabled={!isTenantOwner}
                            sx={{ color: (theme) => theme.palette.error.main }}
                            onClick={() => onClickDelete(businessYear)}
                          >
                            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                            {t("delete")}
                          </MenuItem>
                        </MyTooltip>
                      </MoreMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );

  const renderNoBusinessYearContainer = () => (
    <Grid container item>
      <Grid item sm={12} md={10} lg={8}>
        <NotFound
          title={t("business-year.not-found")}
          caption={t("business-year.not-found.caption")}
          buttons={[
            {
              label: t("business-year.new"),
              unauthorized: !isTenantOwner,
              disabled: !isTenantOwner,
              onClick: onClickNew,
            },
          ]}
        />
      </Grid>
    </Grid>
  );

  return (
    <TenantLayout activeKey="business-year">
      <Grid container spacing={2}>
        <Grid container item>
          <Grid item>
            <Typography variant="h6">{t("business-year")}</Typography>
            <Typography variant="caption">{t("business-year.caption")}</Typography>
          </Grid>
        </Grid>
        {businessYearsQuery.data.length ? renderBusinessYearsContainer() : renderNoBusinessYearContainer()}
      </Grid>
      {newFormOpen && <BusinessYearNewForm open={newFormOpen} onClose={() => setNewFormOpen(false)} />}
      {editFormOpen && editTarget && (
        <BusinessYearEditForm target={editTarget} open={editFormOpen} onClose={() => setEditFormOpen(false)} />
      )}
      {deleteDialogOpen && deleteTarget && (
        <BusinessYearDeleteDialog
          target={deleteTarget}
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        />
      )}
    </TenantLayout>
  );
};
