import { useState } from "react";
import { createContainer } from "unstated-next";
import { CurrentTenantQueryResult } from "../routes/tenant-route-guard";
import { CurrentWorkspaceQueryResult } from "../routes/workspace-route-guard";

export const useAuthStore = () => {
  const [userId, setUserId] = useState<string>();
  const [currentTenant, setCurrentTenant] = useState<CurrentTenantQueryResult>();
  const [currentWorkspace, setCurrentWorkspace] = useState<CurrentWorkspaceQueryResult>();

  const isTenantOwner = currentTenant?.role === "owner";
  const isTenantGuest = currentTenant?.role === "guest";

  return {
    userId,
    setUserId,
    // テナント
    currentTenant,
    setCurrentTenant,
    isTenantOwner,
    isTenantGuest,
    // ワークスペース
    currentWorkspace,
    setCurrentWorkspace,
  };
};

export const AuthStore = createContainer(useAuthStore);
