import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { CompanyQueryResult, companyApi } from "../company-api";
import { CompanyBaseForm, CompanyFormModel } from "./company-base-form";

type CompanyEditFormProps = {
  target: CompanyQueryResult;
  open: boolean;
  onClose: () => void;
};

export const CompanyEditForm = ({ target, open, onClose }: CompanyEditFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { id, name, industryDivision, industryMajorGroup, industryGroup, industryDetail } = target;

  const updateCompanyMutation = companyApi.useUpdateCompany(t, enqueueSnackbar);

  const onSubmit = ({
    name,
    industryDivision,
    industryMajorGroup,
    industryGroup,
    industryDetail,
  }: CompanyFormModel) => {
    if (!industryMajorGroup) {
      return;
    }
    updateCompanyMutation.mutate({
      id,
      name,
      industryDivision,
      industryMajorGroup,
      industryGroup: industryGroup ? industryGroup : null,
      industryDetail: industryDetail ? industryDetail : null,
    });
  };

  return (
    <CompanyBaseForm
      title={t("company.edit")}
      buttonLabel={t("save")}
      defaultValue={{
        name,
        industryDivision,
        industryMajorGroup,
        industryGroup: industryGroup ?? "",
        industryDetail: industryDetail ?? "",
      }}
      open={open}
      loading={updateCompanyMutation.isLoading}
      done={updateCompanyMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
