import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { dateUtil } from "../../../utils/date";
import { businessYearApi } from "../business-year-api";
import { BusinessYearBaseForm, BusinessYearFormModel } from "./business-year-base-form";

type BusinessYearNewFormProps = {
  open: boolean;
  onClose: () => void;
};

export const BusinessYearNewForm = ({ open, onClose }: BusinessYearNewFormProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const createBusinessYearMutation = businessYearApi.useCreateBusinessYear(t, enqueueSnackbar);

  const onSubmit = ({ name, startDate, endDate }: BusinessYearFormModel) => {
    if (startDate && endDate)
      createBusinessYearMutation.mutate({
        name,
        startDate: dateUtil.formatMutate(startDate),
        endDate: dateUtil.formatMutate(endDate),
      });
  };

  const defaultStartDate = () => {
    const lastYear = dayjs().year() - 1;
    return dayjs(`${lastYear}-04-01`).toDate();
  };

  const defaultEndDate = () => {
    const currentYear = dayjs().year();
    return dayjs(`${currentYear}-03-31`).toDate();
  };

  return (
    <BusinessYearBaseForm
      title={t("business-year.new")}
      buttonLabel={t("new")}
      defaultValue={{ name: "", startDate: defaultStartDate(), endDate: defaultEndDate() }}
      open={open}
      loading={createBusinessYearMutation.isLoading}
      done={createBusinessYearMutation.isSuccess}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};
