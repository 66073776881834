// 取得するデータがheadcountとratioの配列になる関係上、継承元がbase-column-chartより
// base-column-line-chartのほうが適しているため
// column-lineチャートに実装している
import { TFunction } from "i18next";
import { AGGREGATE_TYPES } from "../../../../config/const";
import { AggregateTypeTextDef, HeadcountAndRatioTypeTextDef } from "../../../../config/text-def";
import {
  AggregateType,
  ChartSeriesColumnOption,
  ChartSeriesLineOption,
  HeadcountType,
  RatioType,
} from "../../../../types";
import {
  BaseHeadcountAndRatioTypeColumnLineChart,
  BaseHeadcountAndRatioTypeColumnLineChartSeriesCode,
} from "../base-headcount-and-ratio-type-column-line-chart";

type HeadcountChartSeriesCode = `${AggregateType}_${HeadcountType}`;
type RatioChartSeriesCode = `${AggregateType}_${RatioType}`;

export class MaleChildcareLeaveTakenDayDistributionChart extends BaseHeadcountAndRatioTypeColumnLineChart {
  protected _createSeriesDefs(t: TFunction): [
    Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>,
    Map<RatioChartSeriesCode, ChartSeriesLineOption>,
    BaseHeadcountAndRatioTypeColumnLineChartSeriesCode[] // 順序を持ったシリーズコード
  ] {
    const columnMap = new Map<HeadcountChartSeriesCode, ChartSeriesColumnOption>();
    const lineMap = new Map<RatioChartSeriesCode, ChartSeriesLineOption>();
    const orderedSeriesCodes: BaseHeadcountAndRatioTypeColumnLineChartSeriesCode[] = [];
    AGGREGATE_TYPES.forEach((aggregateType) => {
      // 折れ線グラフを表示しないためlineMapの要素をオーバーライドせず、ヘッドカウントのみ
      const headcountChartSeriesCode: HeadcountChartSeriesCode = `${aggregateType}_headcount`;
      orderedSeriesCodes.push(headcountChartSeriesCode);
      columnMap.set(headcountChartSeriesCode, {
        name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
          HeadcountAndRatioTypeTextDef.get("headcount") as string
        )}`,
        color: super.getColumnColor(aggregateType, 0),
      });
    });
    return [columnMap, lineMap, orderedSeriesCodes];
  }
}
