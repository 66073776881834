/* eslint-disable @typescript-eslint/no-explicit-any */
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";

type MoreMenuProps = {
  children: React.ReactNode;
};

// MenuItemをchildrenに受け取る
export const MoreMenu = ({ children }: MoreMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const recursiveMap = (
    children: React.ReactNode,
    fn: (child: React.ReactElement<any>) => React.ReactElement<any>
  ): React.ReactNode => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }
      if (child.props.children) {
        child = React.cloneElement<any>(child, {
          children: recursiveMap(child.props.children, fn),
        });
      }
      return fn(child);
    });
  };

  const childrenWithProps = recursiveMap(children, (child) => {
    if (child.props.onClick) {
      return React.cloneElement<any>(child, {
        onClick: (e: any) => {
          handleClose();
          child.props.onClick(e);
        },
      });
    }
    return child;
  });

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
      >
        {childrenWithProps}
      </Menu>
    </>
  );
};
