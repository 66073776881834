export type COMPANYLogoProps = {
  title?: string;
};

export const COMPANYLogo = ({ title }: COMPANYLogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={title ? 380 : 380 * 2.65}
      height={title ? 48 : 48 * 2.65}
      viewBox="0 0 497 64"
    >
      <defs>
        <clipPath id="hci-logo-cp">
          <rect width="497" height="64" />
        </clipPath>
      </defs>
      {/* FIXME: 中央よせの方法が分からなかったのでtranslateで手動計算して注入している、正しいやり方がわかった人は変えること */}
      <g id="hci-log-g" clipPath="url(#hci-logo-cp)" transform={title ? undefined : "translate(139)"}>
        <g transform="translate(3.013 16.012)">
          <path
            d="M17.682,33.684a2.916,2.916,0,0,1-2.069-.857L2.539,19.753a2.923,2.923,0,0,1,0-4.138L15.613,2.541a2.924,2.924,0,0,1,4.138,0L24.4,7.188l.017.017,1.852,1.852a2.955,2.955,0,1,1-4.179,4.179l-1.852-1.852a3,3,0,0,1-.262-.3l-1.065-1.065a1.726,1.726,0,0,0-2.44,0L10.017,16.47a1.728,1.728,0,0,0,0,2.44l6.451,6.451a1.726,1.726,0,0,0,2.44,0l6.451-6.451a1.729,1.729,0,0,0,.236-.294l2.927-2.926.075-.078,7.095-7.095a2.924,2.924,0,0,1,4.138,0l7.095,7.095a2.924,2.924,0,0,1,0,4.138l-7.095,7.095a2.924,2.924,0,0,1-4.138,0l-4.98-4.98L19.75,32.827A2.916,2.916,0,0,1,17.682,33.684ZM37.929,14.305a3.4,3.4,0,1,0,2.405,1A3.4,3.4,0,0,0,37.929,14.305Z"
            transform="translate(-1.694 -1.695)"
            fill="#fff"
          />
          <path
            d="M435.386,331.2a5.775,5.775,0,0,1-2.238,1.972,6.9,6.9,0,0,1-3.133.712,6.224,6.224,0,0,1-3.268-.9,6.825,6.825,0,0,1-3.274-5.864,6.54,6.54,0,0,1,3.262-5.722,6.424,6.424,0,0,1,3.28-.869,6.156,6.156,0,0,1,2.78.652,6.544,6.544,0,0,1,2.219,1.8l.585.738,2.764-2.369-.569-.686a10.371,10.371,0,0,0-3.447-2.71,9.765,9.765,0,0,0-4.331-1,10.126,10.126,0,0,0-8.793,5.015,10,10,0,0,0-1.375,5.152,10.388,10.388,0,0,0,5.072,8.962,9.8,9.8,0,0,0,5.1,1.4,10.5,10.5,0,0,0,4.742-1.088,9.469,9.469,0,0,0,3.572-3.072l.5-.713-2.9-2.216Z"
            transform="translate(-361.577 -311.185)"
            fill="#fff"
          />
          <path
            d="M512.87,318.215h0a10.188,10.188,0,0,0-13.875,3.695,10,10,0,0,0-1.375,5.152,10.348,10.348,0,0,0,5.075,8.951,10.079,10.079,0,0,0,10.209,0,10.2,10.2,0,0,0,3.694-3.746,10.318,10.318,0,0,0,1.358-5.2,10.112,10.112,0,0,0-5.085-8.847Zm1.46,8.847a6.867,6.867,0,0,1-3.279,5.879,6.3,6.3,0,0,1-6.517,0,6.842,6.842,0,0,1-3.288-5.878,6.533,6.533,0,0,1,.882-3.33,6.624,6.624,0,0,1,2.385-2.407,6.545,6.545,0,0,1,6.551,0,6.631,6.631,0,0,1,2.385,2.407,6.532,6.532,0,0,1,.882,3.329Z"
            transform="translate(-416.326 -311.13)"
            fill="#fff"
          />
          <path
            d="M603.229,317.452a1.925,1.925,0,0,0-2.963.241l-6.021,9.313-6-9.29-.023-.033a1.873,1.873,0,0,0-1.568-.816,1.952,1.952,0,0,0-1.935,1.936v18.351h3.65V324.37l5.881,9.171,5.881-9.171v12.784h3.65V318.8a1.918,1.918,0,0,0-.546-1.35Z"
            transform="translate(-477.642 -311.127)"
            fill="#fff"
          />
          <path
            d="M684.755,319h0a6.726,6.726,0,0,0-3.532-.977h-6.811a1.949,1.949,0,0,0-1.412.572,1.921,1.921,0,0,0-.572,1.388v17.984h3.65v-5.954h5.146a7.073,7.073,0,0,0,3.506-.9,6.834,6.834,0,0,0,2.551-2.481,6.682,6.682,0,0,0,.949-3.479,7.055,7.055,0,0,0-.941-3.55A7.168,7.168,0,0,0,684.755,319Zm-.175,6.153a3.042,3.042,0,0,1-.435,1.605,3.192,3.192,0,0,1-1.21,1.17,3.4,3.4,0,0,1-1.712.435h-5.146v-6.689h5.146a3.09,3.09,0,0,1,1.658.467,3.525,3.525,0,0,1,1.243,1.283,3.394,3.394,0,0,1,.456,1.73Z"
            transform="translate(-539.387 -311.938)"
            fill="#fff"
          />
          <path
            d="M740.642,317.281h0a1.921,1.921,0,0,0-2.133,0,2.093,2.093,0,0,0-.734.887l-8.135,19.05h4.029l2.019-4.724h7.68l1.989,4.724h4.052L741.364,318.2A1.9,1.9,0,0,0,740.642,317.281Zm1.219,11.634h-4.642l2.339-5.472Z"
            transform="translate(-579.667 -311.193)"
            fill="#fff"
          />
          <path
            d="M825.091,330.628l-9.934-13.013a2.012,2.012,0,0,0-2.97-.175,1.922,1.922,0,0,0-.572,1.388v18.327h3.65V323.75l9.934,12.928a1.98,1.98,0,0,0,3.542-1.238V317.212h-3.651Z"
            transform="translate(-637.376 -311.13)"
            fill="#fff"
          />
          <path
            d="M900.99,318.028l-5.586,9.489-5.63-9.489h-4.242l8.039,13.5v6.439h3.65v-6.439l8.038-13.5Z"
            transform="translate(-689.413 -311.945)"
            fill="#fff"
          />
        </g>
        {title && (
          <>
            <line y2="32" transform="translate(235 16)" fill="none" stroke="#fff" strokeWidth="1" />
            <text transform="translate(251 39)" fill="#fff" fontSize="22" fontFamily="Meiryo">
              <tspan x="0" y="0">
                {title}
              </tspan>
            </text>
          </>
        )}
      </g>
    </svg>
  );
};
