import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="error">
          <AlertTitle>{t("error.fallback.message")}</AlertTitle>
        </Alert>
      </Grid>
      <Grid item textAlign="center" xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            return window.location.assign(window.location.origin);
          }}
        >
          Refresh
        </Button>
      </Grid>
    </Grid>
  );
};
