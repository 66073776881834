import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

export const StyledButton = styled(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    return <Button ref={ref} {...props} />;
  })
)(({ theme }) => ({
  color: theme.palette.grey[700],
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.common.white,
  "&:disabled": {
    color: theme.palette.grey[300],
    borderColor: theme.palette.grey[400],
  },
  "&:hover": {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[400],
  },
}));
