import { TFunction } from "i18next";
import { DashboardChartDisplaySwitchModel } from "../../../components/dashboard-chart-display-switch";
import { AGGREGATE_TYPES } from "../../../config/const";
import { NationalityTextDef } from "../../../config/nationality-text-def";
import { AggregateTypeTextDef } from "../../../config/text-def";
import { ChartQueryResult } from "../../../dashboard-api";
import { AggregateType, Row, AggregateTypeRow, BaseData, ChartSeriesColumnOption } from "../../../types";
import { NationalityType } from "../../../types/nationality-type";
import { BaseStackedColumnChart } from "../base-stacked-column-chart";

export type BaseNationalityTypeStackedColumnData = {
  nationality: NationalityType;
} & BaseData;

export type BaseNationalityTypeStackedColumnChartSeriesCode = `${AggregateType}_${NationalityType}`;

const MAX_DISPLAY_NATIONALITY = 4;

type DisplayNationality = {
  aggregateType: AggregateType;
  nationalities: NationalityType[];
};

export class BaseNationalityTypeStackedColumnChart extends BaseStackedColumnChart<BaseNationalityTypeStackedColumnData> {
  getChartOptions(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseNationalityTypeStackedColumnData>
      | ChartQueryResult<BaseNationalityTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel,
    inBoard: boolean
  ): Highcharts.Options {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }

    // _getLatestYearDisplayNationalityでは個別表示を行う国籍を取得する。
    // 個別に表示を行う国籍は最新年度のデータにおける上位4ヵ国となる。
    // 最新年度で国籍が4ヵ国に満たない場合、前年にさかのぼり、人数が多い国籍を個別表示の国籍に加える。
    const displayNationalities = this._getLatestYearDisplayNationalities(queryResult, MAX_DISPLAY_NATIONALITY);

    // _transformTopNでは以下のデータを表示するためにdisplayNationalitiesをもとにデータの整形を行っている。
    // * 上位4ヵ国のデータ
    // * その他のデータ（4ヵ国以外のすべての国籍の従業員の合計）
    const topNQueryResult = this._transformTopN(queryResult, displayNationalities);

    return {
      ...super.getChartOptions(t, topNQueryResult, displaySwitch, inBoard),
      series: super.getSeries(
        topNQueryResult,
        displaySwitch,
        this._getSeriesCode,
        this._createSeriesDefs(displayNationalities, t)
      ),
    };
  }

  protected _getLatestYearDisplayNationalities(
    queryResult: ChartQueryResult<BaseNationalityTypeStackedColumnData>,
    size: number
  ): DisplayNationality[] {
    return AGGREGATE_TYPES.map((aggregateType) => {
      const nationalities: NationalityType[] = [];
      for (const item of queryResult.datasets.toReversed()) {
        if (nationalities.length >= size) {
          break;
        }
        const targetData = item.data.filter((d) => d.aggregateType === aggregateType);
        const sortedData = targetData.sort((a, b) => {
          const aValue = a.value ?? 0;
          const bValue = b.value ?? 0;
          const orderByValue = bValue - aValue;
          const orderByNationality = a.nationality.localeCompare(b.nationality);
          return orderByValue || orderByNationality;
        });
        for (const sortedDataItem of sortedData) {
          if (nationalities.length >= size) {
            break;
          }
          if (nationalities.includes(sortedDataItem.nationality)) {
            continue;
          }
          nationalities.push(sortedDataItem.nationality);
        }
      }
      return { aggregateType, nationalities };
    });
  }

  protected _transformTopN(
    queryResult: ChartQueryResult<BaseNationalityTypeStackedColumnData>,
    displayNationalities: DisplayNationality[]
  ): ChartQueryResult<BaseNationalityTypeStackedColumnData> {
    const datasets = queryResult.datasets.map((dataset) => {
      if (dataset.data.length === 0) {
        return { ...dataset };
      }
      const data: BaseNationalityTypeStackedColumnData[] = displayNationalities.flatMap(
        ({ aggregateType, nationalities }) => {
          const targetData = dataset.data.filter((x) => x.aggregateType === aggregateType);
          const topData = targetData.filter((d) => nationalities.includes(d.nationality));
          const otherTotalValue = targetData
            .filter((d) => !nationalities.includes(d.nationality))
            .reduce((sum, current) => sum + (current.value ?? 0), 0);
          return [
            ...topData,
            {
              aggregateType,
              value: otherTotalValue,
              nationality: "other",
            },
          ];
        }
      );

      return {
        ...dataset,
        data,
      };
    });
    return {
      ...queryResult,
      datasets,
    };
  }

  protected _getSeriesCode(
    datum: BaseNationalityTypeStackedColumnData
  ): BaseNationalityTypeStackedColumnChartSeriesCode {
    return `${datum.aggregateType}_${datum.nationality}`;
  }

  protected _createSeriesDefs(
    displayNationalities: DisplayNationality[],
    t: TFunction
  ): Map<BaseNationalityTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption> {
    const columnMap = new Map<BaseNationalityTypeStackedColumnChartSeriesCode, ChartSeriesColumnOption>();
    displayNationalities.forEach(({ aggregateType, nationalities }) => {
      const nationalitiesWithOther: NationalityType[] = [...nationalities, "other"];
      nationalitiesWithOther.toReversed().forEach((nationality, i, array) => {
        const code: BaseNationalityTypeStackedColumnChartSeriesCode = `${aggregateType}_${nationality}`;
        columnMap.set(code, {
          name: `[${t(AggregateTypeTextDef.get(aggregateType) as string)}] ${t(
            NationalityTextDef.get(nationality) as string
          )}`,
          color: super.getColor(aggregateType, array.length, i),
        });
      });
    });
    return columnMap;
  }

  getAggregateTypeRows(
    t: TFunction,
    queryResult:
      | ChartQueryResult<BaseNationalityTypeStackedColumnData>
      | ChartQueryResult<BaseNationalityTypeStackedColumnData>[],
    displaySwitch: DashboardChartDisplaySwitchModel
  ): AggregateTypeRow[] {
    if (Array.isArray(queryResult)) {
      throw new Error("ChartQueryResult must NOT be array.");
    }
    return this.getFilteredAggregateTypes(displaySwitch).map((aggregateType) => {
      const rows: Row[] = [];
      queryResult.datasets
        .flatMap(({ data }) => {
          return data
            .filter((x) => x.aggregateType === aggregateType)
            .map((x) => {
              return {
                header: x.nationality,
                unit: queryResult.unit,
                values: super.getValuesByCondition(
                  queryResult,
                  (datum) => datum.aggregateType === aggregateType && datum.nationality === x.nationality
                ),
              };
            });
        })
        .forEach((row) => {
          if (!row.header) {
            return;
          }
          const headers = rows.map((row) => row.header);
          if (headers.includes(row.header)) {
            return;
          }
          rows.push(row);
        });

      // queryResult.datasetsは年度ごとにデータが分かれているため
      // rowsに結果がまとめられてからソートする必要がある。
      // またヘッダの変換については国籍コードと国名がソートした際の順番が一致しないため
      // ソート後に変換を行っている。
      const sortedRows = rows
        .sort((a, b) => (a.header ?? "").localeCompare(b.header ?? ""))
        .map((x) => {
          const nationality = x.header as NationalityType;
          return {
            ...x,
            header: t(NationalityTextDef.get(nationality) as string) as string,
          };
        });

      if (rows.length !== 0) {
        let totals: (number | null)[] = [];
        rows.forEach((row, index) => {
          if (index == 0) {
            totals = [...row.values];
            return;
          }
          row.values.forEach((value, index) => {
            if (value == undefined) {
              return;
            }
            totals[index] = (totals[index] ?? 0) + value;
          });
        });

        sortedRows.push({
          header: t("dashboard.nationality.all") as string,
          unit: queryResult.unit,
          values: totals,
        });
      }

      return {
        aggregateType,
        rows: sortedRows,
      };
    });
  }
}
