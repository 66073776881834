import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../components/loading/loading";
import { axiosInstance } from "../lib/axios";
import { AuthStore } from "../stores/auth-store";
import { TenantRole, IndustryDivision } from "../types";

export type CurrentTenantQueryResult = {
  name: string;
  industryDivision: IndustryDivision;
  role: TenantRole | null;
};

const getCurrentTenant = (): Promise<CurrentTenantQueryResult> => {
  return axiosInstance.get(`/tenants/current`);
};

const useCurrentTenant = () => {
  return useQuery({
    queryKey: ["tenants/current"],
    queryFn: () => getCurrentTenant(),
  });
};

type TenantRouteGuardProps = {
  children: React.JSX.Element;
};

export const TenantRouteGuard = ({ children }: TenantRouteGuardProps) => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const authStore = AuthStore.useContainer();

  const currentTenantQuery = useCurrentTenant();

  useEffect(() => {
    if (currentTenantQuery.isFetching) return;
    authStore.setCurrentTenant(currentTenantQuery.data);
    // guest 以外の role のみ遷移を許可する
    if (authStore.isTenantGuest) {
      if (pathname !== "/my-workspaces") {
        // ログイン成功時の遷移ではエラーメッセージの表示を行わない
        if (state?.from !== "/auth/login") enqueueSnackbar(t("snackbar.unauthorized-or-not-found"));

        navigate("/my-workspaces");
      }
      return;
    }
  }, [currentTenantQuery, authStore, pathname, state, navigate, t, enqueueSnackbar]);

  if (currentTenantQuery.isFetching || authStore.currentTenant === undefined) {
    // 各ページでauthStore.tenantRoleを利用できるまでローディングを表示する
    return <Loading />;
  }

  return children;
};
