import { Auth } from "aws-amplify";
import axios, { InternalAxiosRequestConfig } from "axios";
import { API_BASE_URL } from "../config";
import { WORKSPACE_ID_HEADER_NAME } from "../config/const";

async function requestInterceptor(config: InternalAxiosRequestConfig) {
  config.baseURL = API_BASE_URL;
  const idToken = await getIdToken();
  config.headers.authorization = `Bearer ${idToken}`;
  config.headers.Accept = "application/json";

  // Request Authorizerでの認可のためリクエスト本文にworkspaceIdが存在する場合はヘッダにコピーする。
  // Authorizerはリクエスト本文の情報を取得できないため。
  // TODO: リクエスト本文にworkspaceIdを含めずに、ヘッダーのみに付与するように変更する。各ApiクラスのAxiosの呼び出し時にヘッダが付与されるイメージ。
  if (config.data?.workspaceId) {
    config.headers[WORKSPACE_ID_HEADER_NAME] = config.data.workspaceId;
  }

  return config;
}

const getIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(requestInterceptor);
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
