import { Box } from "@mui/material";
import React from "react";
import { HEADER_HEIGHT } from "./global-header";
import { TenantSidebar, TenantSidebarKey } from "./tenant-sidebar";

type TenantLayoutProps = {
  activeKey: TenantSidebarKey;
  sideDialog?: React.ReactNode;
  children: React.ReactNode;
};

export const TenantLayout = ({ activeKey, sideDialog, children }: TenantLayoutProps) => {
  return (
    <Box display="flex" height="100%">
      <TenantSidebar activeKey={activeKey} />
      <Box overflow="scroll" height={`calc(var(--vh, 100vh) - ${HEADER_HEIGHT}px)`} width="100%">
        <Box p={2}>{children}</Box>
      </Box>
      {sideDialog}
    </Box>
  );
};
