import BlockIcon from "@mui/icons-material/Block";
import Box from "@mui/material/Box";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import React from "react";
import { useTranslation } from "react-i18next";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type MyTooltipProps = {
  unauthorized?: boolean;
  title?: React.ReactNode;
} & Omit<TooltipProps, "title">;

// 権限がない場合とある場合どちらもTooltipを表示したい場合に使う
export const MyTooltip = ({ children, unauthorized = false, title = "", ...rest }: MyTooltipProps) => {
  const { t } = useTranslation();

  if (unauthorized && title) throw new Error("title must be undefined when unauthorized is true");
  return (
    <Tooltip
      title={
        unauthorized ? (
          <Box display="flex" alignItems="center" gap={1}>
            <BlockIcon sx={{ fontSize: 14 }} />
            <span>{t("tooltip.unauthorized")}</span>
          </Box>
        ) : (
          title
        )
      }
      {...rest}
    >
      <span>{children}</span>
    </Tooltip>
  );
};
