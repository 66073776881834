import { Box } from "@mui/material";
import React from "react";
import { GlobalHeader } from "./global-header";
import { GLOBAL_SIDEBAR_WIDTH, GlobalSidebar } from "./global-sidebar";

type GlobalLayoutProps = {
  children: React.ReactNode;
};

export const GlobalLayout = ({ children }: GlobalLayoutProps) => {
  return (
    <>
      <GlobalHeader />
      <Box display="flex">
        <GlobalSidebar />
        <Box flexGrow={1} width={`calc(100% - ${GLOBAL_SIDEBAR_WIDTH}px)`}>
          {children}
        </Box>
      </Box>
    </>
  );
};
