/* eslint-disable @typescript-eslint/no-explicit-any */
import { TFunction } from "i18next";
import { CalcBasisQueryResult } from "../dashboard-api";
import { CalcBasisItemRow, CalcBasisBaseData } from "../types";
import { IBaseCalcBasis } from "./i-base-calc-basis";

export type BaseCalcBasisProps = {
  unitCaption?: string;
  notes?: string[];
};

export abstract class BaseCalcBasis<TData extends CalcBasisBaseData> implements IBaseCalcBasis<TData> {
  private static instances: Map<new (props: BaseCalcBasisProps) => BaseCalcBasis<any>, BaseCalcBasis<any>> = new Map();
  private _unitCaption?: string;
  private _notes: string[];

  protected constructor({ unitCaption, notes }: BaseCalcBasisProps) {
    this._unitCaption = unitCaption;
    this._notes = notes ?? [];
  }

  /**
   * 再描画を抑制するためにシングルトンパターンを利用
   * 参考: https://typescript-jp.gitbook.io/deep-dive/main-1/singleton
   */
  static getInstance<TCalcBasisClass extends BaseCalcBasis<any>>(
    this: new (props: BaseCalcBasisProps) => TCalcBasisClass,
    props: BaseCalcBasisProps
  ): TCalcBasisClass {
    if (!BaseCalcBasis.instances.has(this)) {
      const instance = new this(props);
      BaseCalcBasis.instances.set(this, instance);
    }
    return BaseCalcBasis.instances.get(this) as TCalcBasisClass;
  }

  get unitCaption() {
    return this._unitCaption;
  }

  get notes() {
    return this._notes;
  }

  abstract getRows(
    t: TFunction,
    queryResult: CalcBasisQueryResult<TData> | CalcBasisQueryResult<TData>[]
  ): CalcBasisItemRow[];

  /**
   * getRowsの実装で利用する関数
   */
  protected getValuesByCondition<TData extends CalcBasisBaseData>(
    queryResult: CalcBasisQueryResult<TData>,
    condition: (datum: TData) => boolean
  ): (number | null)[] {
    const indexes = queryResult.datasets.map(({ index }) => index);
    return indexes.map((index) => {
      const dataset = queryResult.datasets.find((dataset) => dataset.index === index);
      const datum = dataset?.data.find(condition);
      return datum ? datum.value : null;
    });
  }
}
