import styled from "@emotion/styled";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const RunningIcon = styled(AutorenewIcon)({
  animation: "rotate 2s linear infinite",
  "@keyframes rotate": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
});
